#root {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

fa,
input,
textarea,
button,
select {
    outline: none
}

a:hover,
a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
    outline: none !important;
    text-decoration: none;
    color: #c59344;
}

body {
    position: relative;
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    color: #5c5c5c;
    padding: 0;
    margin: 0;
    letter-spacing: .014em;
    font-weight: 400
}

p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #5c5c5c;
    letter-spacing: 0.04em;
}

input,
textarea {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

input[type="search"],
input[type="text"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="date"],
input[type="time"] {
    background: none;
    /* border: 1px solid #A3A3A3; */
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    color: #666;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Work Sans', sans-serif;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

select {
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px
}

textarea {
    background: none;
    border: 1px solid #5c5c5c;
    resize: none;
    padding: 12px;
    height: 140px;
    color: #666;
    font-size: 14px;
    vertical-align: middle
}

input::-webkit-input-placeholder {
    color: #888888
}

input:-moz-placeholder {
    color: #888888
}

input::-moz-placeholder {
    color: #888888
}

input:-ms-input-placeholder {
    color: #888888
}

textarea::-webkit-input-placeholder {
    color: #888888
}

textarea:-moz-placeholder {
    color: #888888
}

textarea::-moz-placeholder {
    color: #888888
}

textarea:-ms-input-placeholder {
    color: #888888
}

a {
    color: #5c5c5c;
    text-decoration: inherit;
}

.tab-pane {
    display: none;
    /* Sembunyikan semua tab secara default */
}

.tab-pane.active {
    display: block;
    /* Tampilkan tab yang aktif */
}

.room-detail_tab-header li.active a {
    font-weight: bold;
    /* Contoh styling untuk tab aktif */
    color: #D4A355;
    /* Warna teks tab aktif */
}

.btn-collapse {
    background: none;
    border: none;
    color: inherit;
    text-align: left;
    width: 100%;
    cursor: pointer;
    padding: 10px 0;
    font-weight: bold;
}

.collapse-content {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

code,
kbd,
pre,
samp {
    font-size: inherit
}

code {
    background-color: #eee;
    letter-spacing: .015em
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0
}

::-moz-selection {
    color: #fff;
    background-color: #232323
}

::selection {
    color: #fff;
    background-color: #232323
}

.element-invisible {
    position: absolute !important;

    .box-border {
        display: inline-block;
        width: 100px;
        height: 1px;
        background: #e1bd85
    }
}

.box-border.w350 {
    width: 350px
}

.awe-parallax {
    background-position: 50% 50%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 0
}

.color-red {
    color: #ee242a
}

.fix-background-ios {
    -webkit-background-size: auto 150% !important;
    background-attachment: scroll !important
}

.awe-static {
    color: #fff;
    background-position: 50% 50%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.awe-color {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    background-color: #232323
}

.awe-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(72, 72, 72, 0.3);
    top: 0;
    left: 0;
    z-index: 0
}

.awe-btn {
    display: inline-block;
    background: none;
    font-family: 'Work Sans', sans-serif;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    line-height: normal;
    padding: 11px 42px;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.055em;
    color: #5C5C5C;
    ;
    min-width: 110px;
    border-width: 0;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    font-weight: 400;
    margin-bottom: 30px;

}

.awe-btn.awe-btn-default {
    border: 0.5px solid #5C5C5C;
}

.awe-btn.awe-btn-default:hover {
    border-color: #e1bd85;
    background-color: #e1bd85;
    color: #fff
}

.awe-btn.awe-btn-default:link {
    color: #5c5c5c;
}

.awe-btn.awe-btn-1 {
    border: 2px solid #357ebd;
    color: #357ebd
}

.awe-btn.awe-btn-1:hover {
    background-color: #357ebd;
    color: #fff
}

.awe-btn.awe-btn-2 {
    border: 2px solid #4cae4c;
    color: #4cae4c
}

.awe-btn.awe-btn-2:hover {
    background-color: #4cae4c;
    color: #fff
}

.awe-btn.awe-btn-3 {
    border: 2px solid #46b8da;
    color: #46b8da
}

.awe-btn.awe-btn-3:hover {
    background-color: #46b8da;
    color: #fff
}

.awe-btn.awe-btn-4 {
    border: 2px solid #eea236;
    color: #eea236
}

.awe-btn.awe-btn-4:hover {
    background-color: #eea236;
    color: #fff
}

.awe-btn.awe-btn-5 {
    border: 2px solid #d43f3a;
    color: #d43f3a
}

.awe-btn.awe-btn-5:hover {
    background-color: #d43f3a;
    color: #fff
}

.awe-btn.awe-btn-6 {
    border: 2px solid #e1bd85;
    background-color: #e1bd85;
    color: #fff
}

.awe-btn.awe-btn-6:hover {
    color: #e1bd85;
    background-color: transparent
}

.awe-btn.awe-btn-7 {
    border: 2px solid #357ebd;
    background-color: #357ebd;
    color: #fff
}

.awe-btn.awe-btn-7:hover {
    color: #357ebd;
    background-color: transparent
}

.awe-btn.awe-btn-8 {
    border: 2px solid #4cae4c;
    background-color: #4cae4c;
    color: #fff
}

.awe-btn.awe-btn-8:hover {
    color: #4cae4c;
    background-color: transparent
}

.awe-btn.awe-btn-9 {
    border: 2px solid #46b8da;
    background-color: #46b8da;
    color: #fff
}

.awe-btn.awe-btn-9:hover {
    color: #46b8da;
    background-color: transparent
}

.awe-btn.awe-btn-10 {
    border: 2px solid #eea236;
    background-color: #eea236;
    color: #fff
}

.awe-btn.awe-btn-10:hover {
    color: #eea236;
    background-color: transparent
}

.awe-btn.awe-btn-11 {
    border: 2px solid #d43f3a;
    background-color: #d43f3a;
    color: #fff
}

.awe-btn.awe-btn-11:hover {
    color: #d43f3a;
    background-color: transparent
}

.awe-btn.awe-btn-12 {
    border: 2px solid #fff;
    color: #fff;
}

.awe-btn.awe-btn-12:hover {
    color: #e1bd85;
    background-color: #e1bd85;
    border-color: #e1bd85;
    color: #fff
}

.awe-btn.awe-btn-13 {
    margin-left: 10px;
    line-height: 30px;
    font-size: 18px;
    border: 1px solid #A3A3A3;
    padding: 0 15px !important;
    margin-bottom: 15px;

}

.awe-btn.awe-btn-13:hover {
    border-color: #d0983f;
    background-color: #d0983f;
    color: #fff !important
}

.awe-btn.awe-btn-13.btn-order {
    height: 54px;
    line-height: 50px;
    padding-top: 0 !important;
    font-size: 16px !important;
    padding-bottom: 0 !important
}

.awe-btn.awe-btn-14 {
    border: 2px solid #000
}

.awe-btn.awe-btn-14:hover {
    border-color: #e1bd85;
    background-color: #e1bd85;
    color: #fff
}

section {
    position: relative
}

.tb {
    display: table;
    width: 100%
}

.tb-cell {
    display: table-cell;
    vertical-align: middle
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: normal;
    line-height: 1.5em;
    color: #5c5c5c;
    font-family: 'Work Sans', sans-serif;
    margin: 0
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
    color: inherit
}

h1,
.h1 {
    font-size: 36px;
    font-weight: bold
}

h2,
.h2 {
    font-size: 28px
}

h3,
.h3 {
    font-size: 24px
}

h4,
.h4 {
    font-size: 21px;
    margin-bottom: 5px;
}

h5,
.h5 {
    font-size: 16px;
    font-weight: bold;
}

h6,
.h6 {
    font-size: 14.12px
}

abbr {
    color: #A1D71A;
    border-width: 2px
}

mark,
.mark {
    background-color: #232323
}

.icon {
    display: inline-block;
    font-style: normal
}

.float-left {
    float: left
}

.float-right {
    float: right
}

.hr {
    height: 1px;
    width: 100%
}

.hr:after,
.hr:before {
    width: 50%;
    content: '';
    height: 1px
}

.hr:after {
    float: right;
    background-color: #e3e3e3;
    background-image: -webkit-gradient(linear, left top, right top, from(#e3e3e3), to(#fff));
    background-image: -webkit-linear-gradient(left, #e3e3e3, #fff);
    background-image: -moz-linear-gradient(left, #e3e3e3, #fff);
    background-image: -ms-linear-gradient(left, #e3e3e3, #fff);
    background-image: -o-linear-gradient(left, #e3e3e3, #fff)
}

.hr:before {
    float: left;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(#e3e3e3));
    background-image: -webkit-linear-gradient(left, #fff, #e3e3e3);
    background-image: -moz-linear-gradient(left, #fff, #e3e3e3);
    background-image: -ms-linear-gradient(left, #fff, #e3e3e3);
    background-image: -o-linear-gradient(left, #fff, #e3e3e3)
}

.heading {
    margin: 0;
    color: #5C5C5C;
    font-size: 32px;
    /**font-weight: bold;**/
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.heading.white {
    color: #fff
}

.sub-heading {
    color: #333333;
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px
}

.shortcode-heading {
    color: #464646;
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    padding-bottom: 10px;
    font-size: 14px;
    margin: 0;
    border-bottom: 2px dotted #464646
}

blockquote {
    border: 2px solid #232323;
    padding: 15px 25px;
    margin: 0
}

blockquote p {
    margin: 0;
    color: #444444;
    font-weight: 600;
    font-style: italic;
    line-height: 1.428em
}

blockquote p:before {
    content: '“';
    font-family: 'Work Sans', sans-serif;
    font-size: 28px;
    margin-right: 10px;
    font-style: inherit
}

blockquote p:after {
    content: '”';
    font-size: 28px;
    font-style: inherit;
    margin-left: 10px;
    font-family: 'Montserrat'
}

.bg-white {
    background-color: #ffffff
}

.bg-gray {
    background-color: #f1f1f1
}

.bg-blue {
    background-color: #e1bd85
}

.bg-padma {
    background-color: #f6f6f6
}

.tabs {
    background-color: transparent;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tabs .ui-tabs-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-width: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tabs .ui-tabs-nav li {
    background-color: transparent !important;
    background-image: none !important;
    border-width: 0 !important;
    margin: 0 40px 0 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tabs .ui-tabs-nav li a {
    color: #232323 !important;
    font-weight: 500;
    padding: 0 !important;
    text-transform: uppercase;
    font-family: 'Hind'
}

.tabs .ui-tabs-nav li:hover a,
.tabs .ui-tabs-nav li.ui-tabs-active a {
    color: #e1bd85 !important
}

.tabs .ui-tabs-panel {
    padding: 0;
    margin-top: 30px
}

.tabs.tabs-2 .ui-tabs-nav li {
    margin: 0 !important
}

.tabs.tabs-2 .ui-tabs-nav li a {
    font-family: 'Work Sans', sans-serif;
    padding: 7px 10px !important;
    border: 2px solid transparent;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.tabs.tabs-2 .ui-tabs-nav li:hover a {
    color: #232323 !important;
    border-color: #e1bd85
}

.tabs.tabs-2 .ui-tabs-nav li.ui-tabs-active a {
    background-color: #e1bd85;
    color: #fff !important
}

.accordion .ui-accordion-header {
    background-color: #e1bd85;
    color: #fff;
    padding: 10px 20px;
    font-family: 'Hind';
    text-transform: uppercase;
    font-weight: normal;
    margin-top: 10px;
    border-width: 0;
    outline: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.accordion .ui-accordion-header .ui-accordion-header-icon {
    display: none
}

.accordion .ui-accordion-header:first-child {
    margin-top: 0
}

.accordion .ui-accordion-content {
    background-color: transparent;
    padding: 15px 20px;
    border: 2px solid #e1bd85;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.alert {
    line-height: 1.5em;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.awe-calendar-wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.awe-calendar-wrapper .awe-calendar {
    padding: 0 30px 0 10px;
    background-color: transparent;
    color: #5c5c5c;
    width: 100%;
    border: 1px solid #a3a3a3;
    height: 40px;
    line-height: 36px
}

.awe-calendar-wrapper .awe-calendar::-webkit-input-placeholder {
    color: #333
}

.awe-calendar-wrapper .awe-calendar:-moz-placeholder {
    color: #333
}

.awe-calendar-wrapper .awe-calendar::-moz-placeholder {
    color: #333
}

.awe-calendar-wrapper .awe-calendar:-ms-input-placeholder {
    color: #333
}

.awe-calendar-wrapper i {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 14px;
    color: #333;
    pointer-events: none;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.awe-calendar-wrapper1 {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    margin-top: 20px
}

.awe-calendar-wrapper1 .awe-calendar1 {
    padding: 0 30px 0 10px;
    background-color: transparent;
    color: #333333;
    width: 100%;
    border: 1px solid #333333;
    height: 40px;
    line-height: 36px
}

.awe-calendar-wrapper1 .awe-calendar1::-webkit-input-placeholder {
    color: #333
}

.awe-calendar-wrapper1 .awe-calendar1:-moz-placeholder {
    color: #333
}

.awe-calendar-wrapper1 .awe-calendar1::-moz-placeholder {
    color: #333
}

.awe-calendar-wrapper1 .awe-calendar1:-ms-input-placeholder {
    color: #333
}

.awe-calendar-wrapper1 i {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 14px;
    color: #333;
    pointer-events: none;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.bootstrap-select.btn-group.awe-select {
    width: auto
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: 1px solid #fff;
    height: 40px;
    line-height: 36px;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 315px;
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle .caret {
    color: #fff
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option {
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px
}

.bootstrap-select.btn-group.awe-select .dropdown-menu {
    margin: 0;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li a {
    padding: 10px 20px;
    font-family: 'Work Sans', sans-serif;
    color: #5c5c5c;
    font-size: 16px;
    border-bottom: 1px solid #f1f1f1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li a:hover {
    background-color: #e1bd85;
    color: #fff;
    border-bottom-color: #e1bd85
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li:last-child a {
    border-bottom-width: 0
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li.selected a {
    background-color: #e1bd85;
    color: #fff;
    border-bottom-color: #e1bd85
}

.tab-price table {
    width: 100%;
    vertical-align: middle
}

.tab-price table caption {
    text-align: center;
    position: relative;
    padding: 0
}

.tab-price table caption h2 {
    margin: 0;
    color: #333333;
    font-size: 16.37px;
    line-height: 50px;
    text-transform: uppercase;
    font-family: 'Montserrat'
}

.tab-price table caption a {
    display: block;
    position: absolute;
    text-align: center;
    line-height: 50px;
    width: 40px;
    top: 0;
    bottom: 0;
    font-size: 18px;
    color: #333;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.tab-price table caption a:hover {
    color: #e1bd85
}

.tab-price table caption a.prev {
    left: 0
}

.tab-price table caption a.next {
    right: 0
}

.tab-price table thead {
    border: 2px solid #333
}

.tab-price table thead th {
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: normal;
    padding: 15px 10px
}

.tab-price table tr td {
    padding: 15px 10px;
    border: 2px solid #333;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    color: #333333;
    text-align: center;
    position: relative
}

.tab-price table tr td .date {
    font-size: 13.6px;
    position: absolute;
    top: 5px;
    right: 10px
}

.tab-price table tr td.other-date .date {
    color: #b0afaf
}

.tab-price table tr td.date-select {
    background-color: #e1bd85;
    color: #fff
}

.ui-datepicker {
    background: #ffffff;
    border-width: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 0;
    width: 320px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.ui-datepicker .ui-datepicker-header {
    background-color: #d4ad6f;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: both;
    line-height: 50px
}

.ui-datepicker .ui-datepicker-header .ui-corner-all {
    position: static;
    display: block;
    height: inherit;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-width: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.ui-datepicker .ui-datepicker-header .ui-corner-all .ui-icon {
    background: none;
    color: #fff;
    text-indent: 0;
    position: static;
    margin: 0;
    width: inherit;
    height: inherit;
    display: inline
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev {
    float: left
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next {
    float: right
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover {
    background: #e1bd85
}

.ui-datepicker .ui-state-disabled,
.ui-datepicker .ui-widget-content .ui-state-disabled,
.ui-datepicker .ui-widget-header .ui-state-disabled {
    opacity: .8
}

.ui-datepicker .ui-state-default,
.ui-datepicker .ui-widget-content .ui-state-default,
.ui-datepicker .ui-widget-header .ui-state-default {
    background: none;
    color: #333;
    border: 1px solid #f1f1f1;
    font-size: 12px;
    height: 33px;
    width: 33px;
    line-height: 25px;
    margin-left: 6px;
    margin-top: 8px;
    text-align: center
}

.ui-datepicker .ui-datepicker-calendar {
    width: 100%;
    margin: 0
}

.ui-datepicker .ui-datepicker-calendar thead th {
    text-transform: capitalize;
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    background-color: #e1bd85;
    padding: 12px 5px
}

.ui-datepicker .ui-datepicker-calendar tbody {
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff
}

.ui-datepicker .ui-datepicker-calendar td a {
    padding: 0;
    background: none;
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    border-width: 0;
    text-align: center;
    font-weight: 400;
    border: 1px solid #e4e4e4;
    line-height: 30px
}

.ui-datepicker .ui-datepicker-calendar td a.ui-state-active,
.ui-datepicker .ui-datepicker-calendar td a:hover {
    border: 1px solid #e1bd85
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a {
    background-color: #e1bd85;
    color: #fff;
    border-color: #e1bd85
}

.owl-controls .owl-prev,
.owl-controls .owl-next {
    color: #fff;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    bottom: 33%;
    font-size: 13px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border: 2px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.owl-controls .owl-prev:hover,
.owl-controls .owl-next:hover {
    color: #e1bd85;
    border-color: #e1bd85
}

.owl-controls .owl-prev {
    left: 10px
}

.owl-controls .owl-next {
    right: 10px
}

.owl-controls .owl-pagination {
    font-size: 0
}

.owl-controls .owl-pagination .owl-page {
    font-size: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    margin: 0 2px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.owl-controls .owl-pagination .owl-page.active {
    background-color: #fff
}

.hover-zoom {
    overflow: hidden;
    display: block
}

.hover-zoom img {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.hover-zoom:hover img {
    transform: scale(1.1)
}

.awe-overflow-h {
    overflow: hidden
}

.awe-popup-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.awe-popup-overlay:before,
.awe-popup-overlay:after {
    content: ''
}

.awe-popup-overlay.in {
    visibility: visible;
    opacity: 1
}

.awe-popup-wrap {
    position: fixed;
    z-index: 999999;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: hidden;
    opacity: 0;
    top: 0;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.awe-popup-wrap.in {
    visibility: visible;
    opacity: 1
}

.awe-popup-wrap .awe-popup-content {
    display: inline-block;
    text-align: left
}

.awe-popup-wrap .awe-popup-close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px
}

.awe-popup-wrap .awe-popup-close:after,
.awe-popup-wrap .awe-popup-close:before {
    content: '';
    height: 2px;
    width: 20px;
    background-color: #e1bd85;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

.awe-popup-wrap .awe-popup-close:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.awe-popup-wrap .awe-popup-close:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.space-5 {
    margin: 12px 0 18px 0;
    display: block
}



#header {

    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    transition: all .3s ease 0s;
}

#header.header-v2 .header_content:after {
    background: rgba(0, 0, 0, 0.6)
}

#header.header-v2 .header_content .menu li:hover a {
    background: #030404
}

#header.header-v2 .header_content .menu li a {
    color: #fff
}

#header.header-v2 .header_content .menu li.current-menu-item a {
    background: #030404
}

#header.header-v2 .header_content .menu li .sub-menu li a {
    color: #333;
    background: #fff
}

#header.header-v2 .header_content .menu li .sub-menu li a:hover {
    color: #fff;
    background: #e1bd85
}

#header.header-v2 .header_content .menu li .sub-menu li.current-menu-item a {
    color: #fff;
    background: #e1bd85
}

#header.header-sticky {
    margin-top: -125px
}

#header.header-sticky img {
    margin-top: 20px
}

#header.header-sticky .header_menu {
    margin-top: 28px;
}

.header_top {
    background-color: #f6f6f6;
}

.header_top .language p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.055em;
    text-align: right;
}

.header_top .language p a:link {
    color: #5c5c5c;
}

.header_top .language p a:hover {
    color: #D4A355;
}

.header_top:after {
    display: table;
    content: '';
    clear: both
}

.header_top .header_left span {
    font-size: 12px;
    color: #777;
    margin-right: 15px;
    line-height: 36px
}

.header_top .header_left span i {
    font-size: 11px;
    margin-right: 5px
}

.header_top .header_right {
    font-size: 0
}

.header_top .header_right .login-register {
    text-transform: uppercase
}

.header_top .header_right .login-register a {
    font-size: 12px;
    line-height: 36px;
    color: #777;
    display: inline-block;
    padding: 0 10px;

    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.header_top .header_right .login-register a:hover,
.header_top .header_right .login-register a.active {
    color: #000;
}

.header_top .header_right .dropdown {
    font-size: 12px;
    position: relative;
    display: inline-block
}

.header_top .header_right .dropdown span {
    color: #000;
    line-height: 36px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.header_top .header_right .dropdown span:after {
    content: '\f0d7';
    font-family: 'FontAwesome';
    margin-left: 5px
}

.header_top .header_right .dropdown ul {
    line-height: none;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    background-color: #fff;
    position: absolute;
    min-width: 75px;
    right: 0;
    width: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.58)
}

.header_top .header_right .dropdown ul li a {
    color: #333333;
    font-size: 12px;
    display: block;
    padding: 5px 10px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.header_top .header_right .dropdown ul li a:hover {
    background-color: #e1bd85;
    color: #000
}

.header_top .header_right .dropdown ul li.active a {
    background-color: #d4a355;
    color: #000 !important
}

.header_top .header_right .dropdown:hover span {

    color: #000
}

.header_top .header_right .dropdown:hover ul {
    visibility: visible;
    opacity: 1
}

#header.header-sticky .header_content:after {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.58);
    background-color: #f6f6f6;
}

.header_content {
    position: relative;
    left: 0;
    right: 0;
    top: 100%;
    padding: 0 0;
    text-align: right;
    font-size: 0;
    z-index: 999
}

.header_content:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
}

.header_content .container {
    position: relative
}

.header_content .header_menu {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 16px;
}

.header_content .menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.header_content .menu>li {
    float: left;
    position: relative
}

.header_content .menu>li>a {
    display: block;
    padding: 0px 12px 15px;
    color: #5c5c5c;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.055em;
}

.header_content .menu>li.current-menu-item>a {

    color: #d4a355;
}

.header_content .menu>li:hover>a {
    color: #d4a355;
}

.header_content .menu>li>a.active {
    color: #d4a355 !important;
}

.header_content .menu>li:hover>.sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0)
}

.header_content .menu>li>.sub-menu {
    left: 0;
    right: 0;
    margin: auto
}

.header_content .menu>li .sub-menu {
    min-width: 210px;
    position: absolute;
    left: 0;
    background-color: #fff;
    list-style: none;
    padding: 0px 0;
    margin: 0px;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate(0, 20px);
    -moz-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    text-align: left;
    width: fit-content;
    white-space: nowrap;
}

.header_content .menu>li .sub-menu li {
    float: none;
    position: relative
}

.header_content .menu>li .sub-menu li a {
    display: block;
    padding: 6px 15px;
    color: #5c5c5c;
    font-size: 14px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    letter-spacing: .02em;
}

.header_content .menu>li .sub-menu li a span {
    float: right;
    position: relative;
    top: 2px
}

.header_content .menu>li .sub-menu li.current-menu-item>a {
    background-color: #e1bd85;
    color: #fff
}

.header_content .menu>li .sub-menu li:hover>a {

    color: #5c5c5c;
    background: #e5e5e5;
}

.header_content .menu>li .sub-menu li:hover>.sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0)
}

.header_content .menu>li .sub-menu li .sub-menu {
    left: 100%;
    top: 0;
    padding: 0;
    margin-top: 0px;
}

.header_logo {
    line-height: 84px;
    text-align: center;
    z-index: 100;
    position: relative;
}

.header_logo img {
    max-height: 85px;
    margin: 8px;
}

.menu-bars {
    color: #333;
    width: 50px;
    font-size: 28px;
    cursor: pointer;
    display: none;
    top: -100px;
    bottom: 0;
    position: absolute;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    z-index: 1000;
}

.menu-bars span {
    width: 25px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 2px;
    background-color: #333;
    display: block;
    position: absolute;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.menu-bars span:before,
.menu-bars span:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.menu-bars span:after {
    top: -8px
}

.menu-bars span:before {
    bottom: -8px
}


.menu-bars:hover {
    background-color: rgb(246, 246, 246);
}

/*.menu-bars:hover span {
    background-color: transparent
}

.menu-bars:hover span:after {
    background-color: #e1bd85;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.menu-bars:hover span:before {
    background-color: #e1bd85;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}*/

.menu-bars.active {
    background-color: rgb(246, 246, 246);
}

.menu-bars.active span {
    background-color: transparent
}

.menu-bars.active span:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.menu-bars.active span:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.menu-bars.active span::before {
    bottom: 0px;
    width: 30px;
}

.menu-bars.active span::after {
    top: 0px;
    width: 30px;
}

.header_mobile {
    background-color: rgba(254, 251, 234, 1);
    left: 0;
    right: 0;
    z-index: 999;
    position: relative;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.58)
}

.header_mobile .container {
    position: relative;
}

.header_mobile .header_logo {
    display: inline-block
}

.header_mobile .menu-bars {
    display: block;
    text-align: left;
}

.header_mobile .header_menu {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    overflow-y: auto;
    background-color: #f6f6f6;
    -webkit-transition: left .5s cubic-bezier(0, .67, .35, .94);
    -moz-transition: left .5s cubic-bezier(0, .67, .35, .94);
    -ms-transition: left .5s cubic-bezier(0, .67, .35, .94);
    -o-transition: left .5s cubic-bezier(0, .67, .35, .94);
    padding-top: 100px;
}

.header_mobile .header_menu.active {
    left: 0
}

.header_mobile .header_menu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    text-align: left;
}

.header_mobile .header_menu ul li a {
    display: block;
    padding: 10px 40px 10px 20px;
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 300;
    position: relative;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.header_mobile .header_menu ul li .sub-menu.show {
    display: block;
}

.header_mobile .header_menu ul li a span {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 1;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0.1)
}

.header_mobile .header_menu ul li a span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.header_mobile .header_menu ul li a:hover {
    color: #D4A355
}

.header_mobile .header_menu ul li.current-menu-item>a {
    color: #D4A355
}

.header_mobile .header_menu ul li .sub-menu {
    display: none
}

.header_mobile .header_menu ul li>.sub-menu>li>a {
    padding-left: 35px
}

.header_mobile .header_menu ul li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 55px
}

.header_mobile .header_menu ul li>.sub-menu>li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 75px
}

.header_mobile .header_menu ul li>.sub-menu>li>.sub-menu>li>.sub-menu>.sub-menu>li>a {
    padding-left: 100px
}

.header_mobile .header_logo {
    line-height: 1
}

.header-sticky {
    position: fixed;
    top: 0;
    -webkit-backface-visibility: hidden
}

.section-slider {
    position: relative;
}

.custom-arrow.prev {
    left: 20px;
}

.custom-arrow.next {
    right: 20px;
}

.custom-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

#slider-revolution ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0
}

.banner-slider .slider-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative
}

.banner-slider .slider-item .slider-text {
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.banner-slider .slider-item .slider-text .slider-caption {
    padding: 7px 0
}

.banner-slider .slider-item .slider-text .awe-btn {
    margin-top: 30px
}

.banner-slider .owl-prev,
.banner-slider .owl-next {
    height: 38px;
    line-height: 38px;
    width: 38px
}

.banner-slider .owl-prev {
    left: 50px
}

.banner-slider .owl-next {
    right: 50px
}

.slider-caption-sub {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1;
    display: inline-block
}

.slider-caption-sub.slider-caption-sub-1 {
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 28px
}

.slider-caption-sub.slider-caption-sub-2 {
    color: #333333
}

.slider-caption-sub.slider-caption-sub-3 {
    font-size: 26px;
    font-family: 'Montserrat'
}

.slider-caption {
    font-size: 60px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    line-height: 1
}

.slider-caption small {
    display: inline-block;
    line-height: 1;
    font-weight: 500;
    font-family: 'Hind';
    font-size: 30px;
    color: #fff;
    line-height: 1.15em
}

.slider-caption.slider-caption-1 {
    font-weight: bold
}

.slider-caption.slider-caption-2 {
    color: #333333;
    font-weight: bold;
    font-size: 80px
}

.slider-caption.slider-caption-2 small {
    color: #333
}

.slider-caption.slider-caption-3 {
    font-size: 80px
}

.slider-icon {
    padding: 5px 0;
    display: inline-block
}

.awe-btn.awe-btn-slider {
    font-size: 14px;
    padding: 10px 30px;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none
}

#preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
    background-color: #f1f1f1
}

#preloader .preloader-brand {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -75px;
    margin-top: -56px;
    z-index: 1000;
}

@keyframes mymove {
    0% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}

.section-sub-banner {
    padding-top: 80px
}

.sub-banner {
    position: relative
}

.sub-banner .text {
    padding-bottom: 50px
}

.sub-banner .text h2 {
    margin-top: 70px;
    margin-bottom: 0;
    color: #fff;
    font-size: 35.68px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.sub-banner .text p {
    margin-bottom: 0;
    color: #fff;
    font-size: 20px
}

.section-accommo_1 {
    padding-top: 50px
}

.accomd-modations {
    padding: 60px 0;
    position: relative
}

.accomd-modations .accomd-modations-content {
    margin-top: -30px
}

.accomd-modations-content .accomd-modations-room {
    margin-top: 30px
}

.accomd-modations-content.owl-single .owl-next,
.accomd-modations-content.owl-single .owl-prev {
    display: none
}

.accomd-modations-content.owl-single .owl-pagination {
    text-align: center;
    margin-top: 20px
}

.accomd-modations-header {
    margin-top: 30px;
    text-align: center
}

.accomd-modations-header p {
    margin: 10px auto 20px;
    max-width: 635px
}

.accomd-modations-room {
    position: relative;
    overflow: hidden
}

.accomd-modations-room .img {
    position: relative
}

.accomd-modations-room .img img {
    width: 100%;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.accomd-modations-room .text {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    bottom: 20px;
    left: 0;
    right: 0
}

.accomd-modations-room .text h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    font-family: 'Hind';
    float: left
}

.accomd-modations-room .text h2 a {
    color: #fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.accomd-modations-room .text h2 a:hover {
    color: #e1bd85
}

.accomd-modations-room .text .price {
    color: #e1bd85;
    margin-bottom: 0;
    font-family: 'Work Sans', sans-serif;
    margin-top: 0;
    float: right
}

.accomd-modations-room .text .price .amout {
    color: #fff;
    font-size: 26px;
    margin-right: 10px
}

.accomd-modations-room:hover img {
    transform: scale(1.1)
}

.accomd-modations_1 .heading {
    margin-bottom: 40px
}

.accomd-modations-content .owl-pagination .owl-page {
    border-color: #333;
    margin: 0 4px
}

.accomd-modations-content .owl-pagination .owl-page.active {
    background-color: #333
}

.accomd-modations-content_1 {
    margin-left: -15px;
    margin-right: -15px
}

.accomd-modations-content_1 .owl-item {
    padding-left: 15px;
    padding-right: 15px
}

.accomd-modations-content_1 .owl-pagination {
    position: absolute;
    top: -60px;
    right: 15px
}

.accomd-modations-content_1 .owl-pagination .owl-page {
    border-color: #333;
    margin: 0 4px
}

.accomd-modations-content_1 .owl-pagination .owl-page.active {
    background-color: #333
}

.accomd-modations-room_1 {
    border: 1px solid #9a9a9a
}

.accomd-modations-room_1 .img {
    position: relative
}

.accomd-modations-room_1 .img a {
    display: inline-block;
    overflow: hidden
}

.accomd-modations-room_1 .img img {
    width: 100%;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.accomd-modations-room_1 .text {
    margin-top: 20px
}

.accomd-modations-room_1 .text h2 {
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    padding: 0 17px
}

.accomd-modations-room_1 .text h2 a {
    color: #333333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.accomd-modations-room_1 .text h2 a:hover {
    color: #e1bd85
}

.accomd-modations-room_1 .text .desc {
    color: #333333;
    font-size: 15px;
    margin-bottom: 25px;
    line-height: 1.5em;
    margin-top: 5px;
    padding: 0 17px
}

.accomd-modations-room_1 .text .price {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    color: #898989;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    width: 60%;
    padding-left: 17px;
    padding-top: 12px
}

.accomd-modations-room_1 .text .price .amout {
    color: #e1bd85;
    font-size: 30px
}

.accomd-modations-room_1 .text .wrap-price {
    display: inline-block;
    width: 100%;
    height: 50px;
    border-top: 1px solid #9a9a9a;
    position: relative
}

.accomd-modations-room_1 .text .wrap-price .awe-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 49px;
    border: none;
    border-left: 1px solid #9a9a9a;
    padding: 0 25px;
    font-size: 14px;
    color: #232323;
    font-family: 'Hind';
    line-height: 49px
}

.accomd-modations-room_1 .text .wrap-price .awe-btn:hover {
    color: #fff
}

.accomd-modations-room_1 .text .wrap-price .awe-btn:hover:after {
    color: #fff
}

.accomd-modations-room_1 .text .wrap-price .awe-btn:after {
    content: "";
    font-family: "FontAwesome";
    font-size: 14px;
    margin-left: 5px;
    transition: all .3s ease 0s
}

.accomd-modations-room_1:hover img {
    transform: scale(1.1)
}

.section-check-availability {
    position: relative;
    z-index: 20;
    float: left;
    width: 100%;
    display: inline-block;
}

.check-availability {
    padding: 20px 0;
    margin-top: -90px
}

.check-availability:before {
    top: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, 0.95);
    opacity: .85;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 0;
    height: 83px;
    top: -83px
}

.check-availability h2 {
    color: #fff;
    font-size: 25px;
    margin: 0;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
    position: relative;
    padding-left: 60px;
    line-height: 1
}

.check-availability h2:before {
    content: '\f00c';
    border: 3px solid #e1bd85;
    color: #e1bd85;
    font-size: 18px;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 34px;
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.check-availability .availability-form {
    font-size: 0;
    text-align: center;
    margin-top: 8px;
    position: relative
}

.check-availability .availability-form .awe-calendar-wrapper {
    width: 150px;
    margin-right: 20px
}

.check-availability .availability-form .awe-calendar-wrapper .awe-calendar {
    border-color: #fff;
    color: #fff
}

.check-availability .availability-form .awe-calendar-wrapper .awe-calendar::-webkit-input-placeholder {
    color: #fff
}

.check-availability .availability-form .awe-calendar-wrapper .awe-calendar:-moz-placeholder {
    color: #fff
}

.check-availability .availability-form .awe-calendar-wrapper .awe-calendar::-moz-placeholder {
    color: #fff
}

.check-availability .availability-form .awe-calendar-wrapper .awe-calendar:-ms-input-placeholder {
    color: #fff
}

.check-availability .availability-form .awe-calendar-wrapper i {
    color: #fff
}

.check-availability .availability-form .bootstrap-select.btn-group.awe-select {
    margin-right: 20px;
    width: 110px
}

.check-availability .availability-form .bootstrap-select.btn-group.awe-select .caret {
    border: none;
    background: url(./images/icon-select-cal.png) no-repeat;
    width: 13px;
    height: 7px
}

.check-availability .availability-form .vailability-submit {
    vertical-align: middle;
    display: inline-block
}

.check-availability .availability-form .vailability-submit .awe-btn {
    height: 40px;
    color: #fff;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px
}

.section-check-availability1 {
    position: relative;
    z-index: 20;
    float: left;
    width: 100%;
    display: inline-block;
}

.check-availability1 {
    padding: 20px 0;
    margin-top: 0px
}

.check-availability1:before {
    top: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, 0.95);
    opacity: .85;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 0;
    height: 83px;
    top: 0px
}

.check-availability1 h2 {
    color: #fff;
    font-size: 25px;
    margin: 0;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
    position: relative;
    padding-left: 60px;
    line-height: 1
}

.check-availability1 h2:before {
    content: '\f00c';
    border: 3px solid #e1bd85;
    color: #e1bd85;
    font-size: 18px;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 34px;
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.check-availability1 .availability-form1 {
    font-size: 0;
    text-align: center;
    margin-top: 0px;
    position: relative
}

.check-availability1 .availability-form1 .awe-calendar-wrapper {
    width: 150px;
    margin-right: 20px
}

.check-availability1 .availability-form1 .awe-calendar-wrapper .awe-calendar {
    border-color: #fff;
    color: #fff
}

.check-availability1 .availability-form1 .awe-calendar-wrapper .awe-calendar::-webkit-input-placeholder {
    color: #fff
}

.check-availability1 .availability-form1 .awe-calendar-wrapper .awe-calendar:-moz-placeholder {
    color: #fff
}

.check-availability1 .availability-form1 .awe-calendar-wrapper .awe-calendar::-moz-placeholder {
    color: #fff
}

.check-availability1 .availability-form1 .awe-calendar-wrapper .awe-calendar:-ms-input-placeholder {
    color: #fff
}

.check-availability1 .availability-form1 .awe-calendar-wrapper i {
    color: #fff
}

.check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select {
    margin-right: 20px;
    width: 110px
}

.check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select .caret {
    border: none;
    background: url(./images/icon-select-cal.png) no-repeat;
    width: 13px;
    height: 7px
}

.check-availability1 .availability-form1 .vailability-submit {
    vertical-align: middle;
    display: inline-block
}

.check-availability1 .availability-form1 .vailability-submit .awe-btn {
    height: 40px;
    color: #fff;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px
}

.section-home-about {
    padding: 40px 0 40px 0;
}

.home-about .img {
    position: relative
}

.home-about .img img {
    width: 100%
}

.home-about .text .heading {
    margin-top: 15px
}

.home-about .text span.box-border {
    width: 250px
}

.home-about .text p {
    color: #5c5c5c;
    font-size: 18px;
}

.home-about .text .awe-btn {
    margin-top: 25px
}

.section-our-best {
    padding-top: 40px;
    padding-bottom: 70px
}

.our-best .img {
    margin-top: 30px
}

.our-best .img img {
    width: 100%
}

.our-best .text {
    margin-top: 30px;
    position: relative
}

.our-best .text p {
    color: #333;
    margin-bottom: 0;
    margin-top: 25px;
    line-height: 1.428em
}

.our-best .text ul {
    list-style: none;
    padding-left: 0;
    margin-top: 25px;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 0;
    display: table
}

.our-best .text ul li {
    margin-top: 15px;
    color: #232323;
    font-weight: 500;
    width: -moz-calc(50% - 1px);
    width: -o-calc(50% - 1px);
    width: -webkit-calc(50% - 1px);
    width: calc(50% - 1px);
    font-size: 14px;
    display: inline-block;
    padding-right: 15px;
    margin-bottom: 15px
}

.our-best .text ul li img {
    margin-right: 17px
}

.our-best .text ul li:nth-child(even) {
    padding-right: 0;
    padding-left: 15px
}

.home-guestbook {
    padding: 50px 0;
    position: relative
}

.home-guestbook:after {
    display: table;
    content: '';
    clear: both
}

.guestbook-content .owl-buttons {
    display: none
}

.guestbook-content .owl-pagination {
    margin-top: 20px;
    margin-left: 0;
    text-align: center
}

.guestbook-header {
    position: relative;
    z-index: 1
}

.guestbook-header p {
    color: #232323;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 1.428em
}

.guestbook-header .awe-btn {
    margin-top: 40px
}

.guestbook-item {
    text-align: center
}

.guestbook-item .img {
    overflow: hidden;
    width: 115px;
    height: 115px;
    margin: 30px auto;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.guestbook-item .text {
    overflow: hidden;
    padding-top: 10px;
    padding-left: 20px
}

.guestbook-item .text p {
    color: #ffffff;
    line-height: 1.4em;
    font-size: 15px;
    position: relative;
    margin-bottom: 15px
}

.guestbook-item .text p:before {
    position: absolute;
    font-family: 'Work Sans', sans-serif;
    content: '';
    top: 0;
    left: -20px;
    font-size: 40px
}

.guestbook-item .text p:after {
    font-family: 'Work Sans', sans-serif;
    content: '„';
    font-size: 40px;
    line-height: 0;
    margin-left: 10px
}

.guestbook-item .text span {
    color: #ffffff;
    display: inline-block;
    width: 100%
}

.guestbook-item .text span strong {
    font-size: 20px
}

.section-event-news {
    padding-top: 70px
}

.section-event-news .news {
    margin-top: 40px
}

.event-news {
    margin-top: -40px;
    position: relative;
    padding-bottom: 70px
}

.event-news p {
    color: #636363;
    margin-bottom: 0
}

.event-news .hr {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
}

.news {
    overflow: hidden
}

.news p {
    margin-bottom: 5px
}

.news .news-item {
    margin-top: 17px
}

.news .awe-btn {
    margin-top: 20px
}

.news-item {
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 0;
    background-color: #fafafa
}

.news-item .img {
    width: 50%;
    padding-left: 15px;
    padding-right: 0;
    display: inline-block;
    font-size: 14px;
    position: relative;
    overflow: hidden
}

.news-item .img a {
    display: inline-block;
    width: 100%
}

.news-item .img img {
    width: 100%;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.news-item .text {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
    vertical-align: middle;
    width: -moz-calc(50% - 1px);
    width: -o-calc(50% - 1px);
    width: -webkit-calc(50% - 1px);
    width: calc(50% - 1px);
    vertical-align: top
}

.news-item .text .date {
    color: #898989;
    font-size: 20px
}

.news-item .text h2 {
    font-size: 17px;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.news-item .text h2 a {
    color: #e1bd85;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.news-item .text h2 a:hover {
    color: #e1bd85
}

.news-item .text .read-more {
    display: inline-block;
    margin-top: 10px;
    color: #898989;
    font-size: 13px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.news-item .text .read-more:hover {
    color: #e1bd85
}

.news-item:hover img {
    transform: scale(1.1)
}

.event {
    margin-top: 40px
}

.event .event-slide .owl-prev,
.event .event-slide .owl-next {
    display: none
}

.event .event-slide .owl-pagination {
    position: absolute;
    bottom: 15px;
    left: 17px
}

.event .event-item {
    margin-top: 30px
}

.event-item {
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}

.event-item .hover-zoom {
    overflow: visible
}

.event-item .img {
    width: 100%
}

.event-item .img img {
    width: 100%
}

.event-item .text {
    position: absolute;
    width: 130px;
    top: 0;
    left: 20px;
    bottom: 0;
    z-index: 9
}

.event-item .text:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: .9;
    top: 0;
    left: 0;
    z-index: -1
}

.event-item .text .text-cn {
    position: absolute;
    padding: 10px;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.event-item .text h2 {
    font-size: 26px;
    font-family: 'Work Sans', sans-serif;
    line-height: 1.3em;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase
}

.event-item .text span {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    display: block
}

.event-item .text .awe-btn {
    margin-top: 10px
}

.section-guestbook-event {
    padding-top: 70px
}

.guestbook-event {
    margin-top: -70px
}

.guestbook-event .heading {
    margin-top: 70px
}

.guestbook-event .guestbook-content_1 {
    margin-top: 60px
}

.guestbook-event .guestbook-content_1 .owl-prev,
.guestbook-event .guestbook-content_1 .owl-next {
    display: none
}

.guestbook-event .guestbook-content_1 .owl-pagination {
    margin-top: 45px
}

.guestbook-event .guestbook-content_1 .owl-pagination .owl-page {
    border-color: #333
}

.guestbook-event .guestbook-content_1 .owl-pagination .owl-page.active {
    background-color: #333
}

.guestbook-event .event-slide {
    margin-top: 40px
}

.guestbook-event .event-slide .owl-prev,
.guestbook-event .event-slide .owl-next {
    display: none
}

.guestbook-event .event-slide .owl-pagination {
    position: absolute;
    bottom: 15px;
    left: 20px
}

.guestbook-item_1 {
    overflow: hidden
}

.guestbook-item_1 .img img {
    max-width: 115px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.guestbook-item_1 .img span {
    display: inline-block;
    vertical-align: middle;
    color: #898989;
    font-size: 13px
}

.guestbook-item_1 .img span strong {
    display: block;
    color: #333;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 3px
}

.guestbook-item_1 .text {
    color: #333333;
    margin-top: 40px
}

.guestbook-item_1 .text p {
    margin-bottom: 0;
    line-height: 1.4em
}

.section-gallery {
    padding-top: 60px;
    padding-bottom: 70px
}

.section-icon {
    padding: 50px 0
}

.section-icon span {
    margin-top: 20px;
    display: block;
    font-size: 20px;
    position: relative;
    padding-left: 50px
}

.section-icon span:before {
    position: absolute;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    font-size: 14px
}

.news-item .text {
    padding-top: 10px
}

.section_page-gallery {
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 120px;
}

.gallery .heading {
    margin-bottom: 40px
}

.gallery.no-padding .gallery-cat {
    padding-left: 15px;
    padding-right: 15px
}

.gallery.no-padding .gallery-content {
    margin-top: 40px
}

.gallery.no-padding .gallery-content .row {
    margin-left: 0;
    margin-right: 0
}

.gallery.no-padding .gallery-content [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0
}

.gallery.no-padding .gallery-content .item {
    margin-top: 0
}

.gallery.gallery-3 .gallery-content [class*="col-"] {
    margin-top: 30px
}

.gallery-content {
    margin-top: 20px
}

.gallery-content .gallery_item {
    position: relative;
    overflow: hidden
}

.gallery-content .gallery_item img {
    width: 100%
}

.gallery-content .gallery_item .icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    line-height: 1;
    font-size: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    background: url(./images/icon-galery.png) no-repeat;
    width: 64px;
    height: 64px
}

.gallery-content .gallery_item:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000));
    background: -webkit-linear-gradient(top, transparent, #000000);
    background: -moz-linear-gradient(top, transparent, #000000);
    background: -ms-linear-gradient(top, transparent, #000000);
    background: -o-linear-gradient(top, transparent, #000000)
}

.gallery-content .gallery_item .text {
    position: absolute;
    bottom: 10px;
    left: 15px;
    right: 15px;
    color: #fff;
    z-index: 10;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    pointer-events: none;
    font-weight: normal;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    -o-transform: translate(0, 50%);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    max-width: 280px
}

.gallery-content .gallery_item:hover .text {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0)
}

.gallery-content .gallery_item:hover:before {
    top: 0;
    opacity: .9
}

.gallery-content .our-gallery {
    margin-top: 30px
}

.gallery-content .our-gallery .awe-btn {
    min-width: 180px
}

.gallery-content.hover-img .gallery_item:before {
    background: none
}

.gallery-content.hover-img .gallery_item img {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.gallery-content.hover-img .gallery_item:hover img {
    transform: scale(1.1)
}

.gallery-isotope .item-isotope {
    float: left;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px
}

.gallery-isotope.pd-0 .item-isotope {
    padding: 0;
    margin-top: 0
}

.gallery-isotope.col-3 .item-isotope,
.gallery-isotope.col-3 .item-size {
    width: 33.333333%
}

.gallery-isotope.col-4 .item-isotope,
.gallery-isotope.col-4 .item-size {
    width: 25%
}

.gallery-isotope.col-4 .item-isotope.larger {
    width: 50%
}

.gallery-isotope.col-6 .item-isotope,
.gallery-isotope.col-6 .item-size {
    width: 16.66666667%
}

.gallery-cat ul {
    margin-bottom: 0;
    font-size: 0
}

.gallery-cat ul li {
    font-size: 16px
}

.gallery-cat ul li a {
    color: #232323;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    padding: 7px 15px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.gallery-cat ul li:hover a,
.gallery-cat ul li.active a {
    background-color: #e1bd85;
    color: #fff
}

.mfp-wrap {
    cursor: default
}

.mfp-wrap .mfp-content .mfp-figure {
    padding-bottom: 0;
    background-color: #1f232b;
    position: relative
}

.mfp-wrap .mfp-content .mfp-figure:after {
    content: none
}

.mfp-wrap .mfp-content .mfp-figure .mfp-close {
    right: 7px;
    top: 7px;
    position: absolute;
    padding: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: #7c7c7c;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.mfp-wrap .mfp-content .mfp-figure .mfp-close:hover {
    background-color: #e1bd85
}

.mfp-wrap .mfp-content .mfp-figure .mfp-img {
    padding: 30px 30px 60px 30px
}

.mfp-wrap .mfp-content .mfp-figure .mfp-bottom-bar {
    position: absolute;
    margin: 0;
    text-align: center;
    font-weight: 500;
    bottom: 17px;
    top: inherit;
    left: 30px;
    right: 30px;
    width: inherit
}

.mfp-wrap .mfp-content .mfp-figure .mfp-bottom-bar .mfp-title {
    position: static;
    display: inline-block;
    color: #fff;
    padding-right: 10px;
    font-size: 16px
}

.mfp-wrap .mfp-content .mfp-figure .mfp-bottom-bar .mfp-counter {
    display: inline-block;
    position: static;
    font-size: 16px;
    colo: #898989
}

.mfp-wrap .mfp-prevent-close {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    width: 36px;
    height: 36px;
    line-height: 30px;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.mfp-wrap .mfp-prevent-close:hover {
    color: #e1bd85;
    border-color: #e1bd85
}

.mfp-wrap .mfp-prevent-close.mfp-prevent-left {
    left: 10px
}

.mfp-wrap .mfp-prevent-close.mfp-prevent-right {
    right: 10px
}

.section-blog {
    padding-bottom: 50px
}

.sidebar {
    margin-right: 70px
}

.sidebar .widget {
    margin-top: 40px
}

.sidebar .widget .widget-title {
    color: #232323;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase
}

.sidebar .widget ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.sidebar .widget.widget_check_availability {
    background-color: #f1f1f1
}

.sidebar .widget.widget_check_availability .widget-title {
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: #333333;
    line-height: 50px
}

.sidebar .widget.widget_check_availability .check_availability {
    padding: 0 20px 30px 20px;
    border-top: 1px solid #e4e4e4
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_title {
    margin-top: 25px;
    color: #e1bd85;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_title:before {
    content: '';
    display: inline-block;
    height: 10px;
    border-left: 2px solid #38486a;
    margin-right: 10px
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field {
    margin-top: 15px
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field label {
    display: block;
    clear: bold;
    color: #232323;
    font-size: 12px;
    margin-bottom: 10px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .awe-calendar-wrapper {
    width: 100%
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .awe-calendar-wrapper .awe-calendar {
    background-color: #fff;
    border-width: 0;
    height: 30px;
    line-height: 30px;
    color: #898989;
    font-weight: 500
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .bootstrap-select.btn-group.awe-select {
    width: 100%
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle {
    background-color: #fff;
    height: 30px;
    border-width: 0;
    line-height: 30px
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option,
.sidebar .widget.widget_check_availability .check_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle .caret {
    color: #333
}

.sidebar .widget.widget_check_availability .check_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-menu ul li a {
    padding: 7px 10px
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_group {
    padding-left: 70px;
    position: relative
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_group:after {
    display: table;
    content: '';
    clear: both
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_group .label-group {
    position: absolute;
    left: 0;
    bottom: 5px;
    font-weight: bold;
    font-size: 12px;
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_group .check_availability-field_group {
    margin-left: -4px;
    margin-right: -4px
}

.sidebar .widget.widget_check_availability .check_availability .check_availability_group .check_availability-field {
    width: 50%;
    padding-left: 4px;
    padding-right: 4px;
    float: left
}

.sidebar .widget.widget_check_availability .check_availability .awe-btn {
    margin-top: 30px;
    width: 100%;
    font-size: 14px;
    padding: 10px
}

.sidebar .widget .widget-deal .item {
    position: relative
}

.sidebar .widget .widget-deal .item:after {
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#232323));
    background: -webkit-linear-gradient(top, transparent, #232323);
    background: -moz-linear-gradient(top, transparent, #232323);
    background: -ms-linear-gradient(top, transparent, #232323);
    background: -o-linear-gradient(top, transparent, #232323)
}

.sidebar .widget .widget-deal .item .img {
    position: relative
}

.sidebar .widget .widget-deal .item .img img {
    width: 100%
}

.sidebar .widget .widget-deal .item .text {
    text-align: center;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 0 10px;
    z-index: 10
}

.sidebar .widget .widget-deal .item .text h2 {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    font-weight: normal
}

.sidebar .widget .widget-deal .item .text .price {
    color: #fff;
    font-weight: bold;
    font-size: 39px;
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 5px
}

.sidebar .widget .widget-deal .item .text .awe-btn {
    margin-top: 10px
}

.sidebar .widget .widget-deal .owl-controls .owl-buttons {
    display: none
}

.sidebar .widget .widget-deal .owl-controls .owl-pagination {
    position: absolute;
    bottom: 10px;
    right: 15px
}

.sidebar .widget.widget_categories ul {
    margin-top: -7px
}

.sidebar .widget.widget_categories ul li a {
    color: #333333;
    display: inline-block;
    padding: 7px 0;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.sidebar .widget.widget_categories ul li:hover>a,
.sidebar .widget.widget_categories ul li.current-cat>a {
    color: #e1bd85
}

.sidebar .widget.widget_categories ul li .sub-menu {
    margin-left: 20px;
    margin-top: 0
}

.sidebar .widget.widget_upcoming_events ul {
    margin-top: -10px
}

.sidebar .widget.widget_upcoming_events ul li {
    overflow: hidden;
    padding: 10px 0
}

.sidebar .widget.widget_upcoming_events ul li .event-date {
    border: 2px solid #232323;
    text-align: center;
    display: block;
    width: 70px;
    height: 68px;
    float: left;
    padding: 5px 10px;
    font-size: 16px;
    color: #000;
    margin-right: 20px;
    line-height: 1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.sidebar .widget.widget_upcoming_events ul li .event-date strong {
    margin-bottom: 3px;
    display: block;
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: normal
}

.sidebar .widget.widget_upcoming_events ul li .text {
    margin-top: 5px;
    overflow: hidden
}

.sidebar .widget.widget_upcoming_events ul li .text a {
    color: #232323;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.sidebar .widget.widget_upcoming_events ul li .text a:hover {
    color: #e1bd85
}

.sidebar .widget.widget_upcoming_events ul li .text .date {
    display: block;
    color: #898989;
    margin-top: 5px
}

.sidebar .widget.widget_upcoming_events ul li:hover .event-date {
    border-color: #e1bd85;
    color: #e1bd85
}

.sidebar .widget.widget_recent_entries ul {
    margin-top: -7px
}

.sidebar .widget.widget_recent_entries ul li {
    overflow: hidden;
    padding: 7px 0
}

.sidebar .widget.widget_recent_entries ul li .img {
    float: left;
    width: 70px;
    margin-right: 20px
}

.sidebar .widget.widget_recent_entries ul li .img img {
    width: 100%
}

.sidebar .widget.widget_recent_entries ul li a {
    text-transform: uppercase;
    color: #232323;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.sidebar .widget.widget_recent_entries ul li a:hover {
    color: #e1bd85
}

.sidebar .widget.widget_recent_entries ul li .date {
    display: block;
    color: #898989;
    margin-top: 5px
}

.sidebar .widget.widget_recent_entries.has_thumbnail ul {
    margin-top: -10px
}

.sidebar .widget.widget_recent_entries.has_thumbnail ul li {
    padding: 10px 0
}

.sidebar .widget.widget_tag_cloud .tagcloud {
    font-size: 0;
    margin-top: -10px
}

.sidebar .widget.widget_tag_cloud .tagcloud a {
    font-size: 12px;
    font-weight: 500;
    border: 2px solid #232323;
    display: inline-block;
    padding: 5px 15px;
    margin-right: 10px;
    margin-top: 10px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.sidebar .widget.widget_tag_cloud .tagcloud a:hover {
    color: #fff;
    background-color: #e1bd85;
    border-color: #e1bd85
}

.sidebar .widget.widget_social .widget-social {
    font-size: 0;
    margin-top: -10px
}

.sidebar .widget.widget_social .widget-social a {
    font-size: 18px;
    color: #232323;
    margin-top: 10px;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 25px;
    text-align: center;
    line-height: 32px;
    border: 2px solid #232323;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.sidebar .widget.widget_social .widget-social a:hover {
    color: #e1bd85;
    border-color: #e1bd85
}

.widgetsearch {
    position: relative;
    border: 2px solid #232323;
    font-size: 14px
}

.widgetsearch:before {
    content: '\f002';
    font-family: 'FontAwesome';
    position: absolute;
    right: 15px;
    font-size: 18px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.widgetsearch .input-text {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-right: 50px;
    border-width: 0;
    background-color: #fff;
    color: #333;
    font-weight: 500
}

.widgetsearch .input-text::-webkit-input-placeholder {
    color: #333
}

.widgetsearch .input-text:-moz-placeholder {
    color: #333
}

.widgetsearch .input-text::-moz-placeholder {
    color: #333
}

.widgetsearch .input-text:-ms-input-placeholder {
    color: #333
}

.widgetsearch .input-submit {
    display: none
}

.post {
    position: relative
}

.post .entry-media {
    position: relative
}

.post .entry-media img {
    width: 100%
}

.post .entry-media .count-date {
    background-color: rgba(225, 189, 133, 0.85);
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 20;
    color: #fff;
    padding: 15px 30px
}

.post .entry-media .count-date span {
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    line-height: 1
}

.post .entry-media .count-date span span {
    display: block;
    font-family: 'Hind';
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px
}

.post .entry-media .post-format {
    position: absolute;
    display: block;
    color: #fff;
    text-align: center;
    top: 50%;
    left: 50%;
    font-size: 50px;
    line-height: 1;
    pointer-events: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background: url("./images/icon-galery.png") no-repeat scroll 0 0
}

.post .entry-media .posted-on {
    position: absolute;
    bottom: -23px;
    left: 0;
    border: 2px solid #232323;
    text-align: center;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1;
    width: 70px;
    height: 70px;
    padding: 10px;
    -webkit-transform: translate(0, 100%);
    -moz-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    -o-transform: translate(0, 100%)
}

.post .entry-media .posted-on strong {
    display: block;
    font-family: 'Work Sans', sans-serif;
    font-size: 34px
}

.post .entry-media .owl-pagination {
    display: none
}

.post .entry-header {
    margin-top: 18px;
    padding-left: 100px
}

.post .entry-header h2 {
    font-weight: bold;
    margin: 0;
    font-size: 18px
}

.post .entry-header h2 a {
    font-family: 'Work Sans', sans-serif;
    color: #232323;
    text-transform: uppercase;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.post .entry-header h2 a:hover {
    color: #e1bd85
}

.post .entry-header .entry-meta {
    margin-top: 10px;
    margin-bottom: 0
}

.post.post-single .entry-header h1 {
    font-family: 'Work Sans', sans-serif;
    color: #232323;
    font-weight: bold;
    margin: 0;
    font-size: 22px;
    text-transform: uppercase
}

.post .entry-meta {
    font-size: 14px;
    color: #898989
}

.post .entry-meta:before {
    content: '';
    font-size: 10px;
    position: relative;
    top: -1px;
    margin-right: 10px;
    border-left: 2px solid #232323
}

.post .entry-meta .posted-on {
    display: none
}

.post .entry-meta .entry-categories:before,
.post .entry-meta .entry-comments-link:before {
    content: '-';
    padding: 0 2px
}

.post .entry-meta .entry-tags {
    margin-left: 20px
}

.post .entry-meta .entry-tags .screen-reader-text {
    color: #232323;
    margin-right: 5px
}

.post .entry-meta a {
    color: #898989;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.post .entry-meta a:hover {
    color: #e1bd85
}

.post .entry-meta a .entry-author-name {
    font-weight: bold;
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.post .entry-meta a .entry-author-name:hover {
    color: #e1bd85
}

.post .entry-content {
    color: #444444;
    font-size: 15px;
    margin-top: 10px;
    padding-left: 100px
}

.post .entry-content p {
    line-height: 1.5em;
    margin-bottom: 0
}

.post .entry-content a {
    color: #0072bc;
    text-decoration: underline
}

.post .entry-content a:hover {
    color: #e1bd85
}

.post .entry-footer {
    padding-left: 100px;
    margin-top: 30px
}

.post:hover .entry-media:before {
    opacity: .7;
    top: 0
}

.post.post-single .entry-content {
    color: #444444;
    margin-top: 20px;
    font-size: 15px
}

.post.post-single .entry-content h1,
.post.post-single .entry-content h2,
.post.post-single .entry-content h3,
.post.post-single .entry-content h4,
.post.post-single .entry-content h5,
.post.post-single .entry-content h6 {
    text-transform: uppercase;
    margin-bottom: 10px
}

.post.post-single .entry-content .wp-caption {
    max-width: 100%
}

.post.post-single .entry-content .aligncenter {
    margin-left: auto;
    margin-right: auto
}

.post.post-single .entry-content .alignleft {
    float: left;
    margin-right: 20px
}

.post.post-single .entry-content .alignright {
    float: right;
    margin-left: 20px
}

.post.post-single .entry-content p {
    margin-bottom: 0
}

.post.post-single .entry-content p em {
    font-size: 13px;
    line-height: 1.4em;
    color: #6e6e6e
}

.page-navigation {
    margin-top: 60px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0
}

.page-navigation li {
    display: inline-block;
    font-size: 15px;
    margin-right: 20px;
    vertical-align: middle
}

.page-navigation li a {
    color: #444444;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.page-navigation li a:hover {
    color: #e1bd85
}

.page-navigation li.first a,
.page-navigation li.last a {
    font-size: 13px;
    display: block;
    border: 2px solid #232323;
    width: 23px;
    height: 23px;
    line-height: 22px;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.page-navigation li.first a:hover,
.page-navigation li.last a:hover {
    border-color: #e1bd85
}

.page-navigation li.current-page {
    font-weight: bold;
    font-size: 20px
}

.blog-content .widget_search {
    margin-top: 40px
}

.blog-content.events-content .post .entry-footer {
    margin-top: 15px
}

.blog-content .post {
    margin-top: 45px
}

.blog-content .page-navigation {
    margin-top: 60px
}

.blog-content #comments,
.blog-content .comment-respond {
    margin-left: 100px
}

#comments {
    margin-top: 40px;
    border-top: 1px solid #cacaca;
    padding-top: 15px
}

#comments .comment-title {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Montserrat'
}

#comments .commentlist {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

#comments .commentlist li {
    position: relative;
    padding-left: 90px;
    margin-top: 25px
}

#comments .commentlist li .comment-body {
    border-bottom: 1px solid #cacaca;
    padding-bottom: 20px;
    overflow: hidden
}

#comments .commentlist li .comment-body .comment-avatar {
    position: absolute;
    width: 70px;
    display: block;
    top: 5px;
    left: 0;
    border-radius: 50%;
    overflow: hidden
}

#comments .commentlist li .comment-body .comment-subject {
    margin: 0;
    font-size: 16px;
    color: #444444;
    font-weight: bold
}

#comments .commentlist li .comment-body p {
    color: #444444;
    font-size: 15px;
    line-height: 1.428em;
    margin-bottom: 0;
    margin-top: 10px
}

#comments .commentlist li .comment-body .comment-meta {
    float: left;
    margin-top: 20px;
    color: #898989
}

#comments .commentlist li .comment-body .comment-meta a {
    color: #444;
    font-weight: bold;
    text-transform: capitalize;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

#comments .commentlist li .comment-body .comment-meta a:hover {
    color: #e1bd85
}

#comments .commentlist li .comment-body .action {
    float: right;
    margin-top: 15px;
    font-size: 0
}

#comments .commentlist li .comment-body .action .awe-btn {
    padding: 3px 15px;
    min-width: inherit;
    margin-left: 10px
}

#comments .commentlist li .comment-body .children {
    overflow: hidden;
    clear: both;
    list-style: none;
    padding-left: 0
}

#comments .commentlist li .comment-body .children li:last-child .comment-body {
    padding-bottom: 0;
    border-width: 0
}

#comments .commentlist li:last-child .comment-body {
    border-bottom-width: 0;
    padding-bottom: 0
}

.comment-respond {
    border-top: 1px solid #cacaca;
    margin-top: 50px;
    padding-top: 20px
}

.comment-respond .comment-reply-title {
    text-transform: uppercase;
    color: #000;
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    font-family: 'Montserrat'
}

.comment-respond .comment-form {
    margin-top: 10px
}

.comment-respond .comment-form .row {
    margin-left: -10px;
    margin-right: -10px
}

.comment-respond .comment-form .row [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px
}

.comment-respond .comment-form .field-text,
.comment-respond .comment-form .field-textarea {
    width: 100%;
    color: #232323;
    border: 2px solid #232323;
    padding: 0 15px;
    margin-top: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.comment-respond .comment-form .field-text::-webkit-input-placeholder,
.comment-respond .comment-form .field-textarea::-webkit-input-placeholder {
    color: #232323
}

.comment-respond .comment-form .field-text:-moz-placeholder,
.comment-respond .comment-form .field-textarea:-moz-placeholder {
    color: #232323
}

.comment-respond .comment-form .field-text::-moz-placeholder,
.comment-respond .comment-form .field-textarea::-moz-placeholder {
    color: #232323
}

.comment-respond .comment-form .field-text:-ms-input-placeholder,
.comment-respond .comment-form .field-textarea:-ms-input-placeholder {
    color: #232323
}

.comment-respond .comment-form .field-text:focus,
.comment-respond .comment-form .field-textarea:focus {
    border-color: #e1bd85
}

.comment-respond .comment-form .field-textarea {
    resize: none;
    height: 200px;
    padding: 10px 15px;
    line-height: 1.428em
}

.comment-respond .comment-form .awe-btn {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px
}

.restaurant_item {
    overflow: hidden
}

.restaurant_item .img {
    float: left;
    width: 120px;
    margin-right: 30px;
    position: relative;
    overflow: hidden
}

.restaurant_item .img a {
    display: block;
    overflow: hidden;
    position: relative
}

.restaurant_item .img a:before,
.restaurant_item .img a:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    opacity: .8;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.restaurant_item .img a:before {
    border-right: 3px solid #e1bd85;
    border-bottom: 3px solid #e1bd85;
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right
}

.restaurant_item .img a:after {
    border-left: 3px solid #e1bd85;
    border-top: 3px solid #e1bd85;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left
}

.restaurant_item .img .sales {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f9b82c;
    color: #fff;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    padding: 8px
}

.restaurant_item .img img {
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.restaurant_item .text {
    padding-right: 60px;
    position: relative;
    overflow: hidden
}

.restaurant_item .text h2 {
    font-size: 18px;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.restaurant_item .text h2 a {
    color: #444444;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.restaurant_item .text h2 a:hover {
    color: #e1bd85
}

.restaurant_item .text .desc {
    color: #444;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 5px;
    line-height: 1.4em
}

.restaurant_item .text .price {
    text-align: right;
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    color: #e1bd85;
    line-height: 1.428em;
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    line-height: 1
}

.restaurant_item .text .price ins {
    text-decoration: none
}

.restaurant_item .text .price del {
    margin-top: 5px;
    color: #999999;
    display: block;
    clear: both;
    font-weight: normal
}

.restaurant_item.lager-thumbs {
    margin-left: -15px;
    margin-right: -15px;
    font-size: 0
}

.restaurant_item.lager-thumbs .img {
    font-size: 14px;
    width: 50%;
    float: none;
    margin: 0;
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
    vertical-align: middle
}

.restaurant_item.lager-thumbs .img .sales {
    left: 15px
}

.restaurant_item.lager-thumbs .text {
    font-size: 14px;
    width: -moz-calc(50% - 1px);
    width: -o-calc(50% - 1px);
    width: -webkit-calc(50% - 1px);
    width: calc(50% - 1px);
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
    vertical-align: middle
}

.restaurant_item.lager-thumbs .text .price {
    text-align: left;
    position: static;
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 15px;
    color: #e1bd85
}

.restaurant_item.lager-thumbs .text .price del {
    display: inline-block;
    font-weight: normal;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 0
}

.restaurant_item.small-thumbs .text {
    margin-top: 5px;
    padding-right: 70px
}

.restaurant_item.small-thumbs .text .price {
    right: 10px
}

.restaurant_item:hover .img a:before,
.restaurant_item:hover .img a:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.restaurant_item:hover .img img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1)
}

.section-restaurant-1 {
    padding-bottom: 85px;
    padding-top: 40px
}

.section-restaurant-2 {
    padding-bottom: 80px;
    padding-top: 40px
}

.section-restaurant-3 {
    padding-top: 45px;
    padding-bottom: 55px
}

.section-restaurant-4 {
    padding-top: 45px;
    padding-bottom: 85px
}

.restaurant_title .time {
    color: #898989;
    font-size: 18px;
    margin-top: 10px;
    display: block
}

.restaurant-lager {
    margin-top: -40px
}

.restaurant-lager .restaurant_content {
    margin-top: 40px
}

.restaurant-lager .restaurant_content .restaurant_item {
    margin-top: 30px
}

.restaurant-lager .restaurant_content .hr {
    margin-top: 45px
}

.restaurant-small {
    margin-top: -70px
}

.restaurant-small .restaurant_content {
    margin-top: 70px
}

.restaurant-small .restaurant_content .restaurant_title {
    padding-bottom: 10px
}

.restaurant-small .restaurant_content .restaurant_item {
    margin-top: 20px
}

.restaurant-tabs .restaurant_content {
    padding-top: 20px
}

.restaurant-tabs .restaurant_content .restaurant_item {
    margin-top: 30px
}

.tabs-restaurant .icon-restaurant {
    color: #232323;
    font-size: 40px;
    margin-bottom: 10px
}

.tabs-restaurant .ui-tabs-nav {
    display: table;
    width: 100%;
    border-bottom: 2px solid #e7e7e7
}

.tabs-restaurant .ui-tabs-nav li {
    display: table-cell;
    float: none;
    margin: 0 !important;
    text-align: center;
    padding-bottom: 15px;
    outline: none;
    position: relative
}

.tabs-restaurant .ui-tabs-nav li:before {
    position: absolute;
    content: '';
    left: 0;
    width: 0;
    bottom: -2px;
    border-top: 2px solid #e1bd85;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.tabs-restaurant .ui-tabs-nav li a {
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    display: block;
    font-size: 23px;
    float: none !important;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.tabs-restaurant .ui-tabs-nav li a span {
    display: block;
    clear: both;
    color: #898989;
    font-size: 16px;
    font-family: 'Hind';
    font-weight: normal;
    text-transform: none;
    margin-top: 5px
}

.tabs-restaurant .ui-tabs-nav li:hover a,
.tabs-restaurant .ui-tabs-nav li.ui-state-active a {
    color: #e1bd85
}

.tabs-restaurant .ui-tabs-nav li:hover:before,
.tabs-restaurant .ui-tabs-nav li.ui-state-active:before {
    width: 100%
}

.tabs-restaurant .ui-tabs-panel {
    margin-top: 0
}

.section-reservation {
    padding-top: 50px;
    padding-bottom: 50px
}

.reservation {
    position: relative;
    z-index: 9;
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 70px
}

.reservation:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: .9;
    top: 0;
    left: 0;
    z-index: -1
}

.reservation h2 {
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    margin: 0;
    line-height: 1;
    text-align: center
}

.reservation .reservation_form {
    margin-top: 20px
}

.reservation .reservation_form .awe-calendar-wrapper {
    width: 100%;
    margin-top: 20px
}

.reservation .reservation_form .awe-calendar-wrapper .awe-calendar {
    font-size: 14px;
    border-color: #fff;
    color: #fff
}

.reservation .reservation_form .awe-calendar-wrapper .awe-calendar::-webkit-input-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-calendar-wrapper .awe-calendar:-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-calendar-wrapper .awe-calendar::-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-calendar-wrapper .awe-calendar:-ms-input-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-calendar-wrapper i {
    color: #fff
}

.reservation .reservation_form .awe-input {
    margin-top: 20px;
    border: 2px solid #fff;
    background-color: transparent;
    width: 100%;
    color: #fff
}

.reservation .reservation_form .awe-input::-webkit-input-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-input:-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-input::-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-input:-ms-input-placeholder {
    color: #fff
}

.reservation .reservation_form .bootstrap-select.btn-group.awe-select {
    margin-top: 20px;
    width: 100%
}

.reservation .reservation_form .awe-teaxtarea {
    margin-top: 20px;
    background-color: transparent;
    width: 100%;
    border: 2px solid #fff;
    color: #fff;
    height: 135px
}

.reservation .reservation_form .awe-teaxtarea::-webkit-input-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-teaxtarea:-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-teaxtarea::-moz-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-teaxtarea:-ms-input-placeholder {
    color: #fff
}

.reservation .reservation_form .awe-btn {
    margin-top: 20px;
    font-size: 16px;
    min-width: 170px
}

.section-gallery-restaurant {
    padding-top: 75px;
    padding-bottom: 85px
}

.section-event-restaurant {
    padding-top: 70px;
    padding-bottom: 70px
}

.event-restaurant .event-restaurant_header {
    margin-top: 50px
}

.event-restaurant_header {
    color: #fff
}

.event-restaurant_header .icon {
    font-size: 50px
}

.event-restaurant_header h2 {
    text-transform: uppercase;
    font-size: 35px;
    color: #fff;
    font-weight: bold;
    line-height: 1
}

.event-restaurant_header p {
    margin-bottom: 0;
    font-size: 16px;
    margin-top: 20px
}

.event-restaurant_content {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.event-restaurant_content li {
    overflow: hidden;
    margin-top: 45px
}

.event-restaurant_content li:first-child {
    margin-top: 0
}

.event-restaurant_content li .event-date {
    width: 70px;
    padding: 8px 0;
    margin-right: 30px;
    float: left;
    border: 2px solid #fff;
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1
}

.event-restaurant_content li .event-date strong {
    display: block;
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    margin-bottom: 5px
}

.event-restaurant_content li .text {
    overflow: hidden
}

.event-restaurant_content li .text h2 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
    line-height: 1.4em;
    margin-top: -3px
}

.event-restaurant_content li .text a {
    color: #fff;
    display: inline-block;
    margin-top: 5px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.event-restaurant_content li .text a:hover {
    color: #e1bd85
}

.section-room {
    padding-top: 0px;
    padding-bottom: 40px
}

.room_item-1 {
    position: relative
}

.section-room-tumb {
    margin-top: 40px;
}

.section-room-tumb .room_item-1 .title {
    /*edit*/
    color: #D4A355;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 30px 0 30px;
}

.room_item-1 .title {
    /*edit*/
    color: #D4A355;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 30px 0 30px;
}


.room_item-1 h2 a {
    color: #D4A355;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-1 h2 a:hover {
    color: #5c5c5c;
}

.room_item-1 .img {
    position: relative;
}

.room_item-1 .img:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000));
    background: -webkit-linear-gradient(top, transparent, #000000);
    background: -moz-linear-gradient(top, transparent, #000000);
    background: -ms-linear-gradient(top, transparent, #000000);
    background: -o-linear-gradient(top, transparent, #000000);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
}

.item-experience .img:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000));
    background: -webkit-linear-gradient(top, transparent, #000000);
    background: -moz-linear-gradient(top, transparent, #000000);
    background: -ms-linear-gradient(top, transparent, #000000);
    background: -o-linear-gradient(top, transparent, #000000);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.room_item-1 .img img {
    width: 100%
}

.room_item-1 .desc {
    color: #333;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px
}

.room_item-1 .desc p {
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 14px
}

.room_item-1 .desc ul {
    font-size: 0;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px
}

.room_item-1 .desc ul li {
    position: relative;
    padding-left: 25px;
    font-size: 14px;
    width: calc(49%);
    display: inline-block;
    vertical-align: top;
    color: #232323;
    margin: 3px 0;
    font-weight: 500
}

.room_item-1 .desc ul li:before {
    width: 5px;
    height: 5px;
    background-color: #333333;
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.room_item-1 .bot {
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    overflow: hidden;
    padding: 10px 0;
    margin-top: 20px
}

.room_item-1 .bot .price {
    display: inline-block;
    color: #898989;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    line-height: 1
}

.room_item-1 .bot .price .amout {
    color: #333333;
    font-weight: bold;
    font-size: 26px;
    margin-left: 5px
}

.room_item-1 .bot .awe-btn {
    float: right
}

.room_item-1:hover .img:before {
    opacity: .5;
    top: 0
}

.item-experience:hover .img:before {
    opacity: .5;
    top: 0
}

.room_item-2 {
    font-size: 0;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px
}

.room_item-2.img-right .img {
    left: 33.3333%
}

.room_item-2.img-right .img:before {
    right: auto;
    left: 15px
}

.room_item-2.img-right .text {
    right: 66.6667%
}

.room_item-2 .img {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6667%
}

.room_item-2 .img:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    top: 40px;
    bottom: 40px;
    right: 15px;
    background-color: #fff;
    z-index: 2
}

.room_item-2 .img img {
    width: 100%
}

.room_item-2 .img:after {
    bottom: 0;
    left: 15px;
    right: 15px;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e1bd85));
    background: -webkit-linear-gradient(top, transparent, #e1bd85);
    background: -moz-linear-gradient(top, transparent, #e1bd85);
    background: -ms-linear-gradient(top, transparent, #e1bd85);
    background: -o-linear-gradient(top, transparent, #e1bd85);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.room_item-2 .text {
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: -moz-calc(33.3333% - 1px);
    width: -o-calc(33.3333% - 1px);
    width: -webkit-calc(33.3333% - 1px);
    width: calc(33.3333% - 1px)
}

.room_item-2 .text h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
    font-family: 'Montserrat'
}

.room_item-2 .text h2 a {
    color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-2 .text h2 a:hover {
    color: #e1bd85
}

.room_item-2 .text .price {
    display: block;
    color: #333333;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    margin-top: 7px
}

.room_item-2 .text p {
    color: #898989;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 30px
}

.room_item-2 .text p b {
    color: #333333
}

.room_item-2 .text .awe-btn {
    padding: 10px 20px;
    margin-top: 35px
}

.room_item-2:hover .img:after {
    opacity: .7;
    top: 0
}

.room_item-3.thumbs-right .text-thumbs:before {
    left: 30px
}

.room_item-3.thumbs-right .text-thumbs .thumbs {
    float: right;
    margin-left: 70px;
    margin-right: 0
}

.room_item-3 .img {
    position: relative
}

.room_item-3 .img img {
    width: 100%
}

.room_item-3 .img:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e1bd85));
    background: -webkit-linear-gradient(top, transparent, #e1bd85);
    background: -moz-linear-gradient(top, transparent, #e1bd85);
    background: -ms-linear-gradient(top, transparent, #e1bd85);
    background: -o-linear-gradient(top, transparent, #e1bd85);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.room_item-3 .text-thumbs {
    margin-left: 70px;
    margin-right: 70px;
    position: relative
}

.room_item-3 .text-thumbs:after {
    display: table;
    content: '';
    clear: both
}

.room_item-3 .text-thumbs:before {
    display: block;
    content: '';
    height: 2px;
    width: 70px;
    background-color: #333333;
    position: absolute;
    left: 330px;
    bottom: 0
}

.room_item-3 .text-thumbs .thumbs {
    width: 230px;
    float: left;
    margin-right: 70px;
    margin-top: 60px;
    position: relative
}

.room_item-3 .text-thumbs .thumbs img {
    width: 100%
}

.room_item-3 .text-thumbs .thumbs:before,
.room_item-3 .text-thumbs .thumbs:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.room_item-3 .text-thumbs .thumbs:before {
    border-right: 4px solid #e1bd85;
    border-bottom: 4px solid #e1bd85;
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right
}

.room_item-3 .text-thumbs .thumbs:after {
    border-left: 4px solid #e1bd85;
    border-top: 4px solid #e1bd85;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left
}

.room_item-3 .text-thumbs .text {
    overflow: hidden;
    padding: 20px 30px 0 30px;
    background-color: #fff;
    position: relative;
    -webkit-transform: translateY(-60px);
    -moz-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -o-transform: translateY(-60px)
}

.room_item-3 .text-thumbs .text h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
    font-family: 'Montserrat'
}

.room_item-3 .text-thumbs .text h2 a {
    color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-3 .text-thumbs .text h2 a:hover {
    color: #e1bd85
}

.room_item-3 .text-thumbs .text .price {
    display: block;
    color: #333333;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    margin-top: 7px
}

.room_item-3 .text-thumbs .text p {
    color: #898989;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 30px
}

.room_item-3 .text-thumbs .text p b {
    color: #333333
}

.room_item-3 .text-thumbs .text .awe-btn {
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 35px
}

.room_item-3:hover .img:before {
    opacity: .7;
    top: 0
}

.room_item-3:hover .text-thumbs .thumbs:before,
.room_item-3:hover .text-thumbs .thumbs:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.room_item-4 {
    margin-left: -15px;
    margin-right: -15px
}

.room_item-4:after {
    content: '';
    display: table;
    clear: both
}

.room_item-4 .img {
    float: left;
    position: relative;
    width: 41.66666667%;
    padding-left: 15px;
    padding-right: 15px
}

.room_item-4 .img img {
    width: 100%
}

.room_item-4 .img:before {
    bottom: 0;
    left: 15px;
    right: 15px;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e1bd85));
    background: -webkit-linear-gradient(top, transparent, #e1bd85);
    background: -moz-linear-gradient(top, transparent, #e1bd85);
    background: -ms-linear-gradient(top, transparent, #e1bd85);
    background: -o-linear-gradient(top, transparent, #e1bd85);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.room_item-4 .text {
    overflow: hidden;
    position: relative
}

.room_item-4 .text h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
    font-family: 'Montserrat'
}

.room_item-4 .text h2 a {
    color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-4 .text h2 a:hover {
    color: #e1bd85
}

.room_item-4 .text .price {
    display: block;
    color: #333333;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    margin-top: 7px
}

.room_item-4 .text p {
    color: #898989;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 30px
}

.room_item-4 .text p b {
    color: #333333
}

.room_item-4 .text .awe-btn {
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 25px
}

.room_item-4 .thumbs {
    position: relative
}

.room_item-4 .thumbs img {
    width: 100%
}

.room_item-4 .thumbs:before,
.room_item-4 .thumbs:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.room_item-4 .thumbs:before {
    border-right: 3px solid #e1bd85;
    border-bottom: 3px solid #e1bd85;
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right
}

.room_item-4 .thumbs:after {
    border-left: 3px solid #e1bd85;
    border-top: 3px solid #e1bd85;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left
}

.room_item-4 .text-thumbs {
    position: relative;
    float: left;
    width: 58.33333333%;
    padding-left: 15px;
    padding-right: 15px
}

.room_item-4 .text-thumbs .text {
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 45px
}

.room_item-4 .text-thumbs .thumbs {
    margin-left: -30px;
    margin-top: 80px
}

.room_item-4.img-right .img {
    left: 58.33333333%
}

.room_item-4.img-right .text-thumbs {
    right: 41.66666667%
}

.room_item-4.img-right .text-thumbs .thumbs {
    margin-left: 0;
    margin-right: -30px
}

.room_item-4:hover .img:before {
    opacity: .7;
    top: 0
}

.room_item-4:hover .thumbs:before,
.room_item-4:hover .thumbs:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.room_item-5 {
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden
}

.room_item-5:before {
    position: absolute;
    pointer-events: none;
    content: '';
    z-index: 0;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(21, 21, 21, 0);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(21, 21, 21, 0)), to(rgba(21, 21, 21, 0.7)));
    background: -webkit-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.7));
    background: -moz-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.7));
    background: -ms-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.7));
    background: -o-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.7))
}

.room_item-5 .img {
    display: none;
    position: relative
}

.room_item-5 .img img {
    width: 100%
}

.room_item-5 .img:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e1bd85));
    background: -webkit-linear-gradient(top, transparent, #e1bd85);
    background: -moz-linear-gradient(top, transparent, #e1bd85);
    background: -ms-linear-gradient(top, transparent, #e1bd85);
    background: -o-linear-gradient(top, transparent, #e1bd85);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.room_item-5 .room_item-forward {
    position: absolute;
    bottom: 30px;
    left: 45px;
    right: 45px;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.room_item-5 .room_item-forward h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Montserrat'
}

.room_item-5 .room_item-forward h2 a {
    color: #fff
}

.room_item-5 .room_item-forward .price {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Montserrat'
}

.room_item-5 .room_item-back {
    position: relative;
    z-index: 3;
    padding: 30px 50px;
    min-height: 325px;
    border: 2px solid #393736;
    background-color: rgba(255, 255, 255, 0.95);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.room_item-5 .room_item-back h3 {
    color: #333;
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    line-height: 1
}

.room_item-5 .room_item-back .price {
    font-family: 'Work Sans', sans-serif;
    color: #333;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 10px;
    display: block
}

.room_item-5 .room_item-back p {
    color: #898989;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 30px
}

.room_item-5 .room_item-back p b {
    color: #333
}

.room_item-5 .room_item-back .awe-btn {
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 25px
}

.room_item-5:hover .room_item-back {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.room_item-5:hover .img:before {
    opacity: .7;
    top: 0
}

.room_item-6 {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    overflow: hidden
}

.room_item-6:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    opacity: 0;
    width: -moz-calc(100% - 470px);
    width: -o-calc(100% - 470px);
    width: -webkit-calc(100% - 470px);
    width: calc(100% - 470px);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-6.event:before {
    right: auto;
    left: 0
}

.room_item-6.event .text {
    float: right
}

.room_item-6 .text {
    max-width: 470px;
    padding: 25px 30px 30px 30px;
    position: relative;
    z-index: 9
}

.room_item-6 .text:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: .8;
    top: 0;
    left: 0;
    z-index: -1
}

.room_item-6 .text h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 32px;
    font-family: 'Montserrat'
}

.room_item-6 .text h2 a {
    color: #fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room_item-6 .text h2 a:hover {
    color: #e1bd85
}

.room_item-6 .text .price {
    display: block;
    color: #e1bd85;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    margin-top: 7px
}

.room_item-6 .text p {
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 25px
}

.room_item-6 .text ul {
    padding-left: 0;
    color: #fff;
    margin-bottom: 0;
    padding-top: 10px;
    list-style: none
}

.room_item-6 .text ul li {
    position: relative;
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px
}

.room_item-6 .text ul li:before {
    width: 5px;
    height: 5px;
    background-color: #fff;
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.room_item-6 .text .awe-btn {
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 20px
}

.room_item-6:hover:before {
    opacity: 1
}

.room-wrap-1 {
    margin-top: -45px
}

.room-wrap-1 .room_item-1 {
    margin-top: 45px
}

.room-wrap-2 {
    margin-top: -100px
}

.room-wrap-2 .room_item-2 {
    margin-top: 100px
}

.room-wrap-3 {
    margin-top: -100px
}

.room-wrap-3 .room_item-3 {
    margin-top: 100px
}

.room-wrap-4 {
    margin-top: -100px
}

.room-wrap-4 .room_item-4 {
    margin-top: 100px
}

.room-wrap-5 {
    margin-top: -30px
}

.room-wrap-5 .room_item-5 {
    margin-top: 30px
}

.room-wrap-6 {
    margin-top: -30px
}

.room-wrap-6 .room_item-6 {
    margin-top: 30px
}

.section-room-detail {
    /* Edit */
    padding-top: 40px;
    max-width: 1275px;
    margin: 0 auto;
}

.room-detail_img {
    position: relative;
    margin-top: 45px
}

.room-detail_img .room_img-item {
    position: relative
}

.room-detail_img .room_img-item h6 {
    background-color: rgba(51, 51, 51, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 13px;
    color: #fff;
    padding: 15px 20px
}

.room-detail_img .room_img-item img {
    width: 100%
}

.room-detail_img .apb-zoom {
    position: absolute;
    bottom: 65px;
    right: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room-detail_img .apb-zoom:hover {
    color: #e1bd85
}

.room-detail_thumbs {
    margin-top: 10px
}

.room-detail_thumbs .owl-item {
    padding: 0 65px;
    margin-top: 20px;
}

.room-detail_thumbs .owl-item.active a:before {
    border-color: #e1bd85
}

.room-detail_thumbs a {
    position: relative
}

.room-detail_thumbs a:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    pointer-events: none;
    z-index: 1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room-detail_thumbs a:hover::before {
    opacity: .5;
    top: 0;
    /*background: -moz-linear-gradient(top, transparent, #000000);*/
}

.room-detail_thumbs p {
    margin-bottom: 25px;
}

.room-detail_thumbs p a:link {
    color: #A3A3A3;
}

.room-detail_thumbs p a:visited {
    color: #A3A3A3;
}

.room-detail_thumbs p a:visited {
    color: #A3A3A3;
}

.room-detail_thumbs a img {
    width: 100%
}

.room-detail_thumbs .owl-prev,
.room-detail_thumbs .owl-next {
    width: 0px;
    color: #fff;
    font-size: 30px;
    text-align: center;
    border-width: 0;
    height: auto;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    width: 36px;
    height: 36px;
    background: rgba(129, 129, 129, 0.3);
    border-radius: 100%;
    box-shadow: 0px 3px 5px #b5b5b5;
}

.room-detail_thumbs .owl-prev:hover,
.room-detail_thumbs .owl-next:hover {

    color: #dedede
}

.room-detail_thumbs .owl-prev .fa,
.room-detail_thumbs .owl-next .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.room-detail_thumbs .owl-prev {
    left: 0
}

.room-detail_thumbs .owl-next {
    right: 0
}

.room-detail_book {
    background-color: #f1f1f1;
    margin-top: 45px
}

.room-detail_book .room-detail_total {
    padding: 20px 30px;
    text-align: center
}

.room-detail_book .room-detail_total h6 {
    color: #232323;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px
}

.room-detail_book .room-detail_total .price {
    margin-bottom: 0;
    margin-top: 10px;
    color: #898989;
    font-size: 18px;
    line-height: 1;
    font-family: 'Montserrat'
}

.room-detail_book .room-detail_total .price .amout {
    font-weight: bold;
    font-size: 40px;
    color: #232323
}

.room-detail_book .room-detail_form {
    border-top: 1px solid #e4e4e4;
    padding: 0 30px 33px 30px
}

.room-detail_book .room-detail_form label {
    display: block;
    clear: both;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 10px
}

.room-detail_book .room-detail_form .awe-calendar-wrapper {
    width: 100%
}

.room-detail_book .room-detail_form .awe-calendar-wrapper .awe-calendar {
    border-width: 0;
    background-color: #fff;
    line-height: 30px;
    height: 30px;
    color: #898989;
    font-weight: 500
}

.room-detail_book .room-detail_form .bootstrap-select.btn-group.awe-select {
    width: 100%
}

.room-detail_book .room-detail_form .bootstrap-select.btn-group.awe-select .dropdown-toggle {
    background-color: #fff;
    height: 30px;
    border-width: 0;
    line-height: 30px
}

.room-detail_book .room-detail_form .bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option,
.room-detail_book .room-detail_form .bootstrap-select.btn-group.awe-select .dropdown-toggle .caret {
    color: #333
}

.room-detail_book .room-detail_form .bootstrap-select.btn-group.awe-select .dropdown-menu ul li a {
    padding: 7px 10px
}

.room-detail_book .room-detail_form .awe-btn {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    padding: 10px;
    margin-top: 30px
}

.room-detail_tab {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 0px solid #e4e4e4
}

.room-detail_tab-header {
    /*edit*/
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: -15px;
}

.room-detail_tab-header li {
    border-bottom: 2px solid #e4e4e4;
    position: relative
}

.room-detail_tab-header li a {
    padding: 15px 0 15px 0;
    color: #808080;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    position: relative;
    font-family: 'Work Sans', sans-serif;
    display: inline-block;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

.room-detail_tab-header li a:before {
    height: 2px;
    content: '';
    width: 0;
    position: absolute;
    left: 0;
    bottom: -2px;
    background-color: #e1bd85;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room-detail_tab-header li.active a,
.room-detail_tab-header li:hover a {
    color: #e1bd85
}

.room-detail_tab-header li.active a:before,
.room-detail_tab-header li:hover a:before {
    width: 100%
}

.room-detail_tab-content {
    padding-top: 5px;
    position: relative;
    padding-bottom: 5px;
}

.room-detail_tab-content:before {
    /*edit */
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0
}

.room-detail_amenities,
.room-detail_overview,
.room-detail_layout,
.room-detail-compare {
    color: #5c5c5c;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.42857143;
    letter-spacing: 0.055em;

}

.room-detail_amenities h5,
.room-detail_overview h5 {
    font-family: 'Hind';
    font-weight: bold
}

.room-detail_amenities p,
.room-detail_overview p {
    font-size: 18px;
    line-height: 1.42857143;
    letter-spacing: 0.055em;
    margin-bottom: 0
}

.room-detail_amenities h6,
.room-detail_overview h6 {
    text-transform: uppercase;
    color: #232323;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Hind';
    margin-top: 25px;
    line-height: 1.7em;
}

.room-detail_amenities ul,
.room-detail_overview ul {
    padding-left: 17px;
    margin-bottom: 0;
    margin-top: 5px
}

.room-detail_overview table {
    width: 100%;
}

.room-detail_overview td {
    vertical-align: top;
}

.room-detail_amenities ul li,
.room-detail_overview ul li {
    padding: 0px 0
}

.room-detail_package .room-package_item {
    padding: 25px 0;
    border-bottom: 1px solid #e4e4e4
}

.room-detail_package .room-package_item:first-child {
    padding-top: 0
}

.room-detail_package .room-package_item:last-child {
    border-bottom: 0
}

.room-package_item {
    overflow: hidden
}

.room-package_item .img {
    float: left;
    width: 100px;
    margin-right: 30px
}

.room-package_item .img img {
    width: 100%
}

.room-package_item .text {
    overflow: hidden;
    padding-right: 170px;
    position: relative;
    min-height: 70px
}

.room-package_item .text h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    font-family: 'Montserrat'
}

.room-package_item .text h4 a {
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room-package_item .text h4 a:hover {
    color: #e1bd85
}

.room-package_item .text p {
    color: #898989;
    font-size: 13px;
    line-height: 1.428em;
    margin-bottom: 0;
    margin-top: 10px
}

.room-package_item .text p b {
    color: #232323
}

.room-package_item .text .room-package_price {
    position: absolute;
    right: 0;
    top: 0
}

.room-package_item .text .room-package_price .price {
    color: #898989;
    font-size: 12px;
    margin: 0;
    text-align: right
}

.room-package_item .text .room-package_price .price .amout {
    font-weight: bold;
    font-size: 20px;
    color: #232323
}

.room-package_item .text .room-package_price .awe-btn {
    margin-top: 10px;
    font-size: 10px;
    font-weight: bold
}

.room-detail_rates {
    padding-bottom: 20px
}

.room-detail_rates table {
    width: 100%
}

.room-detail_rates table th {
    color: #232323;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #f1f1f1;
    padding: 15px 20px
}

.room-detail_rates table td {
    padding: 15px 20px;
    border-bottom: 1px solid #e4e4e4;
    vertical-align: top
}

.room-detail_rates table td h6 {
    color: #232323;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Hind';
    font-weight: bold
}

.room-detail_rates table td ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 10px
}

.room-detail_rates table td ul li {
    padding: 2px 0;
    color: #898989
}

.room-detail_rates table td .price {
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    margin-bottom: 0
}

.calendar_custom {
    max-width: 390px
}

.calendar_custom .calendar_title {
    text-align: center;
    color: #333;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    margin-left: 3px;
    margin-right: 3px
}

.calendar_custom .calendar_title .calendar_corner {
    display: block;
    width: 24px;
    height: 24px;
    text-align: center;
    color: #cbcbcb;
    line-height: 24px;
    font-size: 12px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.calendar_custom .calendar_title .calendar_corner:hover {
    color: #e1bd85;
    border-color: #e1bd85
}

.calendar_custom .calendar_title .calendar_corner.calendar_prev {
    left: 0
}

.calendar_custom .calendar_title .calendar_corner.calendar_next {
    right: 0
}

.calendar_custom .calendar_tabel {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    table-layout: fixed
}

.calendar_custom .calendar_tabel th {
    text-transform: uppercase;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold
}

.calendar_custom .calendar_tabel td {
    background-color: #fff;
    background-clip: content-box;
    padding: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.calendar_custom .calendar_tabel td a {
    position: relative;
    display: block;
    padding-top: 100%;
    color: #232323;
    font-size: 12px
}

.calendar_custom .calendar_tabel td a:before {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    border: 1px solid #333;
    position: absolute
}

.calendar_custom .calendar_tabel td a small {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 12px
}

.calendar_custom .calendar_tabel td a span {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.calendar_custom .calendar_tabel td.not-available {
    background-color: #333
}

.calendar_custom .calendar_tabel td.not-available a {
    color: #fff
}

.room-detail_calendar-wrap .calendar_status {
    margin-top: 30px
}

.room-detail_calendar-wrap .calendar_status span {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    padding: 0 10px
}

.room-detail_calendar-wrap .calendar_status span:before {
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    display: inline-block;
    vertical-align: middle;
    content: '';
    margin-right: 10px
}

.room-detail_calendar-wrap .calendar_status span.not-available:before {
    background-color: #333
}

.room-detail_compare {
    margin-top: 20px;
    padding-top: 50px;
    border-top: 1px solid #e4e4e4
}

.room-detail_compare .room-compare_title {
    font-weight: bold;
    margin: 0;
    font-size: 22px;
    line-height: 1;
    color: #232323
}

.room-detail_compare .room-compare_item {
    margin-top: 30px
}

.room-compare_item {
    position: relative
}

.room-compare_item .img {
    position: relative
}

.room-compare_item .img img {
    width: 100%
}

.room-compare_item .text {
    margin-top: 20px
}

.room-compare_item .text h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase
}

.room-compare_item .text h2 a {
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.room-compare_item .text h2 a:hover {
    color: #e1bd85
}

.room-compare_item .text ul {
    color: #898989;
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 0;
    color: #333333;
    padding-left: 0;
    list-style: none
}

.room-compare_item .text ul li {
    padding: 4px 0;
    font-weight: 500
}

.room-compare_item .text ul li i {
    font-size: 12px;
    width: 25px;
    display: inline-block
}

.room-compare_item .text .awe-btn {
    font-size: 10px;
    margin-top: 20px;
    font-weight: bold
}

.section-activiti {
    padding-top: 45px;
    padding-bottom: 75px
}

.activiti_content .activiti_item {
    margin-top: 40px
}

.activiti_item {
    position: relative
}

.activiti_item .img {
    position: relative
}

.activiti_item .img img {
    width: 100%
}

.activiti_item .text {
    margin-top: 20px
}

.activiti_item .text h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold
}

.activiti_item .text h2 a {
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.activiti_item .text h2 a:hover {
    color: #e1bd85
}

.activiti_item .text p {
    color: #232323;
    font-size: 14px;
    line-height: 1.5em;
    margin-top: 5px
}

.activiti_item .text .view-more {
    color: #232323;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.activiti_item .text .view-more i {
    margin-left: 10px;
    font-size: 9px
}

.activiti_item .text .view-more:hover {
    color: #e1bd85
}

.activiti_item:hover .img:before {
    opacity: .7;
    top: 0
}

.section-term-condition {
    padding-top: 40px;
    padding-bottom: 80px
}

.term-condition {
    color: #333;
    font-family: 500;
    font-size: 15px
}

.term-condition p {
    margin-bottom: 0;
    font-weight: 500
}

.term-condition h1,
.term-condition h2,
.term-condition h3,
.term-condition h4,
.term-condition h5,
.term-condition h6 {
    font-weight: bold
}

.term-condition ul {
    padding-left: 20px
}

.term-condition ul li {
    padding: 5px 0;
    color: #333;
    font-weight: 500
}

.section-checkout {
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: #ffffff
}

.checkout .checkout_login {
    color: #898989;
    margin-bottom: 0
}

.checkout .checkout_login a {
    color: #232323;
    font-weight: bold;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.checkout .checkout_login a:hover {
    color: #e1bd85
}

.checkout .checkout_head {
    margin-top: 30px
}

.checkout .checkout_head h3 {
    color: #000;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase
}

.checkout .checkout_head span {
    color: #000;
    font-size: 16px;
    margin-top: 10px;
    display: block
}

.checkout label {
    font-weight: 500;
    color: #232323;
    display: block;
    clear: both
}

.checkout .bootstrap-select.btn-group.awe-select {
    width: 100%
}

.checkout .bootstrap-select.btn-group.awe-select .dropdown-toggle {
    border-color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.checkout .bootstrap-select.btn-group.awe-select .dropdown-toggle:hover {
    border-color: #e1bd85
}

.checkout .bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option {
    color: #232323
}

.checkout .checkout_form [class*="col-"] {
    margin-top: 20px
}

.checkout .field-text {
    width: 100%;
    border: 2px solid #232323;
    background-color: transparent;
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.checkout .field-text::-webkit-input-placeholder {
    color: #232323
}

.checkout .field-text:-moz-placeholder {
    color: #232323
}

.checkout .field-text::-moz-placeholder {
    color: #232323
}

.checkout .field-text:-ms-input-placeholder {
    color: #232323
}

.checkout .field-text:focus {
    border-color: #e1bd85
}

.checkout .field-textarea {
    border: 2px solid #232323;
    background-color: transparent;
    width: 100%;
    height: 100px;
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.checkout .field-textarea::-webkit-input-placeholder {
    color: #232323
}

.checkout .field-textarea:-moz-placeholder {
    color: #232323
}

.checkout .field-textarea::-moz-placeholder {
    color: #232323
}

.checkout .field-textarea:-ms-input-placeholder {
    color: #232323
}

.checkout .field-textarea:focus {
    border-color: #e1bd85
}

.checkout .field-radio {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 10px
}

.checkout .code-enter {
    border: 2px solid #232323;
    padding: 12px 20px;
    color: #898989;
    margin-bottom: 0
}

.checkout .code-enter a {
    font-weight: bold;
    color: #232323;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.checkout .code-enter a:hover {
    color: #e1bd85
}

.checkout .checkout_text {
    margin-bottom: 0;
    margin-top: 10px;
    color: #232323;
    line-height: 1.4em
}

.checkout .checkout_cart {
    border: 2px solid #232323;
    padding: 15px 30px 30px 30px;
    margin-top: 17px
}

.checkout .checkout_cart .cart-item {
    overflow: hidden;
    margin-top: 20px;
    position: relative
}

.checkout .checkout_cart .cart-item .img {
    width: 100px;
    float: left;
    position: relative;
    margin-right: 20px
}

.checkout .checkout_cart .cart-item .img img {
    width: 100%
}

.checkout .checkout_cart .cart-item .img:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    content: '';
    opacity: 0;
    z-index: 10;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e1bd85));
    background: -webkit-linear-gradient(top, transparent, #e1bd85);
    background: -moz-linear-gradient(top, transparent, #e1bd85);
    background: -ms-linear-gradient(top, transparent, #e1bd85);
    background: -o-linear-gradient(top, transparent, #e1bd85);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.checkout .checkout_cart .cart-item .text {
    margin-right: 15px;
    overflow: hidden
}

.checkout .checkout_cart .cart-item .text a {
    color: #232323;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.checkout .checkout_cart .cart-item .text a:hover {
    color: #e1bd85
}

.checkout .checkout_cart .cart-item .text p {
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    color: #232323;
    font-size: 14px
}

.checkout .checkout_cart .cart-item .text p span {
    display: block
}

.checkout .checkout_cart .cart-item .text p b {
    font-size: 16px
}

.checkout .checkout_cart .cart-item .remove {
    position: absolute;
    top: 0;
    right: 0;
    color: #a7a7a7;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.checkout .checkout_cart .cart-item .remove:hover {
    color: #e1bd85
}

.checkout .checkout_cart .cart-item:hover .img:before {
    opacity: .5;
    top: 0
}

.checkout .checkout_cartinfo {
    margin-top: 35px
}

.checkout .checkout_cartinfo p {
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    color: #000
}

.checkout .checkout_cartinfo p:first-child {
    margin-top: 0
}

.checkout .checkout_cartinfo p span {
    display: inline-block;
    min-width: 180px
}

.checkout .checkout_option {
    margin-top: 30px
}

.checkout .checkout_option ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.checkout .checkout_option ul li {
    padding-left: 30px;
    position: relative;
    margin-top: 20px
}

.checkout .checkout_option ul li:first-child {
    margin-top: 0
}

.checkout .checkout_option ul li .radio {
    position: absolute;
    left: 0;
    top: 1px
}

.checkout .checkout_option ul li h6 {
    margin: 0;
    color: #232323;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle
}

.checkout .checkout_option ul li p {
    margin-bottom: 0;
    line-height: 1.4em;
    font-size: 12px;
    margin-top: 10px;
    color: #232323
}

.checkout .checkout_option ul li img {
    max-width: 100%;
    margin-left: 10px
}

.checkout .checkout_btn {
    margin-top: 30px
}

.checkout .checkout_btn .awe-btn {
    font-size: 13px;
    padding: 10px 30px
}

.checkout .checkout_margin {
    margin-left: 70px
}

.section-attractions {
    padding-bottom: 100px
}

#attraction-maps {
    position: relative;
    height: 470px
}

.info-location-map {
    text-align: center
}

.info-location-map h2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
    font-weight: bold;
    margin-bottom: 5px
}

.info-location-map span {
    font-size: 12px;
    color: #898989
}

.attraction {
    padding-top: 60px;
    margin-top: -60px
}

.attraction_sidebar {
    background-color: #f1f1f1;
    margin-top: 60px
}

.attraction_sidebar .attraction_heading {
    color: #333333;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 20px;
    text-align: center;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.attraction_sidebar .attraction_heading .attraction-icon-drop {
    display: none;
    float: right;
    position: absolute;
    right: 20px;
    font-size: 20px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.attraction_sidebar .attraction_sidebar-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px
}

.attraction_sidebar .attraction_sidebar-content .attraction_title {
    background-color: #e1bd85;
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 15px;
    margin-top: 30px
}

.attraction_sidebar .attraction_sidebar-content .attraction_title i {
    margin-right: 15px
}

.attraction_sidebar .attraction_sidebar-content .bootstrap-select.attraction_select.awe-select {
    width: 100%;
    margin-top: 30px
}

.attraction_sidebar .attraction_sidebar-content .bootstrap-select.attraction_select.awe-select .btn {
    border-color: #f1f1f1;
    background-color: #fff
}

.attraction_sidebar .attraction_sidebar-content .bootstrap-select.attraction_select.awe-select .btn .filter-option,
.attraction_sidebar .attraction_sidebar-content .bootstrap-select.attraction_select.awe-select .btn .caret {
    color: #898989
}

.attraction_sidebar .attraction_sidebar-content .attraction_location {
    list-style: none;
    padding-left: 0;
    margin-top: 30px;
    margin-bottom: 0
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li {
    color: #898989;
    font-weight: 500;
    position: relative;
    padding-left: 25px;
    margin-top: 18px
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li:first-child {
    margin-top: 0
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li i {
    color: #333333;
    position: absolute;
    left: 0
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li a {
    color: #898989;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li a:hover {
    color: #e1bd85
}

.attraction_sidebar .attraction_sidebar-content .attraction_location li.active a {
    color: #e1bd85
}

.attraction_detail {
    margin-top: 60px
}

.attraction_detail .attraction_detail-title {
    color: #333333;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px
}

.attraction_detail .attraction_detail-title i {
    color: #e1bd85;
    margin-right: 20px
}

.attraction_detail ul {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4em;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px
}

.attraction_detail ul li {
    margin-top: 10px
}

.attraction_detail ul li span {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 20px
}

.attraction_detail ul li a {
    color: #e1bd85
}

.attraction_content {
    border-top: 1px solid #e4e4e4;
    padding-top: 30px;
    color: #898989;
    font-size: 14px;
    margin-top: 35px
}

.attraction_content .attraction_content-title {
    text-transform: uppercase;
    color: #333;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold
}

.attraction_content p {
    margin-bottom: 0;
    line-height: 1.4em;
    font-size: 14px
}

.attraction_content p b {
    color: #333
}

.attraction_content p a {
    color: #e1bd85;
    text-decoration: underline;
    font-weight: bold
}

.attraction_content p a:hover {
    color: #e1bd85
}

.attraction_content .wp_caption {
    max-width: 100%
}

.attraction_content .wp_caption.aligncenter {
    text-align: center
}

.attraction_content .wp_caption.alignright {
    float: right;
    margin-left: 30px
}

.attraction_content .wp_caption.alignleft {
    float: left;
    margin-right: 30px
}

.section-about {
    background-color: #fff;
    padding-bottom: 75px;
    padding-top: 0px
}

.section-about .about {
    margin-top: 0px
}

.section-about .about .about-item {
    margin-top: 40px
}

.about-item {
    overflow: hidden
}

.about-item.about-right .img {
    float: right
}

.about-item.about-right .text {
    float: right;
    padding-left: 0;
    padding-right: 40px
}

.about-item .img {
    float: left;
    width: 50%;
    position: relative
}

.about-item .img img {
    width: 100%
}

.about-item .img .owl-pagination {
    display: none
}

.about-item .text {
    width: 50%;
    float: left;
    padding-left: 40px;
    margin-top: 60px
}

.about-item .text h2 {
    line-height: 1.2em
}

.about-item .text .desc {
    margin-top: 20px;
    color: #333333;
    font-size: 13px
}

.about-item .text .desc p {
    line-height: 1.428em
}

.about-item:hover .img:before {
    opacity: .9
}

.section-statistics {
    padding: 75px 0
}

.section-statistics .awe-overlay {
    background-color: rgba(72, 72, 72, 0.6)
}

.statistics_item {
    margin-top: 30px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    position: relative
}

.statistics_item:before {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    content: '';
    border-right: 1px solid #fff
}

.statistics_item:first-child::before {
    content: none
}

.statistics_item .count {
    font-size: 60px;
    font-family: 'Work Sans', sans-serif;
    color: #fff;
    display: block;
    line-height: 1.3em
}

.section-team {
    background-color: #fff;
    padding: 75px 0
}

.team_item {
    margin-top: 80px;
    position: relative
}

.team_item:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    margin: auto;
    width: 0;
    content: '';
    border-bottom: 1px solid #f1f1f1;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.team_item .img {
    max-width: 200px;
    overflow: hidden;
    margin: auto;
    position: relative;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0)
}

.team_item .img:before {
    content: '';
    z-index: 1;
    border: 5px solid transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease
}

.team_item .img img {
    width: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: transform .9s ease;
    -moz-transition: transform .9s ease;
    -ms-transition: transform .9s ease;
    -o-transition: transform .9s ease
}

.team_item .text {
    margin-top: 30px
}

.team_item .text h2 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase
}

.team_item .text span {
    color: #898989;
    font-size: 16px;
    display: block;
    margin-top: 7px
}

.team_item .text p {
    margin-top: 20px;
    margin-bottom: 0;
    color: #30373b;
    line-height: 1.5em;
    font-size: 15px
}

.team_item .text .team-share {
    font-size: 0;
    margin-top: 20px
}

.team_item .text .team-share a {
    font-size: 12px;
    display: inline-block;
    border: 2px solid #898989;
    color: #898989;
    width: 24px;
    height: 24px;
    line-height: 22px;
    margin: 0 4px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.team_item .text .team-share a:hover {
    border-color: #e1bd85;
    color: #e1bd85
}

.team_item:hover:before {
    width: 100%
}

.team_item:hover .img:before {
    border-color: #e1bd85
}

.team_item:hover .img img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1)
}

.section-guest-book {
    background-color: #fff;
    padding: 45px 0
}

.guest-book .guest-book_head {
    background-size: cover;
    overflow: hidden
}

.guest-book .guest-book_head .text {
    background-color: #333;
    float: right;
    width: 400px;
    padding: 30px 45px 40px 45px
}

.guest-book .guest-book_head .text h2 {
    color: #fff;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 1.428em
}

.guest-book .guest-book_head .text p {
    color: #fff;
    font-size: 15px;
    line-height: 1.428em;
    margin-bottom: 0;
    margin-top: 15px
}

.guest-book .guest-book_head .text .awe-btn {
    font-size: 16px;
    padding: 7px 20px;
    margin-top: 25px
}

.guest-book .guest-book_head.guest-book_head-2 .text {
    background-color: transparent;
    max-width: 660px;
    width: auto;
    float: none;
    margin-left: auto;
    margin-right: auto
}

.guest-book .guest-book_head.guest-book_head-2 .text h2 {
    color: #232323;
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 2px
}

.guest-book .guest-book_head.guest-book_head-2 .text p {
    color: #898989
}

.guest-book .guest-book_content .guest-book_item {
    margin-top: 30px
}

.guest-book_item {
    text-align: center;
    padding: 25px 35px 25px 35px;
    border: 2px solid #232323;
    position: relative
}

.guest-book_item:before,
.guest-book_item:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.guest-book_item:before {
    border-right: 2px solid #e1bd85;
    border-bottom: 2px solid #e1bd85;
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right
}

.guest-book_item:after {
    border-left: 2px solid #e1bd85;
    border-top: 2px solid #e1bd85;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left
}

.guest-book_item.guest-book_item-2 {
    background-color: #f9f9f9;
    border-width: 0;
    margin-top: 20px
}

.guest-book_item.guest-book_item-2:before,
.guest-book_item.guest-book_item-2:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.guest-book_item.guest-book_item-2 h2 {
    margin-top: 20px
}

.guest-book_item .icon {
    color: #b4b4b4;
    font-size: 10px;
    margin-top: 0
}

.guest-book_item .avatar {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.guest-book_item .avatar:before {
    border: 3px solid #e1bd85;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-event: none;
    opacity: 0;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.guest-book_item h2 {
    margin-top: 10px;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    color: #444444;
    font-weight: bold
}

.guest-book_item p {
    color: #30373b;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 0
}

.guest-book_item span {
    margin-top: 20px;
    display: block;
    color: #898989;
    font-size: 16px
}

.guest-book_item span b {
    color: #444444
}

.guest-book_item:hover:before,
.guest-book_item:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.guest-book_item:hover .avatar:before {
    opacity: 1
}

.guest-book-form {
    background-color: #f1f1f1;
    max-width: 370px;
    padding: 35px
}

.guest-book-form h2 {
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.3em;
    text-align: center;
    margin-top: -5px
}

.guest-book-form p {
    margin-bottom: 0;
    color: #898989;
    text-align: center;
    line-height: 1.5em;
    margin-top: 20px
}

.guest-book-form .form-field {
    margin-top: 20px
}

.guest-book-form .field-text {
    width: 100%;
    border-width: 0;
    height: 38px;
    line-height: 38px;
    color: #898989
}

.guest-book-form .field-text::-webkit-input-placeholder {
    color: #898989
}

.guest-book-form .field-text:-moz-placeholder {
    color: #898989
}

.guest-book-form .field-text::-moz-placeholder {
    color: #898989
}

.guest-book-form .field-text:-ms-input-placeholder {
    color: #898989
}

.guest-book-form .field-textarea {
    height: 100px;
    width: 100%;
    border-width: 0;
    resize: none
}

.guest-book-form .awe-btn {
    width: 100%;
    padding: 12px 10px;
    margin-top: 10px
}

.section-comingsoon {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.comingsoon {
    position: absolute;
    padding: 15px;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.comingsoon h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 60px;
    font-family: 'Work Sans', sans-serif;
    line-height: 1;
    margin-top: 20px
}

.comingsoon h1 span {
    color: #e1bd85
}

.comingsoon h6 {
    color: #fff;
    font-size: 36px;
    margin-top: 10px;
    font-weight: normal;
    font-family: 'Hind'
}

.comingsoon p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
    margin-top: 25px
}

.comingsoon .countdown {
    border: 2px solid #fff;
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 20px
}

.comingsoon .countdown .item {
    width: 25%;
    float: left;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    position: relative
}

.comingsoon .countdown .item+.item:before {
    content: ':';
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 30px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%)
}

.comingsoon .countdown .item .count {
    display: block;
    font-family: 'Work Sans', sans-serif;
    font-size: 50px;
    line-height: 1
}

.comingsoon .widget_social .widget-title {
    margin-top: 25px;
    color: #fff;
    font-weight: normal;
    font-family: 'Hind';
    font-size: 20px
}

.comingsoon .widget_social .widget-social {
    text-align: center;
    font-size: 0
}

.comingsoon .widget_social .widget-social a {
    display: inline-block;
    border: 2px solid #fff;
    color: #fff;
    font-size: 16px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    margin: 15px 10px 0 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.comingsoon .widget_social .widget-social a:hover {
    border-color: #e1bd85
}

.section-account {
    padding-top: 220px;
    padding-bottom: 130px
}

.login-register {
    position: relative;
    z-index: 10
}

.login-register .text h2 {
    font-size: 36px;
    text-transform: uppercase;
    margin: 0;
    color: #5c5c5c;
}

.login-register .text p {
    color: #5c5c5c;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 5px
}

.login-register .text .account_form {
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    overflow: hidden
}

.login-register .text .account_form .field-form {
    position: relative;
    margin-top: 15px
}

.login-register .text .account_form .field-form .view-pass {
    position: absolute;
    right: 20px;
    top: 50%;
    color: #5c5c5c;
    line-height: 1;
    cursor: pointer;
    font-size: 11px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.login-register .text .account_form .field-form .view-pass:hover {
    color: #e1bd85
}

.login-register .text .account_form .field-form .field-text {
    background-color: transparent;
    border: 1px solid #5c5c5c;
    color: #5c5c5c;
    width: 100%;
    height: 42px;
    line-height: 42px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.login-register .text .account_form .field-form .field-text::-webkit-input-placeholder {
    color: #5c5c5c
}

.login-register .text .account_form .field-form .field-text:-moz-placeholder {
    color: #5C5C5C
}

.login-register .text .account_form .field-form .field-text::-moz-placeholder {
    color: #5c5c5c
}

.login-register .text .account_form .field-form .field-text:-ms-input-placeholder {
    color: #5c5c5c
}

.login-register .text .account_form .field-form .field-text:focus {
    border-color: #e1bd85
}

.login-register .text .account_form .field-form .awe-btn {
    min-width: 180px;
    font-size: 16px;
    padding: 5px 25px
}

.login-register .text .account_form .field-form.field-submit {
    margin-top: 30px
}

.login-register .text .account_form .account-desc {
    color: #5c5c5c;
    font-size: 13px;
    display: block;
}

.login-register .text .account_form .account-desc a {
    color: #5c5c5c;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.login-register .text .account_form .account-desc a:hover {
    color: #e1bd85
}

.section-404 {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.page-404 {
    position: absolute;
    max-width: 570px;
    width: 100%;
    padding: 15px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.page-404 h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 80px;
    font-family: 'Work Sans', sans-serif;
    line-height: 1;
    margin-top: 50px
}

.page-404 h1 span {
    color: #e1bd85
}

.page-404 h6 {
    color: #fff;
    font-size: 27px;
    margin-top: 10px;
    font-family: 'Hind'
}

.page-404 p {
    margin-bottom: 0;
    font-size: 18px;
    color: #fff;
    margin-top: 25px
}

.page-404 p:last-child {
    margin-top: 20px
}

.page-404 p a {
    color: #fff;
    font-weight: 700;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.page-404 p a:hover {
    color: #e1bd85
}

.page-404 .search-404 {
    margin-top: 35px
}

.page-404 .search-404 .filed-text {
    display: inline-block;
    vertical-align: middle;
    width: 370px;
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.page-404 .search-404 .filed-text::-webkit-input-placeholder {
    color: #fff
}

.page-404 .search-404 .filed-text:-moz-placeholder {
    color: #fff
}

.page-404 .search-404 .filed-text::-moz-placeholder {
    color: #fff
}

.page-404 .search-404 .filed-text:-ms-input-placeholder {
    color: #fff
}

.page-404 .search-404 .filed-text:focus {
    border-color: #e1bd85
}

.page-404 .search-404 .awe-btn {
    vertical-align: middle;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    height: 40px;
    line-height: 36px;
    padding-top: 0;
    padding-bottom: 0
}

.section-contact {
    background-color: #f6f6f6;
    padding-top: 30px;
    padding-bottom: 45px
}

.contact .text {
    margin-top: 15px
}

.contact .text h2 {
    color: #232323;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 36px
}

.contact .text p {
    margin-top: 10px;
    color: #232323;
    line-height: 1.428em
}

.contact .text ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 30px
}

.contact .text ul li {
    padding: 7px 0
}

.contact .text ul li .icon {
    color: #e1bd85;
    font-size: 20px;
    margin-right: 20px;
    vertical-align: middle
}

.contact .contact-location {
    border-top: 1px solid #e3e3e3;
    padding-top: 15px;
    margin-top: 35px
}

.contact .contact-location .btn-collapse {
    text-transform: uppercase;
    color: #232323;
    font-weight: bold;
    font-size: 20px;
    display: block;
    font-family: 'Work Sans', sans-serif;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.contact .contact-location .btn-collapse span {
    float: right;
    font-size: 24px
}

.contact .contact-location .btn-collapse:hover {
    color: #d4a355
}

.contact .contact-location .location-group {
    margin-top: 25px
}

.contact .contact-location .location-group h6 {
    font-weight: bold;
    color: #444444;
    font-size: 20px;
    margin-bottom: 5px;
    text-transform: uppercase
}

.contact .contact-location .location-group span {
    color: #898989;
    font-size: 15px
}

.contact .contact-location .location-group .location-item {
    margin-top: 25px;
    cursor: pointer
}

.contact .contact-location .location-group .location-item:after {
    content: '';
    display: table;
    clear: both
}

.contact .contact-location .location-group .location-item .img {
    float: left;
    width: 100px;
    position: relative
}

.contact .contact-location .location-group .location-item .img .fa {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    margin: auto;
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    background-color: #e1bd85;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.contact .contact-location .location-group .location-item .img img {
    width: 100%
}

.contact .contact-location .location-group .location-item .text {
    margin-left: 120px;
    color: #333333
}

.contact .contact-location .location-group .location-item .text address {
    margin-top: 10px;
    margin-bottom: 0
}

.contact .contact-location .location-group .location-item .text p {
    margin-bottom: 0;
    margin-top: 7px;
    font-size: 14px
}

.contact .contact-form .awe-select-fb {
    border: 1px solid #232323;
    width: 100%;
    margin-top: 20px;
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 5px;
}

.contact .contact-form .field-text,
.contact .contact-form .field-textarea,
.contact .contact-form .field-date {
    border: 1px solid #232323;
    width: 100%;
    margin-top: 20px;
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.contact .contact-form .field-text::-webkit-input-placeholder,
.contact .contact-form .field-textarea::-webkit-input-placeholder {
    color: #232323
}

.contact .contact-form .field-text:-moz-placeholder,
.contact .contact-form .field-textarea:-moz-placeholder {
    color: #232323
}

.contact .contact-form .field-text::-moz-placeholder,
.contact .contact-form .field-textarea::-moz-placeholder {
    color: #232323
}

.contact .contact-form .field-text:-ms-input-placeholder,
.contact .contact-form .field-textarea:-ms-input-placeholder {
    color: #232323
}

.contact .contact-form .field-text:focus,
.contact .contact-form .field-textarea:focus {
    border-color: #e1bd85
}

.contact .contact-form .field-textarea {
    resize: none;
    height: 210px
}

.contact .contact-form .awe-btn {
    margin-top: 20px;
    min-width: 180px;
    padding: 5px 25px;
    font-size: 16px
}

.contact .contact-form label {
    margin-top: 30px;
    font-size: 15px
}

.contact-map {
    height: 500px;
    position: relative
}

.contact-map #map {
    height: 100%;
    width: 100%
}

.section-shortcode {
    padding-top: 50px;
    padding-bottom: 70px;
    background-color: #f8f8f8
}

.shortcode .heading-has-sub {
    margin-bottom: 40px
}

.shortcode .heading-has-sub p {
    margin-bottom: 0;
    color: #555555;
    font-size: 13.9px;
    margin-top: 10px
}

.shortcode .shortcode-heading-list [class*="col-"] {
    margin-top: 20px
}

.shortcode .shortcode-ordered-list .shortcode-heading {
    margin-top: 30px
}

.shortcode .shortcode-ordered-list ul,
.shortcode .shortcode-ordered-list ol {
    margin-top: 20px;
    padding-left: 15px;
    margin-bottom: 0
}

.shortcode .shortcode-ordered-list ul li,
.shortcode .shortcode-ordered-list ol li {
    color: #232323;
    padding: 5px 0
}

.shortcode .shortcode-tabs {
    margin-top: 40px
}

.shortcode .shortcode-tabs .tabs {
    margin-top: 30px
}

.shortcode .shortcode-tabs .tabs p {
    margin-bottom: 0
}

.shortcode .shortcode-accordion {
    margin-top: 40px
}

.shortcode .shortcode-accordion .accordion {
    margin-top: 30px
}

.shortcode .shortcode-accordion .accordion p {
    margin-bottom: 0
}

.shortcode .shortcode-button {
    margin-top: 40px
}

.shortcode .shortcode-button .button-list {
    margin-top: 20px
}

.shortcode .shortcode-button .awe-btn {
    margin-top: 10px
}

.shortcode .shortcode-alert {
    margin-top: 40px
}

.shortcode .shortcode-alert .alert-list {
    margin-top: 20px
}

.shortcode .shortcode-alert .alert {
    margin-top: 10px
}

.shortcode .shortcode-calendar-drop {
    margin-top: 0px
}

.shortcode .shortcode-calendar-drop .shortcode-calendar,
.shortcode .shortcode-calendar-drop .shortcode-select {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0px;
    margin-top: 20px
}

.shortcode .shortcode-calendar-drop .shortcode-calendar .awe-calendar-wrapper,
.shortcode .shortcode-calendar-drop .shortcode-select .awe-calendar-wrapper {
    width: 150px;
    margin-right: 0px
}

.shortcode .shortcode-calendar-drop .shortcode-calendar .btn-group.bootstrap-select.awe-select,
.shortcode .shortcode-calendar-drop .shortcode-select .btn-group.bootstrap-select.awe-select {}

.shortcode .shortcode-calendar-drop .shortcode-calendar .btn-group.bootstrap-select.awe-select .btn,
.shortcode .shortcode-calendar-drop .shortcode-select .btn-group.bootstrap-select.awe-select .btn {
    border: 1px solid #232323
}

.shortcode .shortcode-calendar-drop .shortcode-calendar .btn-group.bootstrap-select.awe-select .filter-option,
.shortcode .shortcode-calendar-drop .shortcode-select .btn-group.bootstrap-select.awe-select .filter-option,
.shortcode .shortcode-calendar-drop .shortcode-calendar .btn-group.bootstrap-select.awe-select .caret,
.shortcode .shortcode-calendar-drop .shortcode-select .btn-group.bootstrap-select.awe-select .caret {
    color: #5c5c5c
}

.shortcode .shortcode-tab-price {
    margin-top: 40px
}

.shortcode .shortcode-tab-price .tab-price {
    margin-top: 30px
}

.section-reservation-page {
    padding-bottom: 70px;
    padding-top: 30px
}

.reservation_step ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0;
    text-align: center;
    border-bottom: 1px solid #e4e4e4
}

.reservation_step ul li {
    display: inline-block;
    font-size: 15px;
    position: relative;
    margin: 0 20px
}

.reservation_step ul li:before {
    content: '';
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    position: absolute;
    background-color: transparent;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation_step ul li a {
    color: #333;
    display: block;
    padding: 15px 0
}

.reservation_step ul li a span {
    font-weight: bold;
    font-size: 18px;
    margin-right: 5px
}

.reservation_step ul li.active:before,
.reservation_step ul li:hover:before {
    background-color: #e1bd85
}

.reservation-date {
    margin-top: 30px
}

.reservation-date ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding: 20px 20px
}

.reservation-date ul li {
    color: #333;
    font-size: 12px;
    overflow: hidden;
    font-weight: 500;
    padding: 5px 0
}

.reservation-date ul li span:last-child {
    float: right;
    font-weight: bold;
    text-transform: uppercase
}

.reservation-room-selected {
    background-color: #f1f1f1;
    margin-top: 30px
}

.reservation-room-selected .reservation-room-seleted_item {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #e4e4e4
}

.reservation-room-selected .reservation-room-seleted_item:last-child {
    border-bottom-width: 0
}

.reservation-room-selected .reservation-room-seleted_item h6 {
    display: inline-block;
    vertical-align: middle;
    color: #232323;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 10px 0 0;
    line-height: 1.428em;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold
}

.reservation-room-selected .reservation-room-seleted_item .reservation-option {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Hind'
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name {
    overflow: hidden;
    margin-top: 10px
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name h2 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 80px;
    float: left;
    margin: 0
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name h2 a {
    color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name h2 a:hover {
    color: #e1bd85
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name .reservation-amout {
    float: right;
    font-weight: bold;
    font-size: 14px;
    color: #e1bd85
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name.has-package h2 {
    font-size: 16px
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_name.has-package h2 a {
    color: #e1bd85
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_change {
    color: #898989;
    font-size: 12px;
    font-style: italic;
    text-decoration: underline;
    display: inline-block;
    margin-top: 7px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_change:hover {
    color: #e1bd85
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_package {
    border-top: 1px solid #e4e4e4;
    margin-top: 15px;
    padding-top: 5px
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_package ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-bottom: 2px;
    margin-top: 5px
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_package ul li {
    color: #333;
    font-size: 13px;
    overflow: hidden;
    font-weight: 500;
    padding: 5px 0
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_package ul li span:last-child {
    float: right;
    font-weight: bold;
    text-transform: uppercase
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_package ul+ul {
    border-top: 1px solid #e4e4e4;
    padding-top: 8px
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_total-room {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-top: 1px solid #e4e4e4;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: 'Montserrat'
}

.reservation-room-selected .reservation-room-seleted_item .reservation-room-seleted_total-room .reservation-amout {
    float: right;
    color: #e1bd85
}

.reservation-room-selected .reservation-room-seleted_item.reservation_disable {
    background-color: #f1f1f1
}

.reservation-room-selected .reservation-room-seleted_item.reservation_disable h6 {
    opacity: .4;
    font-size: 12px
}

.reservation-room-selected .reservation-room-seleted_item.reservation_disable span {
    opacity: .3
}

.reservation-room-selected .reservation-room-seleted_current {
    overflow: hidden;
    color: #fff;
    padding: 15px 20px 13px 40px
}

.reservation-room-selected .reservation-room-seleted_current h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.428em;
    position: relative;
    color: #fff
}

.reservation-room-selected .reservation-room-seleted_current h6:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    left: -20px;
    position: absolute;
    font-size: 12px;
    font-weight: normal
}

.reservation-room-selected .reservation-room-seleted_current span {
    font-size: 12px;
    font-weight: 500
}

.reservation-room-selected .reservation-room-seleted_current.reservation-bg_blue {
    color: #fff
}

.reservation-room-selected .reservation-room-seleted_total {
    padding: 20px 20px;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat'
}

.reservation-room-selected .reservation-room-seleted_total label {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0
}

.reservation-room-selected .reservation-room-seleted_total .reservation-total {
    float: right;
    color: #e1bd85;
    font-size: 18px
}

.reservation-room-selected .reservation-room-seleted_total.bg-blue {
    color: #fff
}

.reservation-room-selected .reservation-room-seleted_total.bg-blue .reservation-total {
    color: #fff
}

.reservation-sidebar {
    padding-top: 10px
}

.reservation-heading {
    font-size: 16px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    padding: 12px 20px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #e4e4e4
}

.reservation-sidebar_availability {
    padding: 0 20px 20px 20px;
    margin-top: 30px
}

.reservation-sidebar_availability .reservation-heading {
    margin-left: -20px;
    margin-right: -20px
}

.reservation-sidebar_availability .check_availability_title {
    margin-top: 20px;
    color: #e1bd85;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    font-weight: bold
}

.reservation-sidebar_availability .check_availability-field {
    margin-top: 15px
}

.reservation-sidebar_availability .check_availability-field label {
    display: block;
    color: #232323;
    font-size: 12px;
    margin-bottom: 7px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.reservation-sidebar_availability .check_availability-field .awe-calendar-wrapper {
    width: 100%
}

.reservation-sidebar_availability .check_availability-field .awe-calendar-wrapper .awe-calendar {
    background-color: #fff;
    border-width: 0;
    height: 30px;
    line-height: 30px;
    color: #898989;
    font-weight: 500
}

.reservation-sidebar_availability .check_availability-field .bootstrap-select.btn-group.awe-select {
    width: 100%
}

.reservation-sidebar_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle {
    background-color: #fff;
    height: 30px;
    border-width: 0;
    line-height: 30px
}

.reservation-sidebar_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option,
.reservation-sidebar_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-toggle .caret {
    color: #898989;
    font-weight: 500
}

.reservation-sidebar_availability .check_availability-field .bootstrap-select.btn-group.awe-select .dropdown-menu ul li a {
    padding: 7px 10px
}

.reservation-sidebar_availability .check_availability_group {
    padding-left: 70px;
    position: relative
}

.reservation-sidebar_availability .check_availability_group:after {
    display: table;
    content: '';
    clear: both
}

.reservation-sidebar_availability .check_availability_group .label-group {
    position: absolute;
    left: 0;
    bottom: 5px;
    font-weight: bold;
    font-size: 12px;
    color: #232323;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase
}

.reservation-sidebar_availability .check_availability_group .check_availability-field_group {
    margin-left: -4px;
    margin-right: -4px
}

.reservation-sidebar_availability .check_availability_group .check_availability-field {
    width: 50%;
    padding-left: 4px;
    padding-right: 4px;
    float: left
}

.reservation-sidebar_availability .awe-btn {
    margin-top: 30px;
    width: 100%;
    font-size: 14px;
    padding: 10px
}

.reservation_content {
    padding-bottom: 20px;
    margin-top: 40px
}

.reservation_content:before,
.reservation_content:after {
    content: '';
    display: table;
    clear: both
}

.reservation-calendar_custom .reservation-calendar_title {
    text-align: center;
    color: #232323;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    margin-left: 3px;
    margin-right: 3px;
    font-family: 'Montserrat'
}

.reservation-calendar_custom .reservation-calendar_title .reservation-calendar_corner {
    display: block;
    width: 24px;
    height: 24px;
    text-align: center;
    color: #cbcbcb;
    line-height: 24px;
    font-size: 12px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-calendar_custom .reservation-calendar_title .reservation-calendar_corner:hover {
    color: #e1bd85
}

.reservation-calendar_custom .reservation-calendar_title .reservation-calendar_corner.reservation-calendar_prev {
    left: 0
}

.reservation-calendar_custom .reservation-calendar_title .reservation-calendar_corner.reservation-calendar_next {
    right: 0
}

.reservation-calendar_custom .reservation-calendar_tabel {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    table-layout: fixed;
    margin-top: 15px
}

.reservation-calendar_custom .reservation-calendar_tabel th {
    text-transform: uppercase;
    text-align: center;
    color: #232323;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px
}

.reservation-calendar_custom .reservation-calendar_tabel td {
    background-color: #fff;
    background-clip: content-box;
    padding: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-calendar_custom .reservation-calendar_tabel td a {
    position: relative;
    display: block;
    padding-top: 100%;
    color: #232323;
    font-size: 12px
}

.reservation-calendar_custom .reservation-calendar_tabel td a:before {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    border: 2px solid transparent;
    position: absolute;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-calendar_custom .reservation-calendar_tabel td a small {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 12px
}

.reservation-calendar_custom .reservation-calendar_tabel td a span {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.reservation-calendar_custom .reservation-calendar_tabel td a:hover:before {
    border-color: #e1bd85
}

.reservation-calendar_custom .reservation-calendar_tabel td.current-select {
    background-color: #e1bd85
}

.reservation-calendar_custom .reservation-calendar_tabel td.current-select a {
    color: #fff
}

.reservation-room .reservation-room_item {
    margin-top: 45px
}

.reservation-room .reservation-room_item:first-child {
    margin-top: 0
}

.reservation-room_item {
    overflow: hidden
}

.reservation-room_item .reservation-room_name {
    color: #232323;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    font-size: 24px
}

.reservation-room_item .reservation-room_name a {
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-room_item .reservation-room_name a:hover {
    color: #e1bd85
}

.reservation-room_item .reservation-room_img {
    float: left;
    margin-right: 30px;
    width: 370px;
    margin-top: 25px
}

.reservation-room_item .reservation-room_img img {
    width: 100%
}

.reservation-room_item .reservation-room_text {
    overflow: hidden;
    margin-top: 25px
}

.reservation-room_item .reservation-room_text .reservation-room_desc {
    color: #333333
}

.reservation-room_item .reservation-room_text .reservation-room_desc p {
    margin-bottom: 0;
    font-size: 14px
}

.reservation-room_item .reservation-room_text .reservation-room_desc p b {
    color: #232323
}

.reservation-room_item .reservation-room_text .reservation-room_desc ul {
    padding-left: 18px;
    margin-bottom: 0;
    margin-top: 15px
}

.reservation-room_item .reservation-room_text .reservation-room_desc ul li {
    color: #232323;
    padding: 3px 0;
    font-size: 14px
}

.reservation-room_item .reservation-room_text .reservation-room_view-more {
    display: inline-block;
    clear: both;
    margin-top: 10px;
    color: #e1bd85;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 25px;
    text-decoration: underline;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-room_item .reservation-room_text .reservation-room_view-more:hover {
    color: #e1bd85
}

.reservation-room_item .reservation-room_text .reservation-room_price {
    display: inline-block;
    vertical-align: middle;
    color: #898989;
    font-size: 14px;
    margin: 0;
    line-height: 1;
    font-family: 'Montserrat'
}

.reservation-room_item .reservation-room_text .reservation-room_price .reservation-room_amout {
    font-weight: bold;
    color: #333333;
    font-size: 30px
}

.reservation-room_item .reservation-room_text .awe-btn {
    margin-left: 30px;
    min-width: 120px
}

.reservation-room_item .reservation-room_package {
    clear: both;
    margin-top: 30px
}

.reservation-room_item .reservation-room_package .reservation-room_package-more {
    display: block;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    padding: 15px 30px;
    background-color: #f1f1f1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-room_item .reservation-room_package .reservation-room_package-more:after {
    content: '\f067';
    font-family: 'FontAwesome';
    float: right;
    font-size: 12px
}

.reservation-room_item .reservation-room_package .reservation-room_package-more[aria-expanded="true"]:after {
    content: '\f068'
}

.reservation-package_item {
    overflow: hidden;
    padding: 20px 30px;
    background-color: #fbfbfb;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-package_item .reservation-package_img {
    float: left;
    width: 100px;
    margin-right: 30px
}

.reservation-package_item .reservation-package_img img {
    width: 100%
}

.reservation-package_item .reservation-package_text {
    overflow: hidden;
    padding-right: 130px;
    position: relative;
    min-height: 72px
}

.reservation-package_item .reservation-package_text h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin: 0
}

.reservation-package_item .reservation-package_text h4 a {
    color: #232323;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-package_item .reservation-package_text h4 a:hover {
    color: #e1bd85
}

.reservation-package_item .reservation-package_text p {
    color: #898989;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 10px
}

.reservation-package_item .reservation-package_text p b {
    color: #232323
}

.reservation-package_item .reservation-package_text .reservation-package_book-price {
    position: absolute;
    right: 0;
    top: 0
}

.reservation-package_item .reservation-package_text .reservation-package_book-price .reservation-package_price {
    color: #333333;
    text-align: right
}

.reservation-package_item .reservation-package_text .reservation-package_book-price .reservation-package_price .amout {
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    font-family: 'Montserrat'
}

.reservation-package_item .reservation-package_text .reservation-package_book-price .awe-btn {
    padding: 5px 10px;
    min-width: inherit;
    font-size: 10px;
    margin-top: 5px;
    font-family: 'Hind';
    font-weight: 700
}

.reservation-package_item:hover,
.reservation-package_item.current-select {
    background-color: #f1f1f1
}

.reservation-chosen-message {
    background-color: #f1f1f1;
    text-align: center;
    padding: 30px 30px
}

.reservation-chosen-message h4 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    font-size: 20px;
    color: #232323
}

.reservation-chosen-message p {
    color: #898989;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 0
}

.reservation-chosen-message .awe-btn {
    width: 150px;
    color: #fff;
    padding: 10px;
    margin-top: 30px
}

.reservation-billing-detail {
    margin-left: 80px;
    max-width: 80%;
}

.reservation-billing-detail .reservation-login {
    margin-bottom: 0;
    color: #898989;
    font-size: 14px
}

.reservation-billing-detail .reservation-login a {
    font-weight: bold;
    color: #333;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-billing-detail .reservation-login a:hover {
    color: #d4a355
}

.reservation-billing-detail h4 {
    color: #000;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 10px;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    font-size: 30px
}

.reservation-billing-detail label {
    color: #5c5c5c;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px
}

.reservation-billing-detail label sup {
    color: #d4a355;
    font-style: 12px
}

.reservation-billing-detail label.label-radio .input-radio {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px
}

.reservation-billing-detail .bootstrap-select.awe-select.btn-group {
    width: 100%
}

.reservation-billing-detail .bootstrap-select.awe-select.btn-group .btn {
    border: 1px solid #a3a3a3;
    font-weight: 500;
    width: 100%;
}

.reservation-billing-detail .bootstrap-select.awe-select.btn-group .btn .filter-option,
.reservation-billing-detail .bootstrap-select.awe-select.btn-group .btn .caret {
    color: #333
}

.reservation-billing-detail .input-text,
.reservation-billing-detail .input-textarea {
    border: 1px solid #A3A3A3;
    width: 100%;
    font-size: 14px;
    color: #333333;
    font-weight: normal
}

.reservation-billing-detail .reservation-code {
    background-color: #333333;
    color: #ffffff;
    font-size: 14px;
    padding: 15px 20px;
    margin-top: 35px
}

.reservation-billing-detail .reservation-code a {
    color: #fff;
    font-weight: bold;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-billing-detail .reservation-code a:hover {
    color: #e1bd85
}

.reservation-billing-detail .option-bank {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 10px
}

.reservation-billing-detail .option-bank li {
    position: relative;
    padding-left: 30px;
    margin-top: 25px
}

.reservation-billing-detail .option-bank li .label-radio {
    font-size: 14px;
    margin: 0 15px 0 0;
    display: inline-block
}

.reservation-billing-detail .option-bank li .label-radio .input-radio {
    position: absolute;
    top: 4px;
    left: 0
}

.reservation-billing-detail .option-bank li p {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 500;
    margin-top: 5px
}

.reservation-billing-detail .option-bank li:last-child .label-radio .input-radio {
    top: 9px
}

.reservation-billing-detail .awe-btn {
    padding: 5px 25px;
    font-family: 'Work Sans';
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    min-width: 180px;
}

.reservation-billing-detail .form-control {
    border-radius: 0px;
}

.reservation-billing-detail .view-pass {
    position: absolute;
    right: 20px;
    top: 50%;
    color: #5c5c5c;
    line-height: 1;
    cursor: pointer;
    font-size: 11px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.reservation-billing-detail .view-pass:hover {
    color: #d4a355
}

#footer {
    background-color: #232323
}

#footer .footer_top {
    background: #f6f6f6;
    padding: 30px 68px 10px 30px;
    color: #5c5c5c;
    border-top: 1px solid #e1e1e1;
}

#footer .footer_top p {
    color: #5c5c5c;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
}

#footer .footer_top a {
    color: #5c5c5c;
    font-size: 13px;
}

#footer .footer_top a:hover {
    color: #d4a355;

}

#footer .footer_top .mailchimp h4 {
    display: inline-block;
    font-family: 'Work Sans', sans-serif;
    margin: 0;
    color: #fff;
    font-size: 25px;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: normal
}

#footer .footer_top .mailchimp h4:before {
    content: "\f003";
    border: 3px solid #e1bd85;
    padding: 10px;
    color: #e1bd85;
    margin-right: 20px;
    font-size: 18px;
    font-family: 'FontAwesome';
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

#footer .footer_top .mailchimp .mailchimp-form {
    display: inline-block;
    vertical-align: middle;
    margin-left: 65px
}

#footer .footer_top .mailchimp .mailchimp-form .input-text {
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
    width: 290px
}

#footer .footer_top .mailchimp .mailchimp-form .input-text::-webkit-input-placeholder {
    color: #fff
}

#footer .footer_top .mailchimp .mailchimp-form .input-text:-moz-placeholder {
    color: #fff
}

#footer .footer_top .mailchimp .mailchimp-form .input-text::-moz-placeholder {
    color: #fff
}

#footer .footer_top .mailchimp .mailchimp-form .input-text:-ms-input-placeholder {
    color: #fff
}

#footer .footer_top .mailchimp .mailchimp-form .awe-btn {
    vertical-align: middle;
    min-width: 135px;
    text-align: center;
    padding: 7px 10px;
    height: 40px;
    color: #fff;
    background-color: #e1bd85;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 10px
}

#footer .footer_top .mailchimp .mailchimp-form .awe-btn:hover {
    background: #fff;
    color: #e1bd85
}

#footer .footer_top .social .social-content {
    font-size: 0;
    float: right;
}

#footer .footer_top .social .social-content a {
    font-size: 22px;
    display: inline-block;
    color: #5c5c5c;
    line-height: 32px;
    width: 32px;
    height: 32px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

#footer .footer_top .social .social-content a:hover {
    border-color: #e1bd85
}

#footer .footer_bottom .social .social-content a {
    font-size: 14px;
    display: inline-block;
    color: #fff;
    border: 2px solid #fff;
    line-height: 32px;
    width: 32px;
    height: 32px;
    margin: 15px 0 15px;
    text-align: center;
    margin-right: 20px;
    font-size: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

#footer .footer_bottom .social .social-content a:hover {
    border-color: #e1bd85
}

#footer .footer_center {
    padding-bottom: 40px
}

#footer .footer_center p {
    font-size: 14px
}

#footer .footer_center .widget-logo {
    overflow: hidden;
    padding-top: 20px
}

#footer .footer_center .widget-logo .img {
    max-width: 145px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 40px
}

#footer .footer_center .widget-logo .img img {
    max-width: 100%
}

#footer .footer_center .widget-logo .text {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px
}

#footer .footer_center .widget-logo .text p {
    color: #acacac;
    margin-bottom: 10px
}

#footer .footer_center .widget-logo .text p i {
    margin-right: 10px;
    font-size: 16px;
    color: #fff
}

#footer .footer_center .widget-logo .text p a {
    color: #acacac
}

#footer .footer_center .widget-logo .text p a:hover {
    color: #e1bd85;
    text-decoration: underline
}

#footer .footer_center .widget-logo .text p:last-child {
    margin-bottom: 0
}

#footer .footer_center .widget {
    margin-top: 40px
}

#footer .footer_center .widget .widget-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 20px
}

#footer .footer_center .widget>ul {
    margin-top: -5px
}

#footer .footer_center .widget ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

#footer .footer_center .widget ul li a {
    color: #acacac;
    padding: 5px 0;
    display: inline-block;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

#footer .footer_center .widget ul li a:hover {
    color: #fff
}

#footer .footer_center .widget ul li .sub-menu {
    margin-left: 15px
}



#CDSWIDCOE.widCOE .widCOELink a {
    font: normal normal 12px Arial, Verdana, "Bitstream Vera Sans", Helvetica, sans-serif !important;
    color: black;
    text-decoration: none;
}

#footer .footer_center .widget_tripadvisor .tripadvisor p {
    color: #acacac;
    font-size: 13.75px;
    margin-bottom: 20px
}

#footer .footer_center .widget_tripadvisor .tripadvisor img {
    max-width: 100%;
    margin-bottom: 20px
}

#footer .footer_center .widget_tripadvisor .tripadvisor .tripadvisor-circle {
    font-size: 0;
    clear: bold;
    display: block
}

#footer .footer_center .widget_tripadvisor .tripadvisor .tripadvisor-circle i {
    font-size: 15px;
    border: 2px solid #8bc23f;
    padding: 1px;
    background-color: #8bc23f;
    background-clip: content-box;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

#footer .footer_center .widget_tripadvisor .tripadvisor .tripadvisor-circle i.part:before {
    content: '';
    position: absolute;
    background-color: #1f232b;
    display: block;
    height: 8px;
    width: 4px;
    right: 1px;
    top: 1px
}

#footer .footer_bottom {
    background-color: #f6f6f6;
    padding: 10px 0;
    text-align: center;
    color: #a3a3a3;
    font-size: 12.22px
}

#footer .footer_bottom p {
    margin-bottom: 0;
    font-size: 13px
}

#footer .footer_bottom a {
    color: #fff;
    font-size: 14px;
}

#footer .footer_bottom a:hover {
    color: #d4a355;
}

.brand {

    line-height: 120px
}

.brand img {
    max-height: 70px
}

.header_m {
    display: none;
}

section.booking-mobile {
    display: none;
}

#compare {
    border-collapse: collapse;
    width: 100%;
}

#compare td,
#compare th {
    border: 1px solid #ddd;
    padding: 3px 15px;
    text-align: center;
}

#compare tr:nth-child(even) {
    background-color: #f2f2f2;
}

#compare tr:hover {
    background-color: #ddd;
}

#compare th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    background-color: #d4a355;
    color: white;
    text-align: center;
}

.menu-book {
    display: none;
}

.book a {
    line-height: 40px !important;
}

.experience {
    text-align: center;
    max-width: 1275px;
    margin: 0 auto;
    letter-spacing: 0.05em;
    margin-bottom: 55px;
}

.item-experience .title {
    color: #D4A355;
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 20px 0 10px;
}

.item-experience .desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #5C5C5C;
    max-width: 250px;
    margin: 0 auto;
    margin-bottom: 20px
}

.item.item-experience {
    margin-bottom: 0px;
}

.item-experience h2 a:hover {
    color: #5c5c5c;
}

.item-experience h2 a:visited {
    color: #D4A355 !important
}

.item-experience a:visited {
    color: #5c5c5c;
}

.item-thumb .title {
    color: #D4A355;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 30px 0 30px;
}

.item-thumb .desc {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #5C5C5C;
    max-width: 325px;
    margin: 0 auto;
    margin-bottom: 20px
}

.item.item-thumb {
    margin-bottom: 50px;
}

.item-thumb h2 a:hover {
    color: #5c5c5c;
}

.item-thumb .img:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000));
    background: -webkit-linear-gradient(top, transparent, #000000);
    background: -moz-linear-gradient(top, transparent, #000000);
    background: -ms-linear-gradient(top, transparent, #000000);
    background: -o-linear-gradient(top, transparent, #000000);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.item-thumb:hover .img:before {
    opacity: .5;
    top: 0
}

.item-thumb1 .title {
    color: #D4A355;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 30px 0 10px;
}

.item-thumb1 .desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #5C5C5C;
    max-width: 325px;
    margin: 0 auto;
    margin-bottom: 20px
}

.item.item-thumb1 {
    margin-bottom: 50px;
}

.item-thumb1 h2 a:hover {
    color: #5c5c5c;
}

.item-thumb1 .img:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000));
    background: -webkit-linear-gradient(top, transparent, #000000);
    background: -moz-linear-gradient(top, transparent, #000000);
    background: -ms-linear-gradient(top, transparent, #000000);
    background: -o-linear-gradient(top, transparent, #000000);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out
}

.item-thumb1:hover .img:before {
    opacity: .5;
    top: 0
}

.room-detail_thumbs h2 {
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
    color: #5c5c5c;
}

.bg-grey {
    background-color: #e5e5e5;
}

.section-home-room {
    padding: 40px 0 40px 0;
}

#header .header_content {
    text-align: center;
}

.desc-overview {
    text-align: center;
    max-width: 1275px;
    margin: 0 auto;
    letter-spacing: 0.05em;
    margin-bottom: 100px;
}

.facilities_thumbs {
    margin-top: 10px
}

.facilities_thumbs .owl-item {
    padding: 0 15px
}

.facilities_thumbs .owl-item.active a:before {
    border-color: #e1bd85
}

.facilities_thumbs a {
    position: relative
}

.facilities_thumbs a:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    pointer-events: none;
    z-index: 1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}

.facilities_thumbs a:hover::before {
    opacity: .5;
    top: 0;
    /*background: -moz-linear-gradient(top, transparent, #000000);*/
}

.facilities_thumbs p {
    margin-bottom: 25px;
}

.facilities_thumbs p a:link {
    color: #A3A3A3;
}

.facilities_thumbs p a:visited {
    color: #A3A3A3;
}

.facilities_thumbs p a:visited {
    color: #A3A3A3;
}

.facilities_thumbs a img {
    width: 100%
}

.facilities_thumbs .owl-prev,
.facilities_thumbs .owl-next {
    width: 0px;
    color: #fff;
    font-size: 30px;
    text-align: center;
    border-width: 0;
    height: auto;
    top: 0;
    bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    margin-top: 33%;
    margin-left: 5%;
    margin-right: 5%;
    width: 36px;
    height: 36px;
    background: rgba(129, 129, 129, 0.3);
    border-radius: 100%;
    box-shadow: 0px 3px 5px #b5b5b5;
}

.facilities_thumbs .owl-prev:hover,
.facilities_thumbs .owl-next:hover {

    color: #dedede
}

.facilities_thumbs .owl-prev .fa,
.facilities_thumbs .owl-next .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.facilities_thumbs .owl-prev {
    left: 0
}

.facilities_thumbs .owl-next {
    right: 0
}

.fa.fa-chevron-right.mob {
    display: none;
}

.brown {
    color: #d4a355 !important;
}

.linelg {
    border: 0.1px solid #5c5c5c;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
}

.bhs {
    display: none;
}

.lebar {
    width: 132px !important;
    height: 4px !important;
    background-color: #D4A355;
    margin: 30px auto !important;
}

.barcode {
    margin-top: -85px;
    margin-left: -302px;
}


@media screen and (max-width:1366px) {
    .header_content .menu>li>a {
        padding: 0 8px 15px;
    }
}

@media screen and (max-width:1199px) {

    .room-detail_thumbs .owl-prev .fa,
    .room-detail_thumbs .owl-next .fa {
        position: absolute;
        top: 25%;
        left: 50%;
    }

    /*#header .header_menu {
        margin-top: 36px;
    }*/
    #header.header-sticky .header_menu {
        margin-top: 15px;
    }

    .header_logo img {
        /*margin-top: 20px;
    width: 175px;*/
    }

    .header_m {
        display: block;
    }

    .header_d {
        display: none;
    }

    .check-availability .availability-form .vailability-submit {
        padding-top: 0px;
    }

    .check-availability .availability-form .awe-calendar-wrapper {
        width: 140px;
        margin-right: 10px;
    }

    .check-availability .availability-form .bootstrap-select.btn-group.awe-select {
        margin-right: 10px;
        width: 110px;
    }

    .check-availability {
        text-align: center;
        margin-top: 0;
        padding: 20px 0
    }

    .check-availability:before {
        background-color: rgba(0, 0, 0, 0.95);
        background-image: none;
        height: 100%;
        top: 0
    }

    .check-availability h2 {
        line-height: 1.4em;
        display: inline-block
    }

    .check-availability .availability-form {
        margin-top: 0px;
        display: inline-block
    }

    .check-availability1 .availability-form1 .vailability-submit {
        padding-top: 0px;
    }

    .check-availability1 .availability-form1 .awe-calendar-wrapper {
        width: 140px;
        margin-right: 10px;
    }

    .check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select {
        margin-right: 10px;
        width: 110px;
    }

    .check-availability1 {
        text-align: center;
        margin-top: 0;
        padding: 20px 0
    }

    .check-availability1:before {
        background-color: #333;
        background-image: none;
        height: 100%;
        top: 0
    }

    .check-availability1 h2 {
        line-height: 1.4em;
        display: inline-block
    }

    .check-availability1 .availability-form1 {
        margin-top: 0px;
        display: inline-block
    }

    .event .event-item .text h2 {
        font-size: 20px
    }

    .event .event-item .text .awe-btn {
        min-width: inherit;
        font-size: 10px
    }

    .about .about-item .text {
        margin-top: 0
    }

    .sidebar {
        margin-right: 0
    }

    .checkout .checkout_head h3 {
        font-size: 20px
    }

    .checkout .checkout_margin {
        margin-left: 0
    }

    .checkout .checkout_cartinfo p {
        font-size: 16px
    }

    .checkout .checkout_option ul li img {
        display: block;
        margin-left: 0;
        margin-top: 10px
    }

    .accomd-modations .accomd-modations-header .heading {
        display: inline-block;
        width: 100%;
        margin-top: 20px
    }

    .room_item-2 .img {
        width: 56.6667%
    }

    .room_item-2 .text {
        width: -moz-calc(43.3333% - 1px);
        width: -o-calc(43.3333% - 1px);
        width: -webkit-calc(43.3333% - 1px);
        width: calc(43.3333% - 1px)
    }

    .room_item-2 .text h2 {
        font-size: 30px
    }

    .room_item-2 .text p {
        margin-top: 25px
    }

    .room_item-2 .text .awe-btn {
        margin-top: 30px
    }

    .room_item-2.img-right .img {
        left: 43.3333%
    }

    .room_item-2.img-right .text {
        right: 56.6667%
    }

    .room-detail_book .room-detail_form .awe-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        width: 200px
    }

    .reservation-room_item .reservation-room_img {
        width: 100%;
        margin: 0;
        float: none;
        margin-top: 10px
    }

    .reservation-room_item .reservation-room_text {
        width: 100%;
        margin-top: 20px;
        float: none
    }

    .gallery-isotope.col-6 .item-isotope,
    .gallery-isotope.col-6 .item-size {
        width: 25%
    }

    .gallery-isotope.col-4 .item-isotope,
    .gallery-isotope.col-4 .item-size,
    .gallery-isotope.col-4 .item-isotope.larger {
        width: 33.33333%
    }

    .our-best .text ul li {
        width: 100%;
        margin-top: 0;
        padding: 0 !important
    }

    #footer .footer_top {
        /*text-align: center;*/
        background-image: none;
        padding: 20px 0;
        line-height: 30px;
    }

    #footer .footer_top .social .social-content {
        float: left;
    }

    #footer .footer_top .mailchimp {
        display: inline-block
    }

    #footer .footer_top .social {
        margin-top: 0px
    }

    #footer .footer_center .widget_logo {
        text-align: center
    }

    #footer .footer_center .widget_logo .widget-logo {
        text-align: left;
        display: inline-block
    }

    .room-detail_tab-content {
        padding-top: 30px;
    }

    .section-home-about {
        padding: 30px 0 30px 0;
    }

    .section-contact {
        padding-top: 110px;
    }

    .contact-map {
        height: 350px;
        position: relative;
    }

    .section_page-gallery {
        background-color: #fff;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .header_content .menu>li>a {
        padding: 0 8px 15px;
        font-size: 14px;
    }

    #header.header-sticky {
        margin-top: -105px;
    }

    .section-slider {
        max-height: 500px !important;
    }
}

@media screen and (max-width:991px) {
    .menu-book {
        height: 25px;
        position: absolute;
        right: 0px;
        display: block;
        bottom: 45px;
    }

    .section-slider {
        max-height: 450px !important;
    }

    .menu-book a:visited {
        color: #5c5c5c !important;
    }

    .menu-book .awe-btn.awe-btn-13 {
        margin-right: 30px;
        line-height: 30px;
        font-size: 14px;
        border: 0.5px solid #5C5C5C;
        margin-bottom: 15px;
        padding: 5px 15px !important;
        color: #5c5c5c;
        font-weight: 300;
    }

    .check-availability .availability-form .awe-calendar-wrapper,
    .check-availability .availability-form .bootstrap-select.btn-group.awe-select,
    .check-availability .availability-form .vailability-submit {
        padding-left: 0px;
        padding-right: 0px;
    }

    .check-availability .availability-form .awe-calendar-wrapper {
        width: 19%;
        float: left;
        margin-right: 10px;
    }

    .check-availability .availability-form .awe-calendar-wrapper i {
        right: 13px
    }

    .check-availability .availability-form .bootstrap-select.btn-group.awe-select {
        width: 13%;
        float: left;
        margin-right: 10px;
    }

    .check-availability .availability-form .bootstrap-select.btn-group.awe-select .dropdown-menu {
        left: 0px;
        right: 0px;

    }

    .heading {
        font-size: 30px
    }

    .check-availability h2:before {
        content: none
    }

    .check-availability .availability-form .awe-select:nth-child(2) {
        margin-right: 0 !important
    }

    .check-availability .availability-form .vailability-submit {
        display: block;
        clear: both;
        text-align: center;
        margin-top: 0px
    }

    .check-availability .availability-form .vailability-submit .awe-btn {
        height: 40px;
        color: #fff;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
    }

    .check-availability1 .availability-form1 .awe-calendar-wrapper,
    .check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select,
    .check-availability1 .availability-form1 .vailability-submit {
        padding-left: 0px;
        padding-right: 0px;
    }

    .check-availability1 .availability-form1 .awe-calendar-wrapper {
        width: 19%;
        float: left;
        margin-right: 10px;
    }

    .check-availability1 .availability-form1 .awe-calendar-wrapper i {
        right: 13px
    }

    .check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select {
        width: 13%;
        float: left;
        margin-right: 10px;
    }

    .check-availability1 .availability-form1 .bootstrap-select.btn-group.awe-select .dropdown-menu {
        left: 0px;
        right: 0px;

    }

    .heading {
        font-size: 32px
    }

    .check-availability1 h2:before {
        content: none
    }

    .check-availability1 .availability-form1 .awe-select:nth-child(2) {
        margin-right: 0 !important
    }

    .check-availability1 .availability-form1 .vailability-submit {
        display: block;
        clear: both;
        text-align: center;
        margin-top: 0px
    }

    .check-availability1 .availability-form1 .vailability-submit .awe-btn {
        height: 40px;
        color: #fff;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
    }

    .accomd-modations-content .col-xs-4 {
        width: 50%
    }

    .accomd-modations {
        padding: 50px 0
    }

    .accomd-modations:before {
        content: none
    }

    .accomd-modations .accomd-modations-header {
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px
    }

    .accomd-modations .accomd-modations-header .heading,
    .accomd-modations .accomd-modations-header p {
        color: #333
    }

    .section-home-about {
        padding-top: 15px;
    }

    .home-about .text {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .home-about .text .heading {
        margin-top: 0
    }

    .section-our-best {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .our-best .img {
        margin-top: 0
    }

    .home-guestbook {
        padding: 70px 0
    }

    .home-guestbook:before {
        content: none
    }

    .home-guestbook .guestbook-header {
        text-align: center
    }

    .home-guestbook .guestbook-header .heading,
    .home-guestbook .guestbook-header p {
        color: #fff
    }

    .home-guestbook .guestbook-header .awe-btn {
        border-color: #fff;
        color: #fff;
        margin-top: 20px
    }

    .home-guestbook .guestbook-item {
        padding-right: 0
    }

    .home-guestbook .guestbook-content .owl-pagination {
        margin-left: 0;
        text-align: center
    }

    .guestbook-event {
        text-align: center
    }

    .guestbook-event .guestbook-content_1 {
        margin-top: 30px
    }

    .guestbook-event .guestbook-content_1 .img img {
        display: block;
        margin-left: auto;
        margin-right: auto
    }

    .guestbook-event .guestbook-content_1 .img span {
        margin-top: 10px
    }

    .guestbook-event .guestbook-content_1 .text {
        margin-top: 25px
    }

    .guestbook-event .guestbook-content_1 .owl-pagination {
        margin-top: 30px;
        text-align: center
    }

    .section-about {
        padding-bottom: 50px
    }

    .about .about-item .img,
    .about .about-item .text {
        width: 100%
    }

    .about .about-item .text {
        padding-left: 0;
        margin-top: 20px
    }

    .section-statistics {
        padding: 60px 0
    }

    .statistics .statistics_content .statistics_item {
        margin-top: 40px
    }

    .statistics_item .count {
        font-size: 50px
    }

    .attraction_sidebar .attraction_heading {
        text-align: left;
        cursor: pointer;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease
    }

    .attraction_sidebar .attraction_heading .attraction-icon-drop {
        display: block
    }

    .attraction_sidebar .attraction_heading:hover {
        background-color: #344a71;
        color: #fff
    }

    .attraction_sidebar .attraction_sidebar-content {
        display: none
    }

    .sidebar {
        margin-top: 70px
    }

    .sidebar .widget.widget_check_availability .check_availability .awe-btn {
        max-width: 250px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto
    }

    .widgetsearch .input-text {
        line-height: 40px;
        height: 40px
    }

    .section-contact {
        padding-top: 160px;
        padding-bottom: 45px
    }

    .contact .contact-form {
        margin-top: 30px
    }

    .restaurant-small .restaurant_content .restaurant_title {
        text-align: center
    }

    .restaurant_item.small-thumbs .text {
        margin-top: 0
    }

    .restaurant_item.lager-thumbs .text {
        vertical-align: top
    }

    .tabs-restaurant .ui-tabs-nav li a {
        font-size: 18px
    }

    .tabs-restaurant .ui-tabs-nav li a span {
        font-size: 14px
    }

    .section-event-restaurant {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .event-restaurant .event-restaurant_header {
        margin-top: 0;
        text-align: center
    }

    .event-restaurant .event-restaurant_header .icon {
        display: none
    }

    .event-restaurant .event-restaurant_content {
        margin-top: 40px
    }

    .event-restaurant .event-restaurant_content li {
        margin-top: 30px
    }

    .event-restaurant .event-restaurant_content li .text h2 {
        font-size: 14px
    }

    .room_item-2 {
        margin-left: 0;
        margin-right: 0
    }

    .room_item-2 .img {
        padding: 0;
        width: 100%
    }

    .room_item-2 .img:after {
        left: 0;
        right: 0
    }

    .room_item-2 .img:before {
        bottom: 0;
        top: auto;
        left: 40px;
        right: 40px;
        height: 40px;
        width: auto
    }

    .room_item-2.img-right .img {
        left: auto
    }

    .room_item-2.img-right .img:before {
        right: 40px;
        left: 40px
    }

    .room_item-2.img-right .text {
        right: auto
    }

    .room_item-2 .text {
        width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        margin-top: -10px;
        z-index: 10
    }

    .room_item-3 .text-thumbs {
        margin-left: 40px;
        margin-right: 40px
    }

    .room_item-3 .text-thumbs:before {
        display: none
    }

    .room_item-3 .text-thumbs .thumbs {
        display: none
    }

    .room_item-3 .text-thumbs .text {
        margin-top: -50px;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0)
    }

    .room_item-3 .text-thumbs .text h2 {
        font-size: 30px
    }

    .room_item-4 .text-thumbs .thumbs {
        display: none
    }

    .room_item-4 .text-thumbs .text {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0
    }

    .room_item-4 .text-thumbs .text h2 {
        font-size: 30px
    }

    .room_item-4 .text-thumbs .text p {
        margin-top: 25px
    }

    .room_item-5 {
        background-image: none !important
    }

    .room_item-5:before {
        z-index: 1;
        top: 0;
        background-color: rgba(21, 21, 21, 0);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(21, 21, 21, 0)), to(rgba(21, 21, 21, 0.3)));
        background: -webkit-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.3));
        background: -moz-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.3));
        background: -ms-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.3));
        background: -o-linear-gradient(top, rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.3))
    }

    .room_item-5 .img {
        display: block
    }

    .room_item-5 .room_item-back {
        display: none
    }

    .room_item-5 .room_item-forward {
        left: 30px;
        right: 30px;
        bottom: 10px
    }

    .room_item-5 .room_item-forward h2 {
        font-size: 24px
    }

    .room_item-5 .room_item-forward .price {
        font-size: 13px
    }

    .room_item-6 .text h2 {
        font-size: 30px
    }

    .section-room-detail {
        padding-bottom: 0px;
    }

    .room-detail_tab-content:before {
        content: none
    }

    .reservation-billing-detail {
        max-width: inherit;
        margin-left: 0
    }

    .gallery-isotope.col-6 .item-isotope,
    .gallery-isotope.col-6 .item-size {
        width: 33.33333%
    }

    #footer .footer_top .mailchimp h4 {
        padding-left: 0;
        display: block;
        margin-bottom: 20px
    }

    #footer .footer_top .mailchimp h4:before {
        content: none
    }

    #footer .footer_top .mailchimp .mailchimp-form {
        margin-left: 0
    }

    #footer .footer_top .social {
        margin-top: 0px
    }

    .google-maps {
        height: 100% !important;
    }

    .section_page-gallery {
        margin-top: 50px;
    }

    .fancybox-next span {

        background: url('./images/lightbox/next2.png') no-repeat 0 0 !important;
    }

    .fancybox-prev span {

        background: url('./images/lightbox/prev2.png') no-repeat 0 0 !important;
    }

    .header_mobile .container {
        position: relative;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .room-detail_thumbs .owl-buttons {
        display: none;
    }

    .book {
        display: none;
    }

    .search {
        margin-left: 50%;
    }

    .language {
        display: none;
    }

    #header.header-sticky {
        margin-top: 0px;
    }

    #header.header-sticky img {
        margin-top: 0px;
    }

    .fa.fa-chevron-right.mob {
        display: block;
    }

    .header_top {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.58)
    }

    .bhs {
        display: block;
    }


}

@media screen and (max-width:767px) {
    .item-experience .title {
        margin: 30px 0;
    }

    .item-experience .desc {
        margin: 30px 5px;
        text-align: left;
    }

    .item-experience .img img {
        /* height: 35vh;*/
        width: 100%;
        object-fit: cover;
    }

    .item-experience .awe-btn {
        display: none;
    }

    #header .header_menu {
        margin-top: 0px;
    }

    .room-detail_thumbs .owl-prev .fa,
    .room-detail_thumbs .owl-next .fa {
        position: absolute;
        top: 35%;
        left: 50%;
    }

    section.section-check-availability {
        display: none;
    }

    section.section-check-availability1 {
        display: none;
    }

    section.booking-mobile {
        background: #9c9c9c;
    }

    section.booking-mobile {
        bottom: 0;
        display: block;
        height: 52px;
        position: fixed;
        width: 100%;
        z-index: 998;
        font-size: 20px;
        line-height: 52px;
        text-align: center;
        text-transform: uppercase;
    }

    section.booking-mobile a {
        color: #fff;
    }

    section.booking-mobile a:hover {
        color: #fff;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
    }

    .banner-slider .slider-caption {
        font-size: 50px
    }

    .banner-slider .slider-caption.slider-caption-2 small {
        font-size: 20px
    }

    .banner-slider .slider-caption-sub {
        font-size: 18px
    }

    .banner-slider .slider-caption-sub.slider-caption-sub-1,
    .banner-slider .slider-caption-sub.slider-caption-sub-3 {
        font-size: 20px
    }

    .banner-slider .slider-item .slider-text .awe-btn {
        margin-top: 20px
    }

    .banner-slider .slider-item .slider-text .awe-btn.awe-btn-slider {
        font-size: 12px;
        padding: 10px 25px
    }

    .banner-slider .owl-next {
        right: 20px
    }

    .banner-slider .owl-prev {
        left: 20px
    }

    .sub-banner .text h2 {
        font-size: 30px
    }

    .sub-banner .text p {
        font-size: 16px
    }

    #header .header_top .header_left,
    #header .header_top .header_right {
        text-align: center;
        float: none;
        width: 100%
    }

    #header .header_top .header_right {
        border-top: 1px solid rgba(255, 255, 255, 0.1)
    }

    .header_top {
        display: block;
    }

    .check-availability .availability-form {
        margin-left: -15px;
        margin-right: -15px
    }

    .check-availability .availability-form:after {
        content: '';
        display: table;
        clear: both
    }

    .check-availability .availability-form .awe-calendar-wrapper,
    .check-availability .availability-form .bootstrap-select.btn-group.awe-select,
    .check-availability .availability-form .vailability-submit {
        padding-left: 15px;
        padding-right: 15px;
        float: left
    }

    .check-availability .availability-form .awe-calendar-wrapper {
        width: 50%;
        margin: 0
    }

    .check-availability .availability-form .awe-calendar-wrapper i {
        right: 25px
    }

    .check-availability .availability-form .bootstrap-select.btn-group.awe-select {
        width: 50%;
        float: left;
        margin: 0;
        margin-top: 20px
    }

    .check-availability .availability-form .bootstrap-select.btn-group.awe-select .dropdown-menu {
        left: 15px;
        right: 15px;
        min-width: inherit
    }

    .check-availability .availability-form .vailability-submit {
        width: 100%
    }

    .check-availability .availability-form .vailability-submit .awe-btn {
        font-size: 13px
    }

    .accomd-modations .accomd-modations-content .accomd-modations-room .text {
        padding: 10px 15px
    }

    .accomd-modations .accomd-modations-content .accomd-modations-room .text .price .amout {
        font-size: 20px
    }

    .accomd-modations_1 .accomd-modations-room_1 .text .price .amout {
        font-size: 26px
    }

    .section-gallery {
        padding: 50px 0
    }

    .gallery-isotope.col-6 .item-isotope,
    .gallery-isotope.col-6 .item-size {
        width: 50%
    }

    .gallery-isotope.col-4 .item-isotope,
    .gallery-isotope.col-3 .item-isotope,
    .gallery-isotope.col-4 .item-size,
    .gallery-isotope.col-3 .item-size,
    .gallery-isotope.col-4 .item-isotope.larger,
    .gallery-isotope.col-3 .item-isotope.larger {
        width: 50%
    }

    .gallery-cat ul li a {
        font-size: 14px;
        padding: 5px 10px
    }

    .section-team {
        padding: 50px 0
    }

    .section-activiti {
        padding-bottom: 50px
    }

    .section-activiti .page-navigation {
        margin-top: 40px
    }

    .activiti_item .text h2 {
        font-size: 18px
    }

    #attraction-maps {
        height: 350px
    }

    .comingsoon h1 {
        font-size: 50px
    }

    .comingsoon h6 {
        font-size: 30px
    }

    .comingsoon .countdown .item {
        font-size: 16px
    }

    .comingsoon .countdown .item .count {
        font-size: 40px
    }

    .guest-book .guest-book_head.guest-book_head-2 .text h2 {
        font-size: 24px
    }

    .login-register .text h2 {
        font-size: 30px
    }

    .login-register .text .account_form .field-form .awe-btn {
        min-width: 150px;
        font-size: 15px;
        padding: 10px 10px
    }

    .page-404 h1 {
        font-size: 60px;
        margin-top: 25px
    }

    .page-404 h6 {
        font-size: 20px
    }

    .page-404 p {
        font-size: 16px;
        margin-top: 15px
    }

    .page-404 .search-404 {
        margin-top: 30px
    }

    .page-404 .search-404 .awe-btn {
        font-size: 14px
    }

    .restaurant_item .text h2 {
        font-size: 16px
    }

    .tabs-restaurant .ui-tabs-nav {
        border-bottom-width: 0
    }

    .tabs-restaurant .ui-tabs-nav li {
        border-bottom: 2px solid #e7e7e7 !important;
        display: block;
        padding-top: 15px
    }

    .tabs-restaurant .ui-tabs-nav li a {
        font-size: 16px
    }

    .tabs-restaurant .ui-tabs-nav li a span {
        font-size: 13px
    }

    .tabs-restaurant .ui-tabs-nav li.ui-tabs-active {
        padding-bottom: 15px
    }

    .event-restaurant .event-restaurant_header h2 {
        font-size: 30px
    }

    .event-restaurant .event-restaurant_header p {
        line-height: 1.4em;
        font-size: 14px
    }

    .event-restaurant .event-restaurant_content li .event-date {
        margin-right: 20px
    }

    .section-room {
        padding-bottom: 70px
    }

    .post .entry-media .count-date {
        padding: 10px 20px
    }

    .post .entry-media .count-date span {
        font-size: 24px;
        padding: 0 5px
    }

    #footer .footer_top .social {
        margin-top: 0px
    }

    .section-room-detail {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .section_page-gallery {
        margin-top: -70px;
    }

    .section-contact {
        padding-top: 30px;
    }

    #footer {
        margin-bottom: 52px;
    }

    .search {
        margin-left: 20px;
    }

    /*.menu-book .awe-btn.awe-btn-13 {
      font-size: 15px;
      padding: 5px 15px !important;
    }*/

    .menu-book .awe-btn.awe-btn-13 {
        display: none;
    }


    @media (max-width:767px) and (min-width:460px) {
        #header.header-sticky {
            margin-top: 0px
        }

        #header.header-sticky .header_menu {
            margin-top: 0px;
        }

        .room-detail_thumbs .owl-prev .fa,
        .room-detail_thumbs .owl-next .fa {
            position: absolute;
            top: 30%;
            left: 50%;
        }



    }

    @media screen and (max-width:600px) {

        .heading {
            font-weight: 300;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            letter-spacing: 0.1em;
        }

        .home-about .text p {
            color: #5c5c5c;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.04em;
        }

        .experience {
            margin-bottom: 20px;
        }

        .section-slider {
            max-height: 350px !important;
        }

        .item-experience .title {
            font-weight: 300;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.1em;
        }

        .item-experience .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.04em;
            margin: 30px;
            text-align: left;
            margin: 30px 5px;
            text-align: left;
        }

        .item-experience .awe-btn {
            display: none;
        }

        .item-experience .img img {
            /*height: 22vh;*/
            width: 100%;
            object-fit: cover;
        }

        .room-detail_thumbs .owl-item {
            padding: 0px;
        }

        .room-detail_thumbs .owl-item img {
            height: 30vh;
        }

        .header_top .language p {
            display: none;
        }

        .room-detail_thumbs .owl-buttons {
            display: none;
        }

        .room-detail_thumbs h2 {
            font-size: 24px;
            line-height: 32px;
        }

        .room-detail_thumbs p {
            font-size: 16px;
            line-height: 24px;
        }

        .room-detail_thumbs .owl-prev .fa,
        .room-detail_thumbs .owl-next .fa {
            position: absolute;
            top: 25%;
            left: 50%;
        }

        #header.header-sticky {
            margin-top: 0px
        }

        #header.header-sticky .header_menu {
            margin-top: -6px !important;
        }

        .guest-book_content .row [class*="col-"] {
            width: 100%
        }

        .guest-book .guest-book_head .text {
            width: 100%;
            text-align: center;
            background-color: rgba(52, 74, 113, 0.85);
            padding-left: 30px;
            padding-right: 30px
        }

        .guest-book .guest-book_head .text h2 {
            font-size: 18px
        }

        .guest-book .guest-book_head .text p,
        .guest-book .guest-book_head .text .awe-btn {
            font-size: 14px
        }

        .guest-book .guest-book_head.guest-book_head-2 .text {
            padding: 0
        }

        .guest-book .guest-book_head.guest-book_head-2 .text h2 {
            font-size: 20px
        }

        .guest-book_item {
            padding-left: 30px;
            padding-right: 30px
        }

        .guest-book_item h2 {
            font-size: 16px
        }

        .page-404 h1 {
            font-size: 50px;
            margin-top: 20px
        }

        .page-404 h6 {
            font-size: 18px
        }

        .page-404 p {
            font-size: 14px
        }

        .page-404 .search-404 .filed-text {
            display: block;
            width: 100%
        }

        .page-404 .search-404 .awe-btn {
            font-size: 13px;
            margin-top: 30px
        }

        .room-wrap-4 {
            margin-top: -70px
        }

        .room-wrap-4 .room_item-4 {
            margin-top: 70px
        }

        .room_item-4 .img {
            width: 100%
        }

        .room_item-4 .text-thumbs {
            width: 100%
        }

        .room_item-4 .text-thumbs .text {
            margin-top: 20px
        }

        .room_item-4.img-right .img,
        .room_item-4.img-right .text-thumbs {
            left: auto;
            right: auto
        }

        .room_item-5 {
            background-image: none !important
        }

        .room_item-5 .room_item-forward {
            left: 20px;
            right: 20px
        }

        .room_item-5 .room_item-forward h2 {
            font-size: 20px
        }

        .room_item-6 .text {
            max-width: 100%
        }

        .room_item-6 .text h2 {
            font-size: 30px
        }

        .room-detail_rates {
            overflow-y: auto
        }

        .room-detail_rates table {
            width: 767px
        }

        .header_logo img {
            max-height: 70px;
            margin: 10px 0 10px;
        }

        #compare th,
        #compare td {
            text-align: center;
            padding: 5px 5px;
            font-size: 15px;
            line-height: 1.4em
        }

        .room-detail_tab-header li a {
            font-size: 24px;
            line-height: 30px;
        }

    }

    @media screen and (max-width:480px) {
        #header .header_menu {
            margin-top: 0px;
        }

        #header .header_top .header_left {
            display: none
        }

        #header .header_top .header_right {
            border-top-width: 0
        }

        #header .header_logo {
            line-height: 1
        }

        /*#header .header_logo img {
        max-height: 40px
    }*/
        .banner-slider .slider-caption {
            font-size: 30px
        }

        .banner-slider .slider-caption.slider-caption-2 small {
            font-size: 16px
        }

        .banner-slider .slider-caption-sub {
            font-size: 14px
        }

        .banner-slider .slider-caption-sub.slider-caption-sub-1 {
            font-size: 16px;
            letter-spacing: 16px
        }

        .banner-slider .slider-caption-sub.slider-caption-sub-3 {
            font-size: 14px
        }

        .banner-slider .slider-item .slider-text .awe-btn-slider {
            margin-top: 20px;
            font-size: 12px;
            padding-left: 20px;
            padding-right: 20px
        }

        .banner-slider .owl-next,
        .banner-slider .owl-prev {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            border-width: 0
        }

        .banner-slider .owl-next {
            right: 0
        }

        .banner-slider .owl-prev {
            left: 0
        }

        .sub-banner .text h2 {
            margin-top: 50px;
            font-size: 20px
        }

        .sub-banner .text p {
            font-size: 14px
        }

        .check-availability {
            padding: 20px 0
        }

        .check-availability h2 {
            padding-left: 0;
            line-height: 1;
            font-size: 20px
        }

        .check-availability .availability-form {
            margin-top: 20px
        }

        .our-best .text .heading {
            font-size: 20px
        }

        .our-best .text ul li {
            margin-top: 10px;
            width: 100%;
            padding: 0 !important
        }

        .accomd-modations .accomd-modations-content [class*="col-"] {
            width: 100%
        }

        .accomd-modations_1 .heading {
            text-align: center
        }

        .accomd-modations_1 .accomd-modations-content_1 .owl-pagination {
            position: static;
            text-align: center;
            margin-top: 40px
        }

        .accomd-modations-room_1 .text h2 {
            font-size: 20px
        }

        .section-event-news {
            padding-top: 50px;
            padding-bottom: 0
        }

        .event-news .event [class*="col-"] {
            width: 100%
        }

        .news-item .text .date {
            font-size: 16px
        }

        .news-item .text h2 {
            font-size: 14px
        }

        .news .awe-btn {
            width: 150px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            display: block
        }

        .home-guestbook {
            padding: 50px 0
        }

        .home-guestbook .guestbook-content .guestbook-item .img {
            float: none;
            margin-right: auto;
            margin-left: auto
        }

        .home-guestbook .guestbook-content .guestbook-item .text {
            text-align: center
        }

        .home-about .text .heading {
            font-size: 28px
        }

        .gallery-isotope.col-6 .item-isotope,
        .gallery-isotope.col-4 .item-isotope,
        .gallery-isotope.col-3 .item-isotope,
        .gallery-isotope.col-6 .item-size,
        .gallery-isotope.col-4 .item-size,
        .gallery-isotope.col-3 .item-size,
        .gallery-isotope.col-6 .item-isotope.larger,
        .gallery-isotope.col-4 .item-isotope.larger,
        .gallery-isotope.col-3 .item-isotope.larger {
            width: 100%
        }

        .gallery-content .gallery_item .text {
            font-size: 13px
        }

        .team .sub-heading {
            font-size: 15px
        }

        .team .team_content [class*="col-"] {
            width: 100%
        }

        .activiti [class*="col-"] {
            width: 100%
        }

        #attraction-maps {
            height: 300px
        }

        .attraction_sidebar .attraction_heading {
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px
        }

        .attraction_detail .attraction_detail-header .attraction_detail-title {
            font-size: 16px
        }

        .attraction_content .attraction_content-title {
            font-size: 16px
        }

        .sidebar .widget .widget-title {
            font-size: 16px
        }

        .sidebar .widget.widget_upcoming_events ul li .event-date {
            height: 50px;
            width: 50px;
            font-size: 13px
        }

        .sidebar .widget.widget_upcoming_events ul li .event-date strong {
            font-size: 18px
        }

        .sidebar .widget.widget_upcoming_events ul li .text a {
            font-size: 13px
        }

        .sidebar .widget.widget_recent_entries ul li .img {
            width: 50px
        }

        .sidebar .widget.widget_recent_entries ul li .text a {
            font-size: 13px
        }

        .post .entry-media .posted-on {
            top: 10px;
            bottom: auto;
            right: 10px;
            left: auto;
            border-color: #fff;
            color: #fff;
            font-size: 14px;
            z-index: 10;
            width: auto;
            height: auto;
            padding: 5px;
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            -o-transform: translate(0, 0)
        }

        .post .entry-media .posted-on strong {
            font-size: 24px
        }

        .post .entry-media .count-date {
            padding: 5px 10px
        }

        .post .entry-media .count-date span {
            font-size: 18px
        }

        .post .entry-media .count-date span span {
            font-size: 10px
        }

        .post .entry-header,
        .post .entry-content,
        .post .entry-footer {
            padding-left: 0
        }

        .post .entry-header .entry-title {
            font-size: 16px
        }

        .blog-content #comments,
        .blog-content .comment-respond {
            margin-left: 0
        }

        #comments .comment-title {
            font-size: 16px
        }

        #comments .commentlist li {
            padding-left: 70px
        }

        #comments .commentlist li .comment-body .comment-avatar {
            width: 50px
        }

        #comments .commentlist li .comment-body .comment-subject {
            font-size: 13px
        }

        #comments .commentlist li .comment-body .comment-meta {
            width: 100%
        }

        #comments .commentlist li .comment-body .action {
            width: 100%
        }

        #comments .commentlist li .comment-body .action .awe-btn {
            margin-right: 10px;
            margin-left: 0
        }

        .checkout .checkout_head h3 {
            font-size: 16px
        }

        .checkout .checkout_cart .cart-item {
            border-bottom: 1px solid #f1f1f1;
            padding: 10px 0;
            margin: 0
        }

        .checkout .checkout_cart .cart-item:last-child {
            border-bottom-width: 0
        }

        .checkout .checkout_cart .cart-item .remove {
            top: 10px
        }

        .checkout .checkout_cart .cart-item .img {
            display: none
        }

        .comingsoon h1 {
            font-size: 40px
        }

        .comingsoon h6 {
            font-size: 24px
        }

        .comingsoon p {
            font-size: 16px;
            margin-top: 15px
        }

        .comingsoon .countdown {
            padding-top: 10px;
            padding-bottom: 15px
        }

        .comingsoon .countdown .item {
            font-size: 14px
        }

        .comingsoon .countdown .item .count {
            font-size: 30px
        }

        .contact .contact-location .btn-collapse,
        .contact .contact-location .location-group h6,
        .contact .text h2 {
            font-size: 16px
        }

        .contact-map {
            height: 300px
        }

        .guest-book .guest-book_head .text h2 {
            font-size: 16px
        }

        .guest-book .guest-book_head .text .awe-btn {
            font-size: 12px
        }

        .login-register .text h2 {
            font-size: 24px
        }

        .login-register .text p {
            font-size: 16px
        }

        .login-register .text .account_form {
            margin-top: 10px
        }

        .login-register .text .account_form .field-form .awe-btn {
            min-width: 130px;
            font-size: 14px
        }

        .login-register .text .account_form .field-form.field-submit {
            margin-top: 25px
        }

        .login-register .text .account_form .account-desc {
            margin-top: 20px
        }

        .page-404 h1 {
            font-size: 30px
        }

        .page-404 h6 {
            font-size: 16px
        }

        .page-404 p {
            font-size: 13px
        }

        .page-404 .search-404 {
            margin-top: 20px
        }

        .page-404 .search-404 .filed-text {
            width: 100%
        }

        .page-404 .search-404 .awe-btn {
            margin-top: 20px
        }

        .restaurant-lager .restaurant_content .restaurant_item {
            margin-top: 40px
        }

        .restaurant-small .restaurant_content .restaurant_item {
            margin-top: 30px
        }

        .restaurant_item.lager-thumbs .text,
        .restaurant_item.lager-thumbs .img {
            width: 100%
        }

        .restaurant_item.lager-thumbs .text {
            margin-top: 25px
        }

        .restaurant_item.lager-thumbs .text .price {
            font-size: 26px
        }

        .restaurant_item.small-thumbs .img {
            width: 100%;
            margin-right: 0;
            float: none
        }

        .restaurant_item.small-thumbs .text {
            width: 100%;
            margin-top: 20px
        }

        .restaurant_item.small-thumbs .text h2 {
            font-size: 16px
        }

        .reservation {
            padding-left: 30px;
            padding-right: 30px
        }

        .reservation h2 {
            font-size: 24px
        }

        .reservation .reservation_form .awe-btn {
            font-size: 14px
        }

        .event-restaurant .event-restaurant_header h2 {
            font-size: 24px
        }

        .event-restaurant .event-restaurant_content li .event-date {
            margin-right: 20px;
            width: 50px;
            font-size: 13px
        }

        .event-restaurant .event-restaurant_content li .event-date strong {
            font-size: 24px
        }

        .section-room {
            padding-bottom: 50px
        }

        .room_item-1 h2 {
            font-size: 24px
        }

        .room_item-1 .desc p {
            font-size: 14px
        }

        .room_item-1 .bot .price {
            font-size: 13px;
            margin-top: 5px
        }

        .room_item-1 .bot .price .amout {
            font-size: 20px
        }

        .room_item-1 .bot .awe-btn {
            font-size: 11px
        }

        .room-wrap-2 {
            margin-top: -50px
        }

        .room-wrap-2 .room_item-2 {
            margin-top: 50px
        }

        .room_item-2 .img:before {
            display: none
        }

        .room_item-2 .text {
            margin-top: 20px;
            padding-left: 0;
            padding-right: 0
        }

        .room_item-2 .text p {
            font-size: 14px;
            margin-top: 20px
        }

        .room_item-2 .text h2 {
            font-size: 24px
        }

        .room_item-2 .text .awe-btn {
            padding: 10px 15px;
            margin-top: 25px
        }

        .room-wrap-3 {
            margin-top: -50px
        }

        .room-wrap-3 .room_item-3 {
            margin-top: 50px
        }

        .room_item-3 .text-thumbs {
            margin-left: 0;
            margin-right: 0
        }

        .room_item-3 .text-thumbs .text {
            padding-left: 0;
            padding-right: 0;
            margin-top: 0
        }

        .room_item-3 .text-thumbs .text h2 {
            font-size: 24px
        }

        .room_item-3 .text-thumbs .text p {
            margin-top: 20px;
            font-size: 14px
        }

        .room_item-3 .text-thumbs .text .awe-btn {
            margin-top: 25px
        }

        .room-wrap-4 {
            margin-top: -50px
        }

        .room-wrap-4 .room_item-4 {
            margin-top: 50px
        }

        .room_item-4 .text-thumbs .text h2 {
            font-size: 24px
        }

        .room_item-4 .text-thumbs .text p {
            font-size: 14px;
            margin-top: 25px
        }

        .room_item-4 .text-thumbs .text .awe-btn {
            padding: 10px 15px;
            margin-top: 25px
        }

        .room-wrap-5 .row [class*="col-"] {
            width: 100%
        }

        .room_item-6 .text h2 {
            font-size: 24px
        }

        .room_item-6 .text p {
            font-size: 14px;
            margin-top: 20px
        }

        .room_item-6 .text .awe-btn {
            padding: 10px 15px
        }

        .room-detail_amenities .row [class*="col-"] {
            width: 100%
        }

        .room-detail_book .room-detail_total .price .amout {
            font-size: 30px
        }

        .room-detail_package .room-package_item .text {
            padding-right: 0
        }

        .room-detail_package .room-package_item .text .room-package_price {
            position: static;
            margin-top: 10px
        }

        .room-detail_package .room-package_item .text .room-package_price .price {
            display: inline-block;
            margin-right: 10px
        }

        .room-detail_img .room_img-item h6 {
            font-size: 12px;
            padding: 5px 20px
        }

        .tab-price table caption h2,
        .tab-price table caption a {
            font-size: 14px
        }

        .tab-price table tr td {
            font-size: 10px
        }

        .tab-price table tr td .date {
            font-size: 9px
        }

        .reservation-calendar_custom .reservation-calendar_tabel td a span {
            font-size: 10px
        }

        .reservation-room_item .reservation-room_name {
            font-size: 22px
        }

        .reservation-room_item .reservation-room_text .reservation-room_price .reservation-room_amout {
            font-size: 24px
        }

        .reservation-room_item .awe-btn {
            min-width: inherit !important;
            padding: 5px 10px
        }

        .reservation-room_item .reservation-room_package .reservation-room_package-more {
            padding: 12px 15px
        }

        .reservation-package_item {
            padding: 15px;
            border-bottom: 1px solid #f1f1f1
        }

        .reservation-package_item .reservation-package_img {
            display: none
        }

        .reservation-package_item .reservation-package_text {
            padding-right: 0
        }

        .reservation-package_item .reservation-package_text .reservation-package_book-price {
            position: static;
            margin-top: 20px
        }

        .reservation-package_item .reservation-package_text .reservation-package_book-price .reservation-package_price {
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle
        }

        .reservation_step ul li {
            width: 100%;
            margin: 0
        }

        #footer .footer_top .mailchimp h4 {
            font-size: 20px
        }

        #footer .footer_top .mailchimp .mailchimp-form {
            padding-right: 100px;
            position: relative
        }

        #footer .footer_top .mailchimp .mailchimp-form .input-text {
            width: 100%
        }

        #footer .footer_top .mailchimp .mailchimp-form .awe-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            min-width: inherit;
            padding-left: 15px;
            padding-right: 15px
        }

        #footer .footer_top .social .social-content a {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px
        }

        #footer .footer_center .widget_logo .widget-logo {
            display: block
        }

        #footer .footer_center .widget_logo .widget-logo .img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px
        }

        #footer .footer_center [class*="col-"] {
            width: 100%
        }

        #footer .footer_bottom p {
            font-size: 13px
        }

        .section-room-detail {
            padding-bottom: 0px;
            padding-top: 0px;
        }

        /*.menu-book .awe-btn.awe-btn-13 {
      font-size: 12px;
      padding: 0px 5px!important;
      margin-top: 10px;
    }*/
        .menu-book .awe-btn.awe-btn-13 {
            display: none;
        }

        #header.header-sticky img {
            margin-top: 8px;
        }

    }

    @media screen and (max-width:480px) {
        .col-xs-6 {
            width: 100% !important;
        }

        .room-detail_thumbs .owl-prev .fa,
        .room-detail_thumbs .owl-next .fa {
            position: absolute;
            top: 35%;
            left: 50%;
        }

        #footer .footer_top a {
            font-size: 14px;
            line-height: 22px;
        }

        #footer .footer_top p {
            font-size: 14px;
        }

        #header .header_menu {
            margin-top: 0px;
        }

    }

    @media screen and (max-width:460px) {

        .menu-bars {
            top: -90px;
        }
    }

    @media screen and (max-width:360px) {
        .header_top .header_right .login-register a {
            font-size: 11px;
        }

        .menu-bars {
            top: -65px;
        }
    }

    @media screen and (max-width:320px) {
        .heading {
            font-size: 20px
        }

        .sub-banner .text h2 {
            margin-top: 30px
        }

        .event-news .event [class*="col-"] {
            width: 100%
        }

        .event-news .news .news-item {
            margin-left: 0;
            margin-right: 0
        }

        .event-news .news .news-item .img {
            width: 100%;
            padding: 0
        }

        .event-news .news .news-item .text {
            width: 100%;
            padding: 15px 15px
        }

        .statistics .statistics_content [class*="col-"] {
            width: 100%
        }

        .checkout .checkout_form [class*="col-"] {
            width: 100%
        }

        .comingsoon h1 {
            font-size: 30px
        }

        .comingsoon h6 {
            font-size: 20px
        }

        .comingsoon p {
            font-size: 16px;
            margin-top: 15px
        }

        .comingsoon .countdown .item {
            font-size: 10px
        }

        .comingsoon .countdown .item+.item:before {
            font-size: 20px
        }

        .comingsoon .countdown .item .count {
            font-size: 20px
        }

        .comingsoon .widget_social .widget-title {
            font-size: 16px;
            margin-top: 15px
        }

        .comingsoon .widget_social .widget-social a {
            margin: 10px 5px 0 5px;
            font-size: 14px
        }

        .restaurant_item.small-thumbs .img {
            width: 100%;
            margin-right: 0;
            float: none
        }

        .restaurant_item.small-thumbs .text {
            padding-right: 0
        }

        .restaurant_item.small-thumbs .text .price {
            position: static;
            text-align: left;
            margin-top: 15px;
            font-size: 26px
        }

        .room_item-1 h2 {
            font-size: 20px
        }

        .room_item-1 .desc p {
            font-size: 13px
        }

        .room_item-1 .desc ul li {
            width: 100%;
            font-size: 13px
        }

        .room_item-1 .bot {
            border-width: 0;
            padding: 0
        }

        .room_item-1 .bot .price {
            display: block;
            margin-top: 0
        }

        .room_item-1 .bot .awe-btn {
            float: none;
            margin-top: 20px
        }

        .room_item-2 .text p,
        .room_item-2 .text .price {
            font-size: 13px
        }

        .room_item-2 .text h2 {
            font-size: 20px
        }

        .room_item-2 .text .awe-btn {
            font-size: 11px
        }

        .room_item-3 .text-thumbs .text h2 {
            font-size: 20px
        }

        .room_item-3 .text-thumbs .text p,
        .room_item-3 .text-thumbs .text .price {
            font-size: 13px
        }

        .room_item-3 .text-thumbs .text .awe-btn {
            padding: 10px 15px;
            font-size: 11px
        }

        .room_item-4 .text-thumbs .text h2 {
            font-size: 20px
        }

        .room_item-4 .text-thumbs .text p,
        .room_item-4 .text-thumbs .text .price {
            font-size: 13px
        }

        .room_item-4 .text-thumbs .text .awe-btn {
            padding: 10px 15px;
            font-size: 11px
        }

        .room_item-6 .text {
            padding: 20px 20px 25px 20px
        }

        .room_item-6 .text h2 {
            font-size: 20px
        }

        .room_item-6 .text p,
        .room_item-6 .text .price,
        .room_item-6 .text ul li {
            font-size: 13px
        }

        .room_item-6 .text .awe-btn {
            font-size: 11px
        }

        .reservation-chosen-message h4 {
            font-size: 18px
        }
    }

    .list-field-send {
        margin: 8px 0 0
    }

    .list-field-send li {
        list-style: none;
        margin-right: 20px;
        margin-bottom: 0 !important;
        float: left;
        position: relative
    }

    .list-field-send li input[type='text'] {
        padding: 0 10px;
        background-color: transparent;
        color: white !important;
        width: 100%;
        border: 2px solid white;
        height: 40px;
        line-height: 36px
    }

    .list-field-send li label {
        font-weight: 400;
        position: absolute;
        left: 10px;
        right: 0;
        bottom: -50%;
        margin-bottom: -2px;
        color: white;
        font-size: 12px
    }

    .list-field-send li::-webkit-input-placeholder {
        color: white
    }

    .list-field-send li:-moz-placeholder {
        color: white
    }

    .list-field-send li::-moz-placeholder {
        color: white
    }

    .list-field-send li:-ms-input-placeholder {
        color: white
    }

    .list-field-send li .awe-btn.awe-btn-13 {
        font-size: 16px;
        border: 2px solid #e7c130 !important;
        background-color: #e7c130 !important;
        height: 40px;
        line-height: 22px
    }

    .list-field-send li .awe-btn.awe-btn-13:hover {
        border-color: #344a71 !important;
        background-color: #344a71 !important
    }

    .reservation-confirm {
        color: white;
        margin-bottom: 0;
        margin-top: 15px
    }

    .section-title {
        background-color: rgba(254, 251, 234, 1);
        padding: 40px;
        z-index: 99;
    }

    .full-screen {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .nopadding {
        padding: 0 !important;
        margin: 0 !important;
    }

    .padding2 {
        padding: 2px !important;
        margin: 0 !important;
    }

    a:hover {
        color: #d4a355;
    }

    #nav a.active {
        color: #d4a355 !important;
    }

    #footer a.active {
        color: #d4a355 !important;
    }

    .header_mobile #nav a.active {
        color: #D4A355 !important;
    }

    a.active {
        color: #D4A355 !important;
    }

    ul#nav li a.active {
        color: red !important;
    }

    a:visited {
        color: #d4a355;
    }

    .google-maps {
        position: relative;
        padding-bottom: 35%;
        height: 0;
        overflow: hidden;
    }

    .google-maps iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    .scrollspy {
        position: relative;
        height: 450px;
        margin-top: 10px;
        overflow: auto
    }

    .section-slider img {
        width: 20%;
    }

    /*# sourceMappingURL=style.css.map */

    .fancybox-close {
        width: 27px;
        height: 27px;
        background: url('./images/lightbox/close.png') no-repeat 0 0;
        bottom: -35px;
        top: auto;
        right: 0px;
    }

    .fancybox-next span,
    .fancybox-prev span {
        width: 50px;
        height: 45px;
        top: 50%;
        margin-top: -22px;
    }

    .fancybox-next span {

        background: url('./images/lightbox/next.png') no-repeat 0 0;
    }

    .fancybox-prev span {

        background: url('./images/lightbox/prev.png') no-repeat 0 0;
    }

    .spanish {
        display: none;
    }

    .section-accomd {
        background-color: #fefbea;
    }

    .float {
        position: fixed;
        width: 70px;
        height: 55px;
        bottom: 10px;
        right: 10px;
        background-color: #D0983F;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        z-index: 100;
        padding: 5px;
        text-shadow: 1px 1px 1px black;
    }

    .float a {
        color: #fff
    }

    .float a:hover {
        background-color: #BD8A39;
        position: fixed;
        width: 70px;
        height: 55px;
        bottom: 10px;
        right: 10px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        z-index: 100;
        padding: 5px;
        text-shadow: 1px 1px 1px black;
    }


    @media screen and (max-width:1199px) {
        #footer .footer_top .search {
            display: inline-block;
        }
    }

    @media screen and (max-width:480px) {
        #footer .footer_top .search .search-form .input-text {
            width: 60% !important;
        }

        #footer .footer_top .search .search-form .awe-btn {

            top: 0;
            bottom: 0;
            right: 0;
            min-width: inherit !important;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    #footer .footer_top .search .search-form {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 15px
    }

    #footer .footer_top .search .search-form .input-text {
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        padding: 5px 10px;
        display: inline-block;
        vertical-align: middle;
        line-height: 40px;
        width: 250px;
        height: 35px;
    }

    #footer .footer_top .search .search-form .input-text::-webkit-input-placeholder {
        color: #fff
    }

    #footer .footer_top .search .search-form .input-text:-moz-placeholder {
        color: #fff
    }

    #footer .footer_top .search .search-form .input-text::-moz-placeholder {
        color: #fff
    }

    #footer .footer_top .search .search-form .input-text:-ms-input-placeholder {
        color: #fff
    }

    #footer .footer_top .search .search-form .awe-btn {
        vertical-align: middle;
        min-width: 135px;
        text-align: center;
        padding: 7px 10px;
        height: 35px;
        color: #fff;
        background-color: #d4a355;
        font-size: 16px;
        text-transform: uppercase;
        margin-left: 10px
    }

    #footer .footer_top .search .search-form .awe-btn:hover {
        background: #D0983F;
        color: #fff
    }



    .search .search-form .awe-btn {

        top: 0;
        bottom: 0;
        right: 0;
        min-width: inherit !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.search .search-form {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px
}

.search .search-form .input-text {
    border: 1px solid #939191;
    background-color: transparent;
    color: #000;
    padding: 5px 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
    width: 250px;
    height: 35px;
}

.search .search-form .input-text::-webkit-input-placeholder {
    color: #000
}

.search .search-form .input-text:-moz-placeholder {
    color: #000
}

.search .search-form .input-text::-moz-placeholder {
    color: #000
}

.search .search-form .input-text:-ms-input-placeholder {
    color: #000
}

.search .search-form .awe-btn {
    vertical-align: middle;
    min-width: 135px;
    text-align: center;
    padding: 7px 10px;
    height: 35px;
    color: #fff;
    background-color: #e1bd85;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 10px
}

.search .search-form .awe-btn:hover {
    background: #D0983F;
    color: #fff
}

.covid {
    position: relative;
    background: #D09840;
    width: 100%;
    height: auto;
    color: #fff;
    padding: 12px;
    z-index: 1;
}

.covid-link a:link {
    color: #fff;
}

.covid .fa-times {
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

